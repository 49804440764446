import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { LoadingOutlined } from "@ant-design/icons";
import { IoMdAlert, IoIosCheckmarkCircle } from "react-icons/io";
import invitteam from "../../assets/img/inviteteam.svg";
import arrow from "../../assets/img/arrow.svg";
import updatebusiness from "../../assets/img/updatebusiness.svg";
import configerbusiness from "../../assets/img/config2.svg";
import socialprofile from "../../assets/img/integrationsocialprofile.svg";
import video from "../../assets/video/video.mp4";
import play1 from "../../assets/img/play1.svg";
import rocket from "../../assets/img/rocket.png";
import groupuser from "../../assets/img/groupuser.svg";
import groupprofile from "../../assets/img/profilegroup.svg";
import line from "../../assets/img/line.svg";
import { UserOutlined, CloseOutlined } from "@ant-design/icons";
import { Form, Input, Select, Button, Tooltip, Empty, Avatar } from "antd";
import { Col, Row } from "antd";
import { Modal } from "antd";
import {
  actionGetRoles,
  actionGetAllRoleProfile,
} from "../../store/services/settingService";
import { actionGetCompanyAllUsers } from "../../store/services/usersService";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  actionClearInviteMessages,
  actionInviteUser,
} from "../../store/services/authService.js";
import { getFullName } from "../helper/common-function.js";

function Begin() {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [text, setText] = useState("Invite your team");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addEmailLoader, setAddEmailLoader] = useState(false);
  const [resetDataAfterAPI, setResetDataAfterAPI] = useState(false);
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getRolesLoader, roles, getAllRoleProfileLoader, roleAllProfile } =
    useSelector((state) => state.setting);
  const { getCompanyAllUserLoader, companyAllUsers } = useSelector(
    (state) => state.users
  );
  const { inviteUserLoader, user,currentCompanyUser, InviteErrors, inviteSuccesses } = useSelector(
    (state) => state.auth
  );

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const showModal = () => {
    setIsModalOpen(true);
    dispatch(actionGetRoles());
    dispatch(actionGetAllRoleProfile());
    dispatch(actionGetCompanyAllUsers());
    setText(<p>Invite your team</p>);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setData([]);
  };

  const roleData = [];
  const roleProfileData = [];
  const reportsToData = [];

  // all role data
  roles &&
    roles.map((data) =>
      roleData.push({
        label: data.name,
        value: data.id,
      })
    );

  // all role profile data
  roleAllProfile &&
    roleAllProfile.map((data) =>
      roleProfileData.push({
        label: data.name,
        value: data.id,
      })
    );

  // report to data
  companyAllUsers &&
    companyAllUsers.map((data) =>
      reportsToData.push({
        label: (
          <span>
            {`${data.first_name ? data.first_name : ""} ${
              data.last_name ? data.last_name : ""
            }`}
          </span>
        ),
        value: data.id,
      })
    );

  const handleFinish = (val) => {
    const newData = { id: uuidv4(), ...val };
    setAddEmailLoader(true);
    setData([...data, newData]);
    form.resetFields();
    setAddEmailLoader(false);
  };

  const handleDelete = (id) => {
    const newData = data.filter((item) => item.id !== id);
    setData(newData);
  };

  // send invitation api call
  const handleSendInvitation = async () => {
    const dataWithoutIds =
      data &&
      data.length > 0 &&
      data.map(({ id, ...itemWithoutId }) => itemWithoutId);

    dispatch(
      actionInviteUser({
        req: dataWithoutIds,
        navigate,
        setResetDataAfterAPI,
        setIsModalOpen,
      })
    );
  };

  //reset data after api
  const handleResetData = () => {
    setData([]);
    dispatch(actionClearInviteMessages());
    setResetDataAfterAPI(false);
  };

  // find message with particular email
  const findMessage = (email, InviteErrors, inviteSuccesses) => {
    const error = InviteErrors.find((err) => err.email === email);
    const success = inviteSuccesses.find((succ) => succ.email === email);
    if (error) return { message: error.message, type: "error" };
    if (success)
      return { message: "Invitation sent successfully", type: "success" };
    return null;
  };



  return (
    <div className="my-5 mx-auto  h-full border border-[#C8D2FF]  rounded-[20px] w-[96%]">
      <div className="flex  lg:flex-row flex-col">
        <div className="w-full h-full border-r border-[#C8D2FF] lg:flex-1 flex-grow">
          <img src={rocket} alt="" className=" rounded-tl-[20px] w-full" />
          <div className="p-7 py-6 pb-[33px] custom-bg-linear  rounded-bl-[20px] h-full flex flex-col justify-between">
            <div>
              <div className="flex items-center justify-start">
                <Avatar
                  src={currentCompanyUser?.profile_pic}
                  size={80}
                  alt="Profile Pic"
                />

                <div className="pl-4">
                  <h2 className="lg:text-[20px] text-[16px] text-[#363636] font-semibold">
                    Hello{" "}
                    {/*  {`${user?.first_name} ${
                      user?.last_name !== null ? user?.last_name : ""
                    }`} */}
                    {getFullName(user?.first_name, user.last_name)}
                  </h2>
                  <p className="lg:text-[17px] text-[13px] text-[#545454]">
                    We’re happy to bring you aboard the world’s favorite CRM!
                  </p>
                  <p className="lg:text-[18px] text-[15px] text-[#6e6e6e] font-popinsMedium pt-3">
                    Let’s get started!
                  </p>
                </div>
              </div>
            </div>
            <div className="pt-10">
              <div className="">
                <h1 className="lg:text-[20px] text-[14px] text-start font-semibold text-[#363636]">
                  Watch a one-minute video
                </h1>
                <p className="lg:text-[14px] text-start text-[13px] pt-2 text-[#545454]">
                  We’re happy to bring you aboard the world’s favorite CRM!
                </p>
              </div>
              <div className="flex justify-center">
                <div className="video-container w-[90%] flex justify-center relative pt-6">
                  <video
                    className="border rounded-[15px]   border-[#4567FF] video-edit"
                    // width="100%"
                    // height="210px"
                    ref={videoRef}
                    onClick={handlePlayPause}
                  >
                    <source
                      src={video}
                      type="video/mp4"
                      className="object-contain"
                    />
                  </video>
                  <button
                    onClick={handlePlayPause}
                    className="play-button focus:outline-none absolute top-[44%] left-[44%] transform -translate-1/2 cursor-pointer bg-none p-0"
                  >
                    {isPlaying ? "" : <img src={play1} alt="Play button" />}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="px-8 lg:py-6 py-2  w-full lg:flex-1 flex-grow bg-white rounded-tr-[20px] rounded-br-[20px]">
          <h1 className="2xl:text-[30px] lg:text-[25px]  md:text-[20px] sm:text-[17px] text-[17px] text-[#363636] font-semibold">
            Set up your CRM Harbor
          </h1>
          <p className="2xl:text-[20px] lg:text-[16px] md:text-[14px] text-[14px] text-[#545454]">
            Make your CRM smarter and more interactive
          </p>
          <div className="flex relative justify-center 2xl:pt-9 xl:pt-7 lg:pt-6 pt-5">
            <img src={groupuser} alt="" />
          </div>

          <h1 className="lg:text-[20px] text-center  2xl:pt-8  xl:pt-7  lg:pt-6 pt-3 text-[18px] text-[#363636] font-semibold">
            {text}
          </h1>
          <p className="text-center  2xl:text-[16px]  lg:text-[14px] text-[12px] text-[#545454] 2xl:pt-6 xl:pt-5 lg:pt-4 pt-2">
            Stay connected and collaborate with your team
          </p>
          <p className="text-center 2xl:text-[16px]  lg:text-[14px] text-[12px] text-[#545454]">
            members to share sales updates from one platform
          </p>
          <div className="text-center 2xl:pt-7 xl:pt-6 lg:pt-5 pt-3"></div>

          <div className="my-5 cursor-pointer">
            <div
              className="flex justify-between border 
            2xl:py-3 pt-[18px] pb-[18px] rounded-[10px] cursor-pointer border-[#C8D2FF] px-4 group hover:bg-blue-50"
              onClick={showModal}
            >
              <div className="flex items-center">
                <img src={invitteam} alt="" />
                <p className="pl-3">Invite your team</p>
              </div>
              <img
                src={arrow}
                alt=""
                className="opacity-0 group-hover:opacity-100 transition-opacity duration-300"
              />
            </div>
            {/* model Design start here*/}
            <Modal
              afterClose={() => form.resetFields()}
              footer={false}
              className="begin-model "
              width={1250}
              // className="begin-model xl:min-w-[80%] lg:min-w-[90%] min-w-[90%]"
              title=""
              open={isModalOpen}
              onCancel={handleCancel}
            >
              <Row justify="space-between">
                <Col
                  span={11}
                  className="flex items-center flex-col px-[30px]  pt-3"
                >
                  <img src={groupprofile} alt="" className=" h-[70px]" />
                  <h1 className="md:text-[26px] text-[22px] font-semibold mt-3">
                    Invite Your Team
                  </h1>
                  <p className="md:text-[18px] text-[14px] text-center">
                    Invite your team and send on Invitation link to your friend.
                  </p>
                  <Form
                    form={form}
                    // name="normal_login"
                    className="login-form w-[95%] pt-5"
                    onFinish={handleFinish}
                    //  onFinishFailed={onFinishFailed}
                  >
                    <Form.Item
                      name="email"
                      className="mb-[12px]"
                      size="small"
                      rules={[
                        {
                          type: "email",
                          required: true,
                          message: "Please input email!",
                        },
                      ]}
                    >
                      <Input
                        style={{ background: "white" }}
                        placeholder="Email"
                        className="indent-5 begin-input bg-white focus:bg-white active:bg-white  rounded-[10px]"
                      />
                    </Form.Item>

                    <Form.Item
                      name="role_id"
                      className="mb-[12px] "
                      rules={[
                        { required: true, message: "Please select role!" },
                      ]}
                      size="small"
                    >
                      <Select
                        prefix={
                          <UserOutlined
                            color="#4567ff"
                            className="h-[18px] w-[18px] site-form-item-icon font-bold "
                          />
                        }
                        loading={getRolesLoader}
                        allowClear
                        showSearch
                        placeholder="Role"
                        className=""
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={roleData}
                        disabled={getRolesLoader}
                        style={{
                          backgroundColor: "white",
                          borderRadius: "10px",
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      name="role_profile_id"
                      className="mb-[12px] "
                      rules={[
                        {
                          required: true,
                          message: "Please select role profile!",
                        },
                      ]}
                      size="small"
                    >
                      <Select
                        prefix={
                          <UserOutlined
                            color="#4567ff"
                            className="h-[18px] w-[18px] site-form-item-icon font-bold"
                          />
                        }
                        loading={getAllRoleProfileLoader}
                        allowClear
                        placeholder="Role Profile"
                        showSearch
                        className=""
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={roleProfileData}
                        disabled={getAllRoleProfileLoader}
                        style={{
                          backgroundColor: "white",
                          borderRadius: "10px",
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      name="reports_to_id"
                      className="mb-[12px] "
                      rules={[
                        { required: true, message: "Please select report to!" },
                      ]}
                    >
                      <Select
                        prefix={
                          <UserOutlined
                            color="#4567ff"
                            className="h-[18px] w-[18px] site-form-item-icon font-bold"
                          />
                        }
                        loading={getCompanyAllUserLoader}
                        allowClear
                        showSearch
                        placeholder="Report To"
                        optionFilterProp="children"
                        className=""
                        filterOption={(input, option) =>
                          (option?.label?.props?.children ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={reportsToData}
                        disabled={getCompanyAllUserLoader}
                        style={{
                          backgroundColor: "white",
                          borderRadius: "10px",
                        }}
                      />
                    </Form.Item>

                    <Form.Item>
                      {/* <button
                      disabled={resetDataAfterAPI}
                        type="submit"
                        className="w-full py-[8px] border mt-10 border-[#6883FD] text-[#6883FD]  text-[16px] font-semibold rounded-[5px]"
                      >
                        <LoadingOutlined
                          className={`mr-2 ${!addEmailLoader ? "hidden" : ""}`}
                        />
                        Add
                      </button> */}
                      <Tooltip
                        title={
                          resetDataAfterAPI
                            ? "To add a new email, you need to clear all old emails."
                            : ""
                        }
                      >
                        <button
                          disabled={resetDataAfterAPI}
                          type="submit"
                          className="w-full py-[8px] border  border-[#6883FD] text-[#6883FD] text-[16px] font-semibold rounded-[5px] mt-[85px]"
                        >
                          <LoadingOutlined
                            className={`mr-2 ${
                              !addEmailLoader ? "hidden" : ""
                            }`}
                          />
                          Add
                        </button>
                      </Tooltip>
                    </Form.Item>
                  </Form>
                </Col>

                <img src={line} alt="" />

                <Col
                  span={11}
                  className="flex items-center begin-scrollbar flex-col px-[30px] py-[20px]"
                >
                  <h1 className="md:text-[26px] text-[22px] font-semibold mt-14 pb-7">
                    Invite List
                  </h1>
                  {resetDataAfterAPI && (
                    <div
                      className="text-[#4567FF] w-full text-right cursor-pointer md:text-[22px] text-[18px] font-semibold"
                      onClick={handleResetData}
                    >
                      Clear
                    </div>
                  )}
                  <div className="flex flex-col justify-between h-full w-full">
                    {data.length > 0 ? (
                      <>
                        <div
                          // className="overflow-auto mb-6 h-[80%]"
                          className="overflow-auto mb-6 max-h-[307px]"
                          // className="overflow-auto mb-6 max-h-[33.2vh] xl:h-[43vh]"
                          // style={{ maxHeight: "43vh",  }}
                        >
                          {data.map((user, index) => (
                            <React.Fragment key={index}>
                              <div
                                className={`flex items-center px-2 py-1 w-full bg-white justify-between rounded-lg ${
                                  findMessage(
                                    user.email,
                                    InviteErrors,
                                    inviteSuccesses
                                  )
                                    ? "border-b border-gray-200 mb-0"
                                    : "mb-1.5"
                                }`}
                              >
                                <div className="flex items-center">
                                  <Avatar
                                    style={{ backgroundColor: "#4567FF" }}
                                  >
                                    {user.email
                                      ? user.email.slice(0, 2).toUpperCase()
                                      : "NA"}
                                  </Avatar>
                                  <Tooltip title={user.email}>
                                    <p className="ml-2 truncate max-w-xs">
                                      {user.email &&
                                      user.email &&
                                      user.email.length > 25
                                        ? user.email.slice(0, 25) + "...."
                                        : user.email}
                                    </p>
                                  </Tooltip>
                                </div>
                                {!findMessage(
                                  user.email,
                                  InviteErrors,
                                  inviteSuccesses
                                ) && (
                                  <div
                                    className="text-blue-600 cursor-pointer"
                                    onClick={() => handleDelete(user.id)}
                                  >
                                    <CloseOutlined />
                                    <span className="ml-1">Remove</span>
                                  </div>
                                )}
                              </div>
                              {findMessage(
                                user.email,
                                InviteErrors,
                                inviteSuccesses
                              ) && (
                                <div className="bg-white border border-gray-200 border-t-0 shadow mb-2 w-[95%] mx-auto rounded-b-lg flex justify-between items-center px-2 py-1">
                                  <p
                                    className={`text-xs ${
                                      findMessage(
                                        user.email,
                                        InviteErrors,
                                        inviteSuccesses
                                      ).type === "error"
                                        ? "text-red-600"
                                        : "text-green-600"
                                    }`}
                                  >
                                    {
                                      findMessage(
                                        user.email,
                                        InviteErrors,
                                        inviteSuccesses
                                      ).message
                                    }
                                  </p>
                                  {findMessage(
                                    user.email,
                                    InviteErrors,
                                    inviteSuccesses
                                  ).type === "error" ? (
                                    <IoMdAlert className="h-5 w-5 text-red-600" />
                                  ) : (
                                    <IoIosCheckmarkCircle className="h-5 w-5 text-green-600" />
                                  )}
                                </div>
                              )}
                            </React.Fragment>
                          ))}
                        </div>

                        <div className="h-[20%] flex items-end justify-center">
                          <Button
                            type="primary"
                            className="bg-blue-600 hover:bg-blue-600/90 border-none font-medium tracking-wider mt-2 md:text-base text-sm min-h-[45px]"
                            block
                            onClick={handleSendInvitation}
                            loading={inviteUserLoader}
                          >
                            Invite
                          </Button>
                        </div>
                      </>
                    ) : (
                      <Empty description="No Users Found" />
                    )}
                  </div>
                </Col>
              </Row>
            </Modal>
            {/* model design finish here */}
          </div>

          <div className="my-5 cursor-pointer">
            <div
              className="flex justify-between border 2xl:py-3 pt-[15px] pb-[15px] rounded-[10px] cursor-pointer border-[#C8D2FF] px-4 group hover:bg-blue-50"
              onClick={() => {
                setText(<p>Update Business Profile</p>);
                navigate("/company-setting", { state: "company-details" });
              }}
            >
              <div className="flex items-center">
                <img src={updatebusiness} alt="" />
                <p className="pl-3">Update Business Profile</p>
              </div>
              <img
                src={arrow}
                alt=""
                className="opacity-0 group-hover:opacity-100 transition-opacity duration-300"
              />
            </div>
          </div>

          <div className="my-5 cursor-pointer">
            <div
              className="flex justify-between border 2xl:py-3 pb-[19px] pt-[19px]  rounded-[10px] cursor-pointer border-[#C8D2FF] px-4 group hover:bg-blue-50"
              onClick={() => {
                setText(<p>Configure your Business hours</p>);
                navigate("/company-setting", { state: "business-hour" });
              }}
            >
              <div className="flex items-center">
                <img src={configerbusiness} alt="" />
                <p className="pl-3">Configure your Business hours</p>
              </div>
              <img
                src={arrow}
                alt=""
                className="opacity-0 group-hover:opacity-100 transition-opacity duration-300"
              />
            </div>
          </div>

          <div className="my-5 cursor-pointer">
            <div
              className="flex justify-between border 2xl:py-3 pt-[17px] pb-[17px]  rounded-[10px] cursor-pointer border-[#C8D2FF] px-4 group hover:bg-blue-50"
              onClick={() => setText(<p>Integration social profiles</p>)}
            >
              <div className="flex items-center">
                <img src={socialprofile} alt="" />
                <p className="pl-3">Integration social profiles</p>
              </div>
              <img
                src={arrow}
                alt=""
                className="opacity-0 group-hover:opacity-100 transition-opacity duration-300"
              />
            </div>
          </div>

          {/* <div className="2xl:pt-7 xl:pt-6 lg:pt-5 pt-3">
            <div className="flex items-center border 2xl:py-5 lg:py-3 py-2 rounded-[10px] cursor-pointer border-[#C8D2FF] px-4">
              <img src={socialprofile} alt="" />
              <p className="pl-3">Integration social profiles</p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Begin;

import {
  LoadingOutlined,
  SearchOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  Button,
  ConfigProvider,
  Form,
  Input,
  Pagination,
  Popconfirm,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import deletevent from "../../../assets/img/deleteevent.svg";
import editedevent from "../../../assets/img/editedevent.svg";
import editevent from "../../../assets/img/editevent.svg";
import {
  actionSyncExchangeCurrencyMaster,
  actionDeleteExchangeCurrencyMaster,
  actionEditExchangeCurrencyMaster,
  actionGetSingleExchangeCurrencyMaster,
  actionGetExchageCurrencyMaster,
} from "../../../store/services/generalMasterService";
import SortIcon from "../../common/SortIcon";
import { isValidSearchString } from "../../helper/common-function";

const ExchangeCurrency = () => {
  const [editableForm] = Form.useForm();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const {
    exchangeCurrencyMasterData,
    getSingleExchangeCurrencyMasterData,
    getSingleExchangeCurrencyMasterLoader,
    getExchangeCurrencyLoader,
    syncExchangeCurrencyMasterLoader,
    deleteExchangeCurrencyMasterLoader,
    exchangeCurrencyMasterDataCount,
    updateExchangeCurrencyMasterLoader,
  } = useSelector((state) => state.generalMaster);
  const [search, setSearch] = useState("");
  const offsetRef = useRef(0);
  const [edited, setEdited] = useState(false);
  const [editingKey, setEditingKey] = useState("");

  const isEditing = (record) => record.currency_exchange_id === editingKey;

  useEffect(() => {
    dispatch(actionGetExchageCurrencyMaster({ search }));
  }, []);

  const handleSearch = (e) => {
    const value = e.target.value;
    if (isValidSearchString(value)) {
      setSearch(value);
      if (value === "") {
        dispatch(
          actionGetExchageCurrencyMaster({
            search: value,
          })
        );
      }
    }
  };

  const handleBlurAndPressEnter = (e) => {
    const value = e.target.value;
    if (search && isValidSearchString(value)) {
      dispatch(
        actionGetExchageCurrencyMaster({
          search: value,
        })
      );
    }
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      inputType === "number" ? <Input /> : <Input variant="borderless" />;
    return (
      <td {...restProps} className="input-height">
        {editing ? (
          <Form.Item
            className="mb-0 editable-order"
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: title === "Exchange Rate" ? true : false,
                message: ``,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          <Typography.Paragraph
            style={{ marginBottom: 0 }}
            ellipsis={{ rows: 1, expandable: false, tooltip: true }}
            className="px-[11px]"
          >
            {children}
          </Typography.Paragraph>
        )}
      </td>
    );
  };

  const edit = (record) => {
    dispatch(
      actionGetSingleExchangeCurrencyMaster(record.currency_exchange_id)
    );
    setEdited(!edited);
  };

  useEffect(() => {
    if (getSingleExchangeCurrencyMasterData && edited) {
      editableForm.setFieldsValue({
        exchange_rate: getSingleExchangeCurrencyMasterData.exchange_rate,
        // exchange
      });
      setEditingKey(getSingleExchangeCurrencyMasterData.currency_exchange_id);
    }
  }, [getSingleExchangeCurrencyMasterData]);

  const save = async (id, exchange_currency_id) => {
    try {
      const row = await editableForm.validateFields();
      const { exchange_rate } = row;

      const req = {
        exchange_rate: parseFloat(exchange_rate),
        exchange_currency_id,
      };
      dispatch(
        actionEditExchangeCurrencyMaster({ id, req, setEditingKey, setEdited })
      );
    } catch (errInfo) {
      // console.log("Validate Failed:", errInfo);
      return;
    }
  };

  const columns = [
    {
      title: "SR No.",
      dataIndex: "srno",
      key: "1",
      width: "12%",
      editable: false,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Exchange Currency",
      // dataIndex: ["exchange_currency", "currency"],
      dataIndex: "exchange_currency",
      key: "2",
      width: "22%",
      editable: false,
      ellipsis: true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => `${text?.currency} (${text?.name})`,
    },

    {
      title: "Exchange Rate",
      dataIndex: "exchange_rate",
      key: "3",
      width: "48%",
      editable: true,
      ellipsis: true,
      render: (data) => (data ? data : "-"),
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Action",
      key: "4",
      dataIndex: "operation",
      render: (_, record) => {
        const editable = isEditing(record);

        return (
          <div className="flex items-center">
            {editable ? (
              <div className="flex items-center">
                {updateExchangeCurrencyMasterLoader[
                  record.currency_exchange_id
                ] ? (
                  <LoadingOutlined />
                ) : (
                  <Typography.Link
                    onClick={() =>
                      save(
                        record.currency_exchange_id,
                        record?.exchange_currency?.id
                      )
                    }
                  >
                    <img src={editedevent} alt="" />
                  </Typography.Link>
                )}
              </div>
            ) : (
              <div className="flex items-center gap-x-2 justify-center">
                <Typography.Link
                  disabled={editingKey !== ""}
                  onClick={() => edit(record)}
                >
                  <img src={editevent} alt="" />
                </Typography.Link>
              </div>
            )}
            <div className="ml-5">
              <Popconfirm
                okButtonProps={{
                  loading:
                    deleteExchangeCurrencyMasterLoader[
                      record.currency_exchange_id
                    ],
                }}
                description="Are you sure you want to delete?"
                onConfirm={() =>
                  dispatch(
                    actionDeleteExchangeCurrencyMaster({
                      id: record.currency_exchange_id,
                      setEditingKey,
                    })
                  )
                }
              >
                <img src={deletevent} alt="" className="cursor-pointer" />
              </Popconfirm>
            </div>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType:
          col.dataIndex === "srno" ? "currency_exchange_id" : "exchange_rate",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleSortChange = (pagination, filters, sorter) => {
    const sortFieldMapping = {
      "exchange_currency.currency": "currency_exchange_id",
    };

    const orderByColumn = sortFieldMapping[sorter.field];

    const sorterParams = {
      field: orderByColumn,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    dispatch(
      actionGetExchageCurrencyMaster({
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
        offset: offsetRef.current,
      })
    );
  };

  const handleSyncClick = async () => {
    try {
      await dispatch(actionSyncExchangeCurrencyMaster());
      await dispatch(actionGetExchageCurrencyMaster({ search }));
    } catch (error) {
      console.log("error sync");
    }
  };

  return (
    <div className="event-input">
      <div className="flex justify-between mt-[15px]">
        <h1 className="text-[#6883FD] text-[20px] font-medium">
          Currency Exchange
        </h1>
        <div>
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  colorBgContainerDisabled: "red",
                },
              },
            }}
          >
            <Button
              className="root-btn sync-disabled text-white hover:text-white py-5 flex-center radius"
              type="primary"
              onClick={handleSyncClick}
              icon={<SyncOutlined spin={syncExchangeCurrencyMasterLoader} />}
              disabled={syncExchangeCurrencyMasterLoader}
            >
              Sync Currency Exchange
            </Button>
          </ConfigProvider>
        </div>
      </div>
      <div className="pt-[25px]">
        <Input
          placeholder="Search"
          value={search}
          onChange={handleSearch}
          onBlur={handleBlurAndPressEnter}
          onPressEnter={handleBlurAndPressEnter}
          prefix={<SearchOutlined className="text-[#7C7C7C]" />}
        />
      </div>

      <div className="pt-5 pb-[75px] h-[calc(100vh-150px)]">
        {" "}
        <Form form={editableForm} component={false}>
          <Table
            onChange={handleSortChange}
            pagination={false}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            loading={getExchangeCurrencyLoader}
            bordered
            dataSource={exchangeCurrencyMasterData}
            rowKey="currency_exchange_id"
            columns={mergedColumns}
            rowClassName="editable-row"
            scroll={{ y: "calc(100vh - 300px)" }}
          />

          <div className="flex items-center justify-between mt-3">
            <p className="text-[15px] font-popinsMedium">
              Total : {exchangeCurrencyMasterDataCount}
            </p>

            <Pagination
              showSizeChanger={false}
              current={offsetRef.current + 1}
              className="flex items-center"
              total={exchangeCurrencyMasterDataCount}
              onChange={(e) => {
                offsetRef.current = e - 1;
                dispatch(
                  actionGetExchageCurrencyMaster({
                    offset: offsetRef.current,
                    search,
                  })
                );
              }}
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ExchangeCurrency;

import {
  Button,
  Card,
  Checkbox,
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Image,
  Input,
  List,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import plusSvg from "../../assets/img/plus.svg";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import add from "../../assets/img/collection-management-primary.svg";
import {
  DeleteOutlined,
  DownloadOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import useFileUpload from "../../hooks/use-file-upload.js";
import {
  actionAddDepositMaster,
  actionAddPaymentMethodMaster,
  actionGetBusinessPartnerTypeMaster,
  actionGetDepositsMaster,
  actionGetPaymentMethodMaster,
} from "../../store/services/generalMasterService";
import {
  actionAddBusinessPartner,
  actionGetAllBusinessPartner,
} from "../../store/services/masterDataService";
import AddPopover from "../common/AddPopover";
import CustomIconText from "../common/CustomIconText";
import CustomUploadDragger from "../common/CustomUploadDragger";
import {
  convertToInt,
  extractFilename,
  renderFileIcon,
} from "../helper/common-function.js";
import { actionDownloadFile } from "../../store/services/commonService.js";
import {
  actionAddCollectionManagement,
  actionGetCollectionManagementDetails,
  actionUpdateCollectionManagement,
} from "../../store/services/financeService.js";
import dayjs from "dayjs";
import { gridSettings } from "../helper/constants";

const AddCollectionManagement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [form] = Form.useForm();
  const [paymentModeForm] = Form.useForm();
  const [depositeForm] = Form.useForm();
  const [businessPartnerForm] = Form.useForm();
  const { collectionId } = location.state || {};

  const { isUploading, handleUpload } = useFileUpload();

  const [isPaymentModePopoverOpen, setIsPaymentModePopoverOpen] =
    useState(false);
  const [isDepositePopoverOpen, setIsDepositePopoverOpen] = useState(false);

  const { myCurrency } = useSelector((state) => state.auth);
  const [attatchments, setAttatchments] = useState([]);
  const [isEmailSend, setIsEmailSend] = useState(false);
  const [isBusinessParterPopoverOpen, setIsBusinessParterPopoverOpen] =
    useState(false);

  const {
    allBusinessPartnerData,
    getAllBusinessPartnerLoader,
    addBusinessPartnerLoader,
  } = useSelector((state) => state.masterData);

  const {
    addCollectionManagementLoader,
    updateCollectionManagmentLoader,
    getCollectionManagementDetailLoader,
    collectionManagementDetails,
  } = useSelector((state) => state.finance);

  const {
    paymentMethodMasterData,
    getPaymentMethodLoader,
    getDepositsMasterLoader,
    depositsMasterData,
    addDepositMasterLoader,
    addPaymentMethodMasterLoader,
    getBusinessPartnerTypeLoader,
    businessPartnerTypeData,
  } = useSelector((state) => state.generalMaster);

  useEffect(() => {
    dispatch(actionGetAllBusinessPartner());
    dispatch(actionGetPaymentMethodMaster());
    dispatch(actionGetDepositsMaster());
    dispatch(actionGetBusinessPartnerTypeMaster());
  }, [dispatch]);

  useEffect(() => {
    if (collectionId) {
      dispatch(actionGetCollectionManagementDetails(collectionId));
    }
  }, [collectionId, dispatch]);

  useEffect(() => {
    if (collectionManagementDetails && collectionId) {
      const {
        business_partner,
        amount_currency,
        amount_received,
        bank_charges,
        payment_date,
        reference,
        notes,
        is_email_send,
        payment_mode,
        deposit_to,
        attachments,
      } = collectionManagementDetails;

      form.setFieldsValue({
        business_partner_id: business_partner?.business_partner_id || null,
        amount_currency_id: amount_currency?.currency
          ? amount_currency?.currency
          : myCurrency?.currency
          ? myCurrency?.currency
          : "INR",
        amount_received: amount_received || null,
        bank_charges: bank_charges || null,
        payment_date: payment_date ? dayjs(payment_date) : null,
        payment_method_master_id:
          payment_mode?.payment_method_master_id || null,
        deposit_master_id: deposit_to?.deposit_master_id || null,
        reference: reference || null,
        notes: notes || null,
      });
      setIsEmailSend(is_email_send);
      setAttatchments(attachments && attachments.map((att) => att.upload));
    }
  }, [collectionId, collectionManagementDetails]);

  // business partner data
  const businessPartnerOptions = useMemo(() => {
    return allBusinessPartnerData && allBusinessPartnerData.length > 0
      ? allBusinessPartnerData.map((data) => ({
          key: data.business_partner_id,
          label: data.business_partner_name,
          value: data.business_partner_id,
        }))
      : [];
  }, [allBusinessPartnerData]);

  const paymentOptions = useMemo(() => {
    return (
      (paymentMethodMasterData &&
        paymentMethodMasterData.length > 0 &&
        paymentMethodMasterData.map((item) => ({
          value: item.payment_method_master_id,
          label: item.payment_method,
        }))) ||
      []
    );
  }, [paymentMethodMasterData]);

  const depositsOptions = useMemo(() => {
    return (
      (depositsMasterData &&
        depositsMasterData.length > 0 &&
        depositsMasterData.map((item) => ({
          value: item.deposit_master_id,
          label: item.name,
        }))) ||
      []
    );
  }, [depositsMasterData]);

  // business partner type data
  const businessPartnerTypesOptions = useMemo(() =>
    businessPartnerTypeData && businessPartnerTypeData.length > 0
      ? businessPartnerTypeData.map((data) => ({
          key: data.business_partner_type_master_id,
          label: data.business_partner_type_name,
          value: data.business_partner_type_master_id,
        }))
      : []
  );

  const closePaymentModePopover = () => {
    setIsPaymentModePopoverOpen(false);
    paymentModeForm.resetFields();
  };

  const closeDepositPopover = () => {
    setIsDepositePopoverOpen(false);
    depositeForm.resetFields();
  };

  const closeBusinessPartnerPopover = () => {
    setIsBusinessParterPopoverOpen(false);
    businessPartnerForm.resetFields();
  };

  const showPaymentModeContent = () => {
    return (
      <Form
        form={paymentModeForm}
        onFinish={(values) =>
          dispatch(
            actionAddPaymentMethodMaster({
              values,
              handleCancel: closePaymentModePopover,
              form,
            })
          )
        }
        layout="vertical"
        className="mt-[20px] mb-0"
      >
        <Form.Item
          name="payment_method"
          label="Payment Method"
          className="mb-[12px]"
          size="small"
          rules={[
            {
              required: true,
              message: "Please input payment method!",
            },
          ]}
        >
          <Input
            style={{ background: "white" }}
            placeholder="Enter Payment Method"
            className="!indent-1 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
          />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          className="mb-[12px]"
          size="small"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input.TextArea
            rows={3}
            placeholder="Enter Description"
            className="!indent-1 begin-input !bg-white focus:bg-white active:bg-white rounded-[10px]"
          />
        </Form.Item>

        <div className="flex justify-center mt-[40px]">
          <Form.Item className="mb-0">
            <Button
              onClick={closePaymentModePopover}
              type="button"
              className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
            >
              Cancel
            </Button>
          </Form.Item>

          <div className="ml-4">
            <Form.Item className="mb-0">
              <Button
                loading={addPaymentMethodMasterLoader}
                type="primary"
                className="root-btn min-h-[45px] min-w-[120px]"
                htmlType="submit"
              >
                Save
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    );
  };

  const showDepositeToContent = () => {
    return (
      <Form
        form={depositeForm}
        onFinish={(values) =>
          dispatch(
            actionAddDepositMaster({
              values,
              handleCancel: closeDepositPopover,
              form,
            })
          )
        }
        layout="vertical"
        className="mt-[20px] mb-0"
      >
        <Form.Item
          name="name"
          label="Deposit name"
          className="mb-[12px]"
          size="small"
          rules={[
            {
              required: true,
              message: "Please input deposit name!",
            },
          ]}
        >
          <Input
            style={{ background: "white" }}
            placeholder="Enter name"
            className="!indent-1 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
          />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          className="mb-[12px]"
          size="small"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input.TextArea
            rows={3}
            placeholder="Enter Description"
            className="!indent-1 begin-input !bg-white focus:bg-white active:bg-white rounded-[10px]"
          />
        </Form.Item>

        <div className="flex justify-center mt-[40px]">
          <Form.Item className="mb-0">
            <Button
              onClick={closeDepositPopover}
              type="button"
              className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
            >
              Cancel
            </Button>
          </Form.Item>

          <div className="ml-4">
            <Form.Item className="mb-0">
              <Button
                loading={addDepositMasterLoader}
                type="primary"
                className="root-btn min-h-[45px] min-w-[120px]"
                htmlType="submit"
              >
                Save
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    );
  };

  const showBusinessPartnerContent = () => (
    <>
      <div className="min-w-[350px]">
        <Form
          form={businessPartnerForm}
          onFinish={(values) =>
            dispatch(
              actionAddBusinessPartner({
                req: values,
                form,
                handleCancel: closeBusinessPartnerPopover,
              })
            )
          }
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="business_partner_name"
            label="Business Partner Name"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please input business partner name!",
              },
            ]}
          >
            <Input
              style={{ background: "white" }}
              placeholder="Enter Business Partner Name"
              className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>
          <Form.Item
            name="business_partner_type_master_id"
            label="Business Partner Type"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please select business partner type!",
              },
            ]}
          >
            <Select
              allowClear
              showSearch
              // mode="multiple"
              placeholder="Select Business Partner Type"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={businessPartnerTypesOptions}
              disabled={getBusinessPartnerTypeLoader}
              loading={getBusinessPartnerTypeLoader}
            />
          </Form.Item>

          <div className="flex justify-start mt-[30px]">
            <Form.Item className="mb-0">
              <Button
                onClick={() => closeBusinessPartnerPopover()}
                type="button"
                className="gray-button text-white hover:text-white min-h-[40px] min-w-[100px]"
              >
                Cancel
              </Button>
            </Form.Item>

            <div className="ml-4">
              <Form.Item className="mb-0">
                <Button
                  loading={addBusinessPartnerLoader}
                  type="primary"
                  className="root-btn min-h-[40px] min-w-[100px]"
                  htmlType="submit"
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );

  const onFinish = (values) => {
    const { amount_currency_id, ...filteredValues } = values;
    const req = {
      ...filteredValues,
      amount_received: values.amount_received
        ? parseFloat(values.amount_received)
        : null,
      bank_charges: values.bank_charges
        ? parseFloat(values.bank_charges)
        : null,
      payment_date: values.payment_date
        ? dayjs(values.payment_date).format("YYYY-MM-DD")
        : null,
      is_email_send: isEmailSend,
      attachments: attatchments?.map((att) => att.upload_id) || [],
    };
    console.log({ req });
    collectionId
      ? dispatch(
          actionUpdateCollectionManagement({ id: collectionId, req, navigate })
        )
      : dispatch(actionAddCollectionManagement({ req, navigate }));
  };

  const onFinishFailed = (errors) => {
    console.log({ errors });
  };

  return (
    <section className="main-wrapper">
      <div className="mb-4">
        <CustomIconText
          image={add}
          title="Collection Management"
          backArrow={true}
        />
      </div>

      <Card
        loading={getCollectionManagementDetailLoader}
        className="main-card mt-5 "
      >
        <Spin spinning={false}>
          <Form
            className={`${
              addCollectionManagementLoader
                ? "pointer-events-none opacity-70"
                : ""
            }`}
            scrollToFirstError
            form={form}
            layout="vertical"
            name="basic"
            initialValues={{
              amount_currency_id: myCurrency?.currency,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row gutter={24}>
              <Col {...gridSettings}>
                <AddPopover
                  content={showBusinessPartnerContent}
                  isOpen={isBusinessParterPopoverOpen}
                  onOpenChange={(visible) => {
                    if (!visible) {
                      setIsBusinessParterPopoverOpen(false);
                      businessPartnerForm.resetFields();
                    }
                  }}
                  text="Add New"
                  showModal={() =>
                    setIsBusinessParterPopoverOpen(!isBusinessParterPopoverOpen)
                  }
                />
                <Form.Item
                  label="Business Partner"
                  name="business_partner_id"
                  rules={[
                    {
                      required: true,
                      message: "Please select business partner!",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    placeholder="Select Business Partner"
                    optionFilterProp="children"
                    className=""
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={businessPartnerOptions}
                    disabled={getAllBusinessPartnerLoader}
                    loading={getAllBusinessPartnerLoader}
                  />
                </Form.Item>
              </Col>

              <Col {...gridSettings}>
                <Form.Item label="Amount Received" required>
                  <Space.Compact className="w-full group-select">
                    <Form.Item name="amount_currency_id" noStyle>
                      <Select
                        disabled
                        // defaultValue="USD"
                        style={{ width: "20%" }}
                        options={[]}
                        className="!rounded-none"
                      />
                    </Form.Item>

                    <Form.Item
                      name="amount_received"
                      noStyle
                      rules={[
                        { required: true, message: "Please enter amount!" },
                      ]}
                      normalize={(value) =>
                        value && value.replace(/[^\d.]/g, "")
                      }
                    >
                      <Input
                        placeholder="Enter Amount"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Space.Compact>
                </Form.Item>
              </Col>

              <Col {...gridSettings}>
                <Form.Item
                  normalize={(value) => value && value.replace(/[^\d.]/g, "")}
                  label="Bank Charges (if any)"
                  name="bank_charges"
                >
                  <Input placeholder="Enter Bank Charges" />
                </Form.Item>
              </Col>
              <Col {...gridSettings}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please select date!",
                    },
                  ]}
                  label="Payment Date"
                  name="payment_date"
                >
                  <DatePicker
                    className="w-full"
                    placeholder="Select Payment Date"
                  />
                </Form.Item>
              </Col>

              <Col {...gridSettings}>
                <AddPopover
                  content={showPaymentModeContent}
                  isOpen={isPaymentModePopoverOpen}
                  onOpenChange={(visible) => {
                    if (!visible) {
                      setIsPaymentModePopoverOpen(false);
                      paymentModeForm.resetFields();
                    }
                  }}
                  text="Add Payment Mode"
                  showModal={() =>
                    setIsPaymentModePopoverOpen(!isPaymentModePopoverOpen)
                  }
                />
                <Form.Item label="Payment Mode" name="payment_method_master_id">
                  <Select
                    allowClear
                    showSearch
                    loading={getPaymentMethodLoader}
                    disabled={getPaymentMethodLoader}
                    placeholder="Select Payment Mode"
                    optionFilterProp="children"
                    className=""
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={paymentOptions}
                  />
                </Form.Item>
              </Col>

              <Col {...gridSettings}>
                <AddPopover
                  content={showDepositeToContent}
                  isOpen={isDepositePopoverOpen}
                  onOpenChange={(visible) => {
                    console.log(visible);
                    if (!visible) {
                      setIsDepositePopoverOpen(false);
                      depositeForm.resetFields();
                    }
                  }}
                  text="Add Deposit To"
                  showModal={() =>
                    setIsDepositePopoverOpen(!isDepositePopoverOpen)
                  }
                />
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please select deposit to!",
                    },
                  ]}
                  label="Deposit To"
                  name="deposit_master_id"
                >
                  <Select
                    allowClear
                    showSearch
                    loading={getDepositsMasterLoader}
                    disabled={getDepositsMasterLoader}
                    placeholder="Select Deposit to"
                    optionFilterProp="children"
                    className=""
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={depositsOptions}
                  />
                </Form.Item>
              </Col>

              <Col {...gridSettings}>
                <Form.Item label="Reference" name="reference">
                  <Input rows={2} placeholder="Enter Reference" />
                </Form.Item>
              </Col>

              <Col {...gridSettings}>
                <Form.Item label="Notes" name="notes">
                  <Input.TextArea rows={2} placeholder="Enter Notes" />
                </Form.Item>
              </Col>

              <Col span={24}>
                <CustomUploadDragger
                  accept=".jpeg,.jpg,.png,.gif,.pdf,.doc"
                  onDrop={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    const files = Array.from(e.dataTransfer.files);
                    if (files && files.length > 0) {
                      handleUpload(files, setAttatchments);
                    }
                  }}
                  onChange={async (e) => {
                    const files = Array.from(e.target.files);

                    if (files.length > 0) {
                      await handleUpload(files, setAttatchments);
                    }
                  }}
                  loading={isUploading}
                />

                {attatchments && attatchments.length > 0 && (
                  <AttachmentList
                    items={attatchments}
                    onDelete={(id) => {
                      setAttatchments((prev) =>
                        prev.filter((attachment) => attachment.upload_id !== id)
                      );
                    }}
                    onView={() => {}}
                  />
                )}
              </Col>

              <ConfigProvider
                theme={{
                  components: {
                    Checkbox: {
                      colorPrimary: "#6883FD",
                      colorPrimaryHover: "#6883FD",
                    },
                  },
                }}
              >
                <Checkbox
                  checked={isEmailSend}
                  onChange={(e) => setIsEmailSend(e.target.checked)}
                  className="primary-checkbox text-sm font-medium pl-3 mt-3"
                >
                  Email a "thank you" note for this payment
                </Checkbox>
              </ConfigProvider>

              <Col
                span={24}
                className="flex items-center my-4 justify-center gap-x-6"
              >
                <Form.Item>
                  <Button
                    onClick={() => navigate(-1)}
                    type="button"
                    className="gray-button text-white hover:text-white gray-hover min-h-[45px] min-w-[120px]"
                    htmlType="button"
                  >
                    Cancel
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    loading={
                      addCollectionManagementLoader ||
                      updateCollectionManagmentLoader
                    }
                    type="primary"
                    className="root-btn  min-h-[45px] min-w-[120px]"
                    htmlType="submit"
                  >
                    {collectionId ? "Update" : "Submit"}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Card>
    </section>
  );
};

export default AddCollectionManagement;

const AttachmentList = ({ items, onDelete }) => {
  const dispatch = useDispatch();
  const { downloadFileLoader } = useSelector((state) => state.common);

  return (
    <>
      <List
        className="mt-3 bg-slate-50 max-h-[300px] gray-scrollbar overflow-auto"
        bordered
        dataSource={items}
        renderItem={(item) => (
          <List.Item
            actions={[
              downloadFileLoader[item.upload_id] ? (
                <LoadingOutlined />
              ) : (
                <DownloadOutlined
                  onClick={() => dispatch(actionDownloadFile(item))}
                  color="red"
                  className="text-black/80"
                />
              ),
              <DeleteOutlined
                onClick={() => onDelete(item.upload_id)}
                color="red"
                className="text-red-500"
              />,
            ]}
          >
            <div className="flex items-center gap-x-3">
              {renderFileIcon(item?.url)}
              <span className="tracking-wide">{extractFilename(item.url)}</span>
            </div>
          </List.Item>
        )}
      />
    </>
  );
};

import { useState } from "react";
import axios from "axios";
import { BASE_URL_UPLOAD } from "../config/web-config";
import { toast } from "sonner";

const useFileUpload = () => {
  const [isUploading, setUploading] = useState(false);

  const handleUpload = async (files, setState) => {
    const formData = new FormData();

    files?.forEach((file) => formData.append("files", file));

    try {
      setUploading(true);
      const response = await axios.post(
        `${BASE_URL_UPLOAD}/upload-multiple`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("crmWebToken"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const newFiles = response.data.data;
      setState((prevFiles) => [
        ...prevFiles,
        ...newFiles.map((file) => ({
          ...file,
          file: files.find((f) => f.name === file.originalname),
        })),
      ]);
    } catch (error) {
      console.error("Upload failed:", error);
      toast.error(error?.response?.data?.message);
    } finally {
      setUploading(false);
    }
  };

  return { handleUpload, isUploading };
};

export default useFileUpload;

import { Button, Card, Col, Form, Input, Row, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import CustomIconText from "../../common/CustomIconText";
import requestForQuotation from "../../../assets/img/request-for-quotation.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { actionGetProductCategoryMaster } from "../../../store/services/productMasterService";
import {
  actionAddRequestForQuotation,
  actionUpdateRequestForQuotation,
  actionGetSingleRequestForQuotation,
} from "../../../store/services/requestForQuotationService";
import { useDispatch, useSelector } from "react-redux";

const CreateRequstForQuotation = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { productCategoryData } = useSelector((state) => state.productMaster);
  const {
    addRequestForQuotationLoader,
    getSingleRequestForQuotationData,
    updateRequestForQuotationLoader,
  } = useSelector((state) => state.requestForQuotation);
  const { requestForQuotationId } = location.state || {};

  useEffect(() => {
    if (requestForQuotationId) {
      dispatch(
        actionGetSingleRequestForQuotation({ id: requestForQuotationId })
      );
    }
  }, [requestForQuotationId, dispatch]);

  useEffect(() => {
    if (getSingleRequestForQuotationData && requestForQuotationId) {
      const {
        category,
        grade,
        thickness,
        custom_thickness,
        custom_made_size,
        sku,
        status,
        remark,
      } = getSingleRequestForQuotationData;

      form.setFieldsValue({
        product_category_id: category?.product_category_master_id || null,
        grade: grade || null,
        thickness: thickness || null,
        custom_thickness: custom_thickness || null,
        custom_made_size: custom_made_size || null,
        sku: sku || null,
        status: status || null,
        remark: remark || null,
      });
    }
  }, [requestForQuotationId, getSingleRequestForQuotationData, form]);

  const onFinish = (values) => {
    requestForQuotationId
      ? dispatch(
          actionUpdateRequestForQuotation({
            id: requestForQuotationId,
            req: values,
            navigate,
          })
        )
      : dispatch(
          actionAddRequestForQuotation({
            req: values,
            navigate,
          })
        );
  };

  const handleCancel = () => {
    navigate("/request-for-quotation");
  };

  useEffect(() => {
    dispatch(actionGetProductCategoryMaster());
  }, [dispatch]);

  const cateData =
    productCategoryData &&
    productCategoryData.length > 0 &&
    productCategoryData.map((data) => ({
      label: data.category_name,
      value: data.product_category_master_id,
    }));

  return (
    <section className="main-wrapper">
      <CustomIconText
        image={requestForQuotation}
        title={
          requestForQuotationId
            ? "Update Request For Quotation"
            : "Create Request For Quotation"
        }
        backArrow={true}
      />
      <Card className="main-card mt-5 w-full">
        <Form
          scrollToFirstError={true}
          form={form}
          layout="vertical"
          initialValues={{
            status: "DRAFT",
          }}
          name="basic"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                className="mb-[12px]"
                label="Category"
                name="product_category_id"
                size="small"
                rules={[
                  {
                    required: true,
                    message: "Please select category!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  options={cateData}
                  placeholder="Select category"
                  className="begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>
            </Col>
            <Col span={12} align="middle">
              <Form.Item
                className="mb-[12px]"
                label="Grade"
                name="grade"
                size="small"
                rules={[
                  {
                    required: false,
                    message: "Please Enter grade!",
                  },
                ]}
              >
                <Input
                  style={{ background: "white" }}
                  placeholder="Enter Grade"
                  className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>
            </Col>
            <Col span={12} align="middle">
              <Form.Item
                className="mb-[12px]"
                label="Thickness"
                name="thickness"
                size="small"
                rules={[
                  {
                    required: false,
                    message: "Please enter thickness!",
                  },
                ]}
              >
                <Input
                  style={{ background: "white" }}
                  placeholder="Enter thickness"
                  className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>
            </Col>
            <Col span={12} align="middle">
              <Form.Item
                className="mb-[12px]"
                label="Size if custom made"
                name="custom_made_size"
                size="samll"
                rules={[
                  {
                    required: false,
                    message: "Please enter size if custom made!",
                  },
                ]}
              >
                <Input
                  style={{ background: "white" }}
                  placeholder="Enter Size if custom made"
                  className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>
            </Col>
            <Col span={12} align="middle">
              <Form.Item
                className="mb-[12px]"
                label="SKU"
                name="sku"
                size="samll"
                rules={[
                  {
                    required: false,
                    message: "Please enter sku!",
                  },
                ]}
              >
                <Input
                  style={{ background: "white" }}
                  placeholder="Enter SKU"
                  className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>
            </Col>
            <Col span={12} align="middle">
              <Form.Item
                className="mb-[12px]"
                label="Custom Thickness"
                name="custom_thickness"
                size="samll"
                rules={[
                  {
                    required: false,
                    message: "Please enter custom thickness!",
                  },
                ]}
              >
                <Input
                  style={{ background: "white" }}
                  placeholder="Enter Custom Thickness"
                  className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>
            </Col>
            {/* <Col span={12}>
                <Form.Item
                  className="mb-[12px]"
                  label="Status"
                  name="status"
                  size="small"
                  rules={[
                    {
                      required: false,
                      message: "Please select status!",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    options={[
                      {
                        value: "DRAFT",
                        label: "Draft",
                      },
                      {
                        value: "PENDING",
                        label: "Pending",
                      },
                      {
                        value: "UNDER_REVIEW",
                        label: "Under Review",
                      },
                      {
                        value: "APPROVED",
                        label: "Approved",
                      },
                      {
                        value: "REJECTED",
                        label: "Rejected",
                      },
                      {
                        value: "IN_PROGRESS",
                        label: "In Progress",
                      },
                      {
                        value: "COMPLETED",
                        label: "Completed",
                      },
                      {
                        value: "CANCELLED",
                        label: "Cancelled",
                      },
                    ]}
                    placeholder="Select Status"
                    className="begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                  />
                </Form.Item>
              </Col> */}
            <Col span={12} align="middle">
              <Form.Item
                className="mb-[12px]"
                label="Remark"
                name="remark"
                size="samll"
                rules={[
                  {
                    required: false,
                    message: "Please enter remark!",
                  },
                ]}
              >
                <Input
                  style={{ background: "white" }}
                  placeholder="Enter remark"
                  className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>
            </Col>
            <Col
              span={24}
              align="middle"
              className="flex justify-center mt-[40px]"
            >
              <Form.Item className="mb-0">
                <Button
                  onClick={handleCancel}
                  type="button"
                  className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
                >
                  Cancel
                </Button>
              </Form.Item>

              <div className="ml-4">
                <Form.Item className="mb-0">
                  <Button
                    loading={
                      addRequestForQuotationLoader ||
                      updateRequestForQuotationLoader
                    }
                    type="primary"
                    className="root-btn min-h-[45px] min-w-[120px]"
                    htmlType="submit"
                  >
                    {requestForQuotationId ? "Save" : "Submit"}
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </Card>
    </section>
  );
};

export default CreateRequstForQuotation;

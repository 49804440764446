import {
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  ConfigProvider,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Select,
  Space,
  Table,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { IoIosRefresh } from "react-icons/io";
import { RiDownloadLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import * as XLSX from "xlsx";
import expenseVoucherIcon from "../../../assets/img/expense-voucher.svg";
import expensefirstdetail from "../../../assets/img/expensefirstdetail.svg";
import {
  actionChangeExpenseVoucherStatus,
  actionClearAllExpenseVouchers,
  actionDeleteExpenseVoucher,
  actionGetAllExpenseVouchers,
  actionGetExpenseVouchers,
} from "../../../store/services/financeService";
import PageTitle from "../../common/PageTitle";
import SortIcon from "../../common/SortIcon";
import TooltipWrapper from "../../common/TooltipWrapper";
import { BASE_URL } from "../../../config/web-config";
import { toast } from "sonner";

const getStatusStyles = (status) => {
  switch (status.toLowerCase()) {
    case "submitted":
      return "border-yellow-500 text-yellow-500 bg-yellow-50";
    case "approved":
      return "border-green-500 text-green-500 bg-green-50";
    case "unsubmitted":
      return "border-gray-500 text-gray-500 bg-gray-50";
    case "rejected":
      return "border-red-500 text-red-500 bg-red-50";
    case "reimbursed":
      return "border-blue-500 text-blue-500 bg-blue-50";
    case "pending approval":
      return "border-orange-500 text-orange-500 bg-orange-50";
    case "awaiting approval":
      return "border-purple-500 text-purple-500 bg-purple-50";
    default:
      return "border-gray-300 text-white-500 bg-white";
  }
};

const ExpenseVoucher = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const offsetRef = useRef(0);
  const [searchValue, setSearchValue] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [exportAll, setExportAll] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paymentOption, setPaymentOption] = useState(null);
  const navigate = useNavigate();

  const {
    getExpenseVouchersLoader,
    expenseVoucherData,
    expenseVoucherDataCount,
    allExpenseVoucherData,
    getAllExpenseVouchersLoader,
    deleteExpenseVoucherLoader,
    changeStatusLoader,
  } = useSelector((state) => state.finance);

  const { currentCompanyUser } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(
      actionGetExpenseVouchers({
        offset: offsetRef.current,
        search: searchValue,
      })
    );
  }, [dispatch]);

  const columns = [
    {
      title: "Voucher No",
      dataIndex: "voucher_no",
      key: "voucher_no",
      fixed: "left",
      sorter: () => true,
      sortIcon: () => <SortIcon />,
      width: 200,
      ellipsis: true,
      render: (text, data) => (
        <div
          onClick={() => {
            navigate(`/view-expense-voucher`, {
              state: { id: data.expense_voucher_id },
            });
          }}
          className="flex items-center text-brand cursor-pointer"
        >
          {text ? text : "-"}
        </div>
      ),
    },
    {
      title: "Voucher Date",
      dataIndex: "voucher_date",
      key: "voucher_date",
      width: 200,
      ellipsis: true,
      sorter: () => true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <span>
          {data.voucher_date
            ? dayjs(data.voucher_date).format("DD/MM/YYYY")
            : "-"}
        </span>
      ),
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      width: 200,
      ellipsis: true,
      sorter: () => true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (data.city ? data.city : "-"),
    },
    {
      title: "Expenses",
      dataIndex: "expenses",
      key: "expenses",
      width: 100,
      ellipsis: true,
      render: (text, data) => data.expense_voucher_items_count,
    },
    {
      title: "Total",
      dataIndex: "expense_voucher_items_amount_count",
      key: "expense_voucher_items_amount_count",
      width: 200,
      ellipsis: true,
      render: (text, data) => {
        const symbol = currentCompanyUser?.company?.currency
          ? currentCompanyUser.company.currency.currency_symbol
          : "";

        return (
          <>
            <span
              style={{
                fontFamily: "Arial, sans-serif",
              }}
            >
              {symbol}
            </span>
            <span style={{ marginLeft: "4px" }}>{text || ""}</span>
          </>
        );
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 200,
      ellipsis: true,
      sorter: () => true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <span
          className={`border-[0.5px] py-1.5 rounded-[5px] px-2.5 text-sm ${getStatusStyles(
            text.toLowerCase() === "submitted"
              ? data.approve_by?.id === currentCompanyUser?.id
                ? "pending approval"
                : "awaiting approval"
              : text
          )} uppercase rounded-md`}
        >
          {text.toLowerCase() === "submitted"
            ? data.approve_by?.id === currentCompanyUser?.id
              ? "PENDING APPROVAL"
              : "AWAITING APPROVAL"
            : text}
        </span>
      ),
    },
    {
      title: "Approver",
      dataIndex: "approve_by",
      key: "approve_by",
      width: 200,
      ellipsis: true,
      sorter: () => true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <div className="flex items-center gap-x-3">
          <Avatar size={30} src={text?.profile_pic} />
          <span className="text-[#7C7C7C] text-[15px]">{text?.full_name}</span>
        </div>
      ),
    },
    {
      title: "Submitter",
      dataIndex: "submitter",
      key: "submitter",
      width: 200,
      ellipsis: true,
      sorter: () => true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <div className="flex items-center gap-x-3">
          <Avatar size={30} src={text?.profile_pic} />
          <div className="flex flex-col gap-y-1">
            <span className="text-[#7C7C7C] text-[15px]">
              {text?.full_name}
            </span>
            <span className="text-[#7C7C7C] text-[11px]">
              on : {dayjs(data.created_at).format("DD/MM/YYYY")}
            </span>
          </div>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 100,
      fixed: "right",
      ellipsis: true,
      render: (text, data) => (
        <>
          <Dropdown.Button
            id="custom-drop"
            menu={{
              items: [
                {
                  label: <span>Edit</span>,
                  key: "Edit",
                  icon: <EditOutlined />,
                  onClick: () => {
                    navigate(`/update-expense-voucher`, {
                      state: data.expense_voucher_id,
                    });
                  },
                  disabled: data.status === "Reimbursed",
                },
                data.status.toLowerCase() === "unsubmitted" &&
                  data.submitter.id === currentCompanyUser?.id && {
                    label: (
                      <Popconfirm
                        okButtonProps={{
                          loading: changeStatusLoader,
                        }}
                        placement="topLeft"
                        description={`Are you sure to change status to submit?`}
                        onConfirm={() => {
                          changeStatus("Submitted", [data.expense_voucher_id]);
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <div
                          className="flex items-center"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <CheckCircleOutlined
                            style={{ fontSize: 12, color: "green" }}
                          />
                          <span
                            style={{
                              marginLeft: 8,
                              color: "green",
                            }}
                          >
                            Submit
                          </span>
                        </div>
                      </Popconfirm>
                    ),
                    key: "change-status",
                  },

                {
                  label: (
                    <Popconfirm
                      okButtonProps={{
                        loading: deleteExpenseVoucherLoader,
                      }}
                      placement="topLeft"
                      description={`Are you sure to delete this expense?`}
                      onConfirm={() => {
                        dispatch(
                          actionDeleteExpenseVoucher(data.expense_voucher_id)
                        );
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <div
                        className="flex items-center"
                        onClick={(e) => e.stopPropagation()} // Stop event propagation
                      >
                        <DeleteOutlined
                          style={{ fontSize: 12, color: "#ff4d4f" }}
                        />
                        <span
                          style={{
                            marginLeft: 8,
                            color: "#ff4d4f",
                          }}
                        >
                          Delete
                        </span>
                      </div>
                    </Popconfirm>
                  ),
                  key: "Delete",
                },
              ].filter(Boolean),
            }}
          >
            Action
          </Dropdown.Button>
        </>
      ),
    },
  ];

  useMemo(() => {}, [expenseVoucherData]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const handleSearchChange = (e) => {
    offsetRef.current = 0;
    setSearchValue(e.target.value);
    if (e.target.value === "") {
      dispatch(
        actionGetExpenseVouchers({
          offset: offsetRef.current,
          search: e.target.value,
        })
      );
    }
  };

  const handleBlurAndEnter = (e) => {
    if (searchValue) {
      offsetRef.current = 0;
      dispatch(
        actionGetExpenseVouchers({
          offset: offsetRef.current,
          search: e.target.value,
        })
      );
    }
  };

  const handleRefresh = () => {
    setSearchValue(null);
    offsetRef.current = 0;
    dispatch(
      actionGetExpenseVouchers({
        offset: offsetRef.current,
      })
    );
  };

  const exportAllToExcel = async () => {
    const data =
      (await allExpenseVoucherData) &&
      allExpenseVoucherData.length > 0 &&
      allExpenseVoucherData.map((data) => ({
        "Voucher No": data?.voucher_no ? data?.voucher_no : "-",
        "Voucher Date": data.voucher_date ? data.voucher_date : "-",
        City: data.city ? data.city : "-",
        Expenses: data.expense_voucher_items
          ? data.expense_voucher_items.length
          : "-",
        "Total Amount": data.expense_voucher_items_amount_count
          ? data.expense_voucher_items_amount_count
          : "-",
        // Status: data.status && data.status ? data.status : "-",
        Status:
          data.status?.toLowerCase() === "submitted"
            ? data.approve_by?.id === currentCompanyUser?.id
              ? "PENDING APPROVAL"
              : "AWAITING APPROVAL"
            : data.status,
        Approver: data.approve_by ? data.approve_by.full_name : "-",
        Submitter: data.submitter ? data.submitter.full_name : "-",
      }));
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Expense Voucher");
    // Generate the Excel file and initiate the download
    XLSX.writeFile(workbook, "Expense Voucher.xlsx");
    dispatch(actionClearAllExpenseVouchers({ setExportAll }));
  };

  useEffect(() => {
    if (
      exportAll &&
      allExpenseVoucherData &&
      allExpenseVoucherData.length > 0
    ) {
      exportAllToExcel();
    }
  }, [allExpenseVoucherData]);

  const onClickDownloadMenu = () => {
    dispatch(actionGetAllExpenseVouchers());
    setExportAll(true);
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    dispatch(
      actionGetExpenseVouchers({
        offset: offsetRef.current,
        search: searchValue,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
      })
    );
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 15 },
    },
  };

  const changeStatus = (newStatus, rows) => {
    const filteredRows = rows.filter((rowId) => {
      const matchingRow = expenseVoucherData.find(
        (data) => data.expense_voucher_id === rowId
      );
      return matchingRow && matchingRow.status !== newStatus;
    });

    if (filteredRows.length > 0) {
      const values = {
        status: newStatus,
        expense_voucher_id: filteredRows,
      };

      dispatch(actionChangeExpenseVoucherStatus({ values }));
    } else {
      toast.error(
        "No rows to update. All selected vouchers already have the specified status."
      );
    }
  };

  const selectedItems =
    expenseVoucherData?.filter((item) =>
      selectedRowKeys.includes(item.expense_voucher_id)
    ) || [];

  const total = selectedItems.reduce((sum, item) => {
    const amount = parseFloat(item.expense_voucher_items_amount_count) || 0;
    return sum + amount;
  }, 0);

  const totalAmount = total.toFixed(2);

  const handleChangeStatusReimbush = (values) => {
    const req = {
      status: "Reimbursed",
      expense_voucher_id: selectedRowKeys, // Use the filtered IDs
      expense_voucher_reimbursed: {
        reimbursed_date: dayjs(values.reimbursed_date).format("YYYY-MM-DD"),
        paid_via: values.paid_via,
        paid_via_remark: values.paid_via_remark,
        reference: values.reference,
        note: values.note,
      },
    };

    // Dispatch the action with the updated request
    dispatch(actionChangeExpenseVoucherStatus({ values: req, setIsModalOpen }));
  };

  const isExistsUnsubmittedData = useMemo(() => {
    return (
      (expenseVoucherData &&
        expenseVoucherData.length > 0 &&
        expenseVoucherData.filter(
          (data) =>
            selectedRowKeys.includes(data.expense_voucher_id) &&
            data.status.toLowerCase() === "unsubmitted"
        )) ||
      []
    );
  }, [expenseVoucherData, selectedRowKeys]);

  console.log(expenseVoucherData);


  const isApprover = useMemo(() => {
    return (
      expenseVoucherData &&
      expenseVoucherData.length > 0 &&
      expenseVoucherData.some(
        (data) =>
          selectedRowKeys &&
          selectedRowKeys.length > 0 &&
          selectedRowKeys.includes(data.expense_voucher_id) &&
          data.approve_by?.id === currentCompanyUser?.id
      )
    );
  }, [expenseVoucherData, selectedRowKeys, currentCompanyUser]);

  return (
    <section className="main-wrapper">
      <PageTitle
        image={expenseVoucherIcon}
        title="Expense Voucher"
        buttonText="Add Expense Voucher"
        buttonLink="/add-expense-voucher"
      />

      <div className="main-section">
        <Space.Compact className="w-full custom-input-sec relative flex mb-5">
          <Input
            value={searchValue}
            onChange={handleSearchChange}
            onBlur={handleBlurAndEnter}
            onPressEnter={handleBlurAndEnter}
            size="large"
            style={{ borderRight: "none" }}
            className=" hover:border-gray-400 bg-transparent"
            id="none-border"
            allowClear
            prefix={<SearchOutlined className="text-[#7C7C7C]" />}
            addonAfter={
              <div className="flex h-full items-center bg-white ">
                {selectedRowKeys.length > 0 &&
                  ((isExistsUnsubmittedData &&
                    isExistsUnsubmittedData.length > 0) ||
                    isApprover) && (
                    <div className="flex items-center gap-x-3  h-full border-r px-3 border-[#d9d9d9] ">
                      {isExistsUnsubmittedData &&
                        isExistsUnsubmittedData.length > 0 && (
                          <TooltipWrapper title={"Submit"}>
                            <Popconfirm
                              okButtonProps={{
                                loading: changeStatusLoader,
                              }}
                              placement="topLeft"
                              title="Approve"
                              description="Are you sure to submit All expense vouchers?"
                              onConfirm={() =>
                                changeStatus("Submitted", selectedRowKeys)
                              }
                              okText="Yes"
                              cancelText="No"
                            >
                              <span className="text-yellow-500 cursor-pointer border-[.8px]  border-yellow-500  text-[12px] rounded-[5px] py-[8px] px-[8px]">
                                Submit
                              </span>
                            </Popconfirm>
                          </TooltipWrapper>
                        )}

                      {isApprover && (
                        <>
                          <TooltipWrapper title={"Approve"}>
                            <Popconfirm
                              okButtonProps={{
                                loading: changeStatusLoader,
                              }}
                              placement="topLeft"
                              title="Approve"
                              description="Are you sure to approve All expense vouchers?"
                              onConfirm={() =>
                                changeStatus("Approved", selectedRowKeys)
                              }
                              okText="Yes"
                              cancelText="No"
                            >
                              <span className="text-green-500 cursor-pointer border-[.8px]  border-green-500  text-[12px] rounded-[5px] py-[8px] px-[8px]">
                                Approve
                              </span>
                            </Popconfirm>
                          </TooltipWrapper>

                          <TooltipWrapper title={"Reject"}>
                            <Popconfirm
                              okButtonProps={{
                                loading: changeStatusLoader,
                              }}
                              placement="topLeft"
                              title="Reject"
                              description="Are you sure to reject All expense vouchers?"
                              onConfirm={() =>
                                changeStatus("Rejected", selectedRowKeys)
                              }
                              okText="Yes"
                              cancelText="No"
                            >
                              <span className="text-red-500 cursor-pointer border-[.8px]  border-red-500  text-[12px] rounded-[5px] py-[8px] px-[8px]">
                                Reject
                              </span>
                            </Popconfirm>
                          </TooltipWrapper>
                          {/*  {isExistsUnsubmittedData &&
                          isExistsUnsubmittedData.length > 0 && ( */}
                          <span
                            onClick={showModal}
                            className="text-[#0299C8] cursor-pointer border-[.8px]  border-[#0299C8]  text-[12px] rounded-[5px] py-[8px] px-[8px]"
                          >
                            Record Reimbursement
                          </span>
                          {/* )} */}
                        </>
                      )}

                      <Modal
                        centered
                        onOk={handleCancel}
                        onCancel={handleCancel}
                        footer={false}
                        width={600}
                        title=""
                        open={isModalOpen}
                      >
                        <div className="flex items-center">
                          <img src={expensefirstdetail} alt="Beat Master" />
                          <h1 className="text-[#6883FD] text-[20px] ml-[8px] mt-[5px] font-medium">
                            Record Reimbursement
                          </h1>
                        </div>
                        <Form
                          labelAlign="left"
                          {...formItemLayout}
                          form={form}
                          onFinish={handleChangeStatusReimbush}
                          layout="horizontal"
                          className="mt-[20px] mb-0"
                        >
                          <Form.Item
                            // name="beat_name"
                            label="Reimbursement Amount"
                            className="mb-[12px] text-[17px]"
                          >
                            <p className="ml-3 text-[25px]">
                              <span>
                                {`${
                                  currentCompanyUser.company?.currency !== null
                                    ? currentCompanyUser.company?.currency
                                        .currency_symbol
                                    : ""
                                } `}
                              </span>{" "}
                              {totalAmount}
                            </p>
                            <p className="text-[14px] ml-3 text-[#7C7C7C]">
                              ( for {selectedRowKeys?.length} Expense Voucher )
                            </p>
                          </Form.Item>
                          <Divider />
                          <Form.Item
                            name="reimbursed_date"
                            label="Reimbursed on"
                            className="text-[17px] font-popinsMedium"
                            rules={[
                              {
                                required: true,
                                message: "Please input reimbursed on!",
                              },
                            ]}
                          >
                            <DatePicker
                              style={{ width: "100%" }}
                              placeholder="Enter reimbursed on"
                              className="rounded-[10px]"
                            />
                          </Form.Item>

                          <Form.Item
                            name="paid_via"
                            label="Paid Via"
                            className="text-[17px] font-popinsMedium"
                            rules={[
                              {
                                required: true,
                                message: "Please input paid via!",
                              },
                            ]}
                          >
                            <Select
                              // onChange={(val) => setPaymentOption(val)}
                              allowClear
                              placeholder="Select Paid Via"
                              options={[
                                { label: "Cash", value: "Cash" },
                                {
                                  label: "Bank Transfer",
                                  value: "Bank Transfer",
                                },
                                { label: "Credit Card", value: "Credit Card" },
                                { label: "Debit Card", value: "Debit Card" },
                                { label: "Check", value: "Check" },
                                {
                                  label: "Mobile Payment",
                                  value: "Mobile Payment",
                                },
                                {
                                  label: "Direct Deposit",
                                  value: "Direct Deposit",
                                },
                                {
                                  label: "Company Account",
                                  value: "Company Account",
                                },
                                { label: "Gift Card", value: "Gift Card" },
                                { label: "Other", value: "Other" },
                              ]}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>

                          <Form.Item
                            name="reference"
                            label="Reference#"
                            className="text-[17px] font-popinsMedium"
                            rules={[
                              {
                                required: true,
                                message: "Please input reference!",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Enter Reference"
                              className="rounded-[10px]"
                            />
                          </Form.Item>

                          <Form.Item
                            name="note"
                            label="Note"
                            className="text-[17px] font-popinsMedium"
                          >
                            <Input.TextArea
                              placeholder="Enter Note"
                              className="rounded-[10px]"
                            />
                          </Form.Item>

                          <Form.Item
                            name="paid_via_remark"
                            label="Remark"
                            className="text-[17px] font-popinsMedium"
                          >
                            <Input.TextArea
                              placeholder="Enter remark"
                              className="rounded-[10px]"
                            />
                          </Form.Item>
                          <div className="flex justify-center mt-[40px]">
                            <Form.Item className="mb-0">
                              <Button
                                type="button"
                                className="gray-button text-white hover:text-white min-h-[45px] min-w-[120px]"
                                onClick={handleCancel}
                              >
                                Cancel
                              </Button>
                            </Form.Item>
                            <div className="ml-4">
                              <Form.Item className="mb-0">
                                <Button
                                  type="primary"
                                  className="root-btn min-h-[45px] min-w-[120px]"
                                  loading={changeStatusLoader}
                                  htmlType="submit"
                                >
                                  Save
                                </Button>
                              </Form.Item>
                            </div>
                          </div>
                        </Form>
                      </Modal>
                      {/* </Popconfirm>
                    </TooltipWrapper> */}
                    </div>
                  )}

                <TooltipWrapper title="Refresh">
                  <Button
                    style={{ backgroundColor: "white" }}
                    className="h-full  default-button  icon-btn"
                    onClick={handleRefresh}
                  >
                    <IoIosRefresh size={17} color="#6883fd" />
                  </Button>
                </TooltipWrapper>
                <TooltipWrapper title="Download">
                  <Button
                    // disabled={!isAllowDownload}
                    style={{ backgroundColor: "white" }}
                    onClick={onClickDownloadMenu}
                    className="h-full  default-button  icon-btn"
                  >
                    <RiDownloadLine size={17} color="#6883fd" />
                  </Button>
                </TooltipWrapper>
              </div>
            }
            placeholder="Search here..."
          />
        </Space.Compact>
      </div>

      <ConfigProvider
        theme={{
          components: {
            Checkbox: {
              colorPrimary: "#6883FD",
              colorPrimaryHover: "#6883FD",
            },
          },
        }}
      >
        <Table
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
            columnWidth: 50,
            getCheckboxProps: (record) => ({
              disabled:
                /*  record.status.toLowerCase() === "unsubmitted" || */
                record.status.toLowerCase() === "reimbursed",
              name: record.status,
            }),
          }}
          sticky
          rowKey="expense_voucher_id"
          scrollToFirstRowOnChange={true}
          sortDirections={["ascend", "descend"]}
          onChange={handleSortChange}
          bordered
          scroll={{
            x: "max-content",
            y: window.innerWidth < 1500 ? "49vh" : "61vh",
          }}
          pagination={false}
          columns={columns}
          dataSource={expenseVoucherData}
          loading={
            getExpenseVouchersLoader ||
            changeStatusLoader ||
            getAllExpenseVouchersLoader
          }
        />
      </ConfigProvider>

      <div className="flex justify-end mt-3">
        <Pagination
          hideOnSinglePage
          responsive={true}
          current={offsetRef.current + 1}
          className="flex items-center"
          showSizeChanger={false}
          total={expenseVoucherDataCount}
          onChange={(e) => {
            offsetRef.current = e - 1;
            dispatch(
              actionGetExpenseVouchers({
                offset: offsetRef.current,
                search: searchValue,
              })
            );
          }}
        />
      </div>
    </section>
  );
};

export default ExpenseVoucher;

import { createBrowserRouter } from "react-router-dom";
import ChangeMobile from "../components/auth/ChangeMobile";
import CreateAccount from "../components/auth/CreateAccount";
import ErrorPage from "../components/auth/ErrorPage";
import ForgotPassword from "../components/auth/ForgotPassword";
import Login from "../components/auth/Login";
import LoginOTP from "../components/auth/LoginOTP";
import MainScreen from "../components/auth/MainScreen";
import OtpVerfication from "../components/auth/OtpVerfication";
import Register from "../components/auth/Register";
import ResetPassword from "../components/auth/ResetPassword";
import Begin from "../components/begin/Begin";
import BusinessHoursConfig from "../components/business/BusinessHoursConfig";
import NotFound from "../components/common/NotFound";
import Root from "../components/common/Root";
import Contacts from "../components/crm/contacts/Contacts";
import CreateContact from "../components/crm/contacts/CreateContact";
import Attendees from "../components/crm/events/Attendees";
import CreateEvent from "../components/crm/events/CreateEvent";
import EventInventory from "../components/crm/events/EventInventory";
import EventOverView from "../components/crm/events/EventOverView";
import Events from "../components/crm/events/Events";
import Exhibitors from "../components/crm/events/Exhibitors";
import Sessions from "../components/crm/events/Sessions";
import Speakers from "../components/crm/events/Speakers";
import Sponsor from "../components/crm/events/Sponsor";
import CreateLead from "../components/crm/leads/CreateLead";
import Leads from "../components/crm/leads/Leads";
import CreateOpportunity from "../components/crm/opportunities/CreateOpportunity";
import Opportunities from "../components/crm/opportunities/Opportunities";
import { PrivateRoute, PublicRoute } from "../components/helper/RouteHoc";
import Inventory from "../components/inventory/Inventory";
import AcceptInvitation from "../components/invitation/AcceptInvitation";
import AcceptInvitationCreateAccount from "../components/invitation/AcceptInvitationCreateAccount";
import BusinessPartners from "../components/master-data/business-partner/BusinessPartners";
import CreateBusinessPartner from "../components/master-data/business-partner/CreateBusinessPartner";
import ViewBusinessPartner from "../components/master-data/business-partner/ViewBusinessPartner";
import EventMaster from "../components/master-data/event-master/EventMasters";
import GeneralMasters from "../components/master-data/general-master/GeneralMasters";
import ProductMasters from "../components/master-data/product-master/ProductMasters";
import BeatPlanning from "../components/sales-force/BeatPlanning";
// import MySalesOrder from "../components/sales-orders/MySalesOrder";
import SalesInvoices from "../components/sales-orders/SalesInvoices";
import Permissions from "../components/setting/Permissions";
import Setting from "../components/setting/Setting";
import CompanySettingWrapper from "../components/setting/company-setting/CompanySettingWrapper";
import AddRole from "../components/setting/roles/AddRole";
import AddRoleProfile from "../components/setting/roles/AddRoleProfile";
import Hierarchy from "../components/setting/roles/Hierarchy";
import ModulePermissions from "../components/setting/roles/ModulePermissions";
import RoleProfile from "../components/setting/roles/RoleProfile";
import CreateUser from "../components/user/CreateUser";
import UpdateUser from "../components/user/UpdateUser";
import Users from "../components/user/Users";
import Task from "../components/task/Task";
import AttendanceLogs from "../components/setting/AttendanceLogs";
import SalesOrders from "../components/sales-orders/SalesOrders";
import ExpenseVoucher from "../components/finance/expense-voucher/ExpenseVoucher";
import AddExpenseVoucher from "../components/finance/expense-voucher/AddExpenseVoucher";
import ViewExpenseVoucher from "../components/finance/expense-voucher/ViewExpenseVoucher";
import CollectionManagement from "../components/collection-management/CollectionManagement";
import AddCollectionManagement from "../components/collection-management/AddCollectionManagement";
import AddSalesOrder from "../components/sales-orders/AddSalesOrder";
import RequstForQuotation from "../components/crm/requestForQuotation/RequstForQuotation";
import CreateRequstForQuotation from "../components/crm/requestForQuotation/CreateRequstForQuotation";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <PrivateRoute component={<Root />} />,
    children: [
      {
        path: "/",
        element: <MainScreen />,
        index: true,
      },
      {
        path: "/inventory",
        element: <Inventory />,
      },
      {
        path: "/users",
        element: <Users />,
      },
      {
        path: "/create-user",
        element: <CreateUser />,
      },
      {
        path: "/update-user",
        element: <UpdateUser />,
      },
      {
        path: "/company-setting",
        element: <CompanySettingWrapper />,
      },
      {
        path: "/sales-orders",
        element: <SalesOrders />,
      },
      {
        path: "/add-sales-order",
        element: <AddSalesOrder />,
      },
      {
        path: "/update-sales-order",
        element: <AddSalesOrder />,
      },

      {
        path: "/sales-invoices",
        element: <SalesInvoices />,
      },
      {
        path: "/purchase-orders",
        element: <h1>purchase-orders</h1>,
      },
      {
        path: "/sales-orders",
        element: <h1>sales-orders</h1>,
      },
      {
        path: "/add-role",
        element: <AddRole />,
      },
      {
        path: "/add-role-profile",
        element: <AddRoleProfile />,
      },
      {
        path: "/update-role-profile",
        element: <AddRoleProfile />,
      },
      {
        path: "/permissions",
        element: <Permissions />,
      },
      {
        path: "/role-hierarchy",
        element: <Hierarchy />,
      },
      {
        path: "/events",
        element: <Events />,
      },
      {
        path: "/create-event",
        element: <CreateEvent />,
      },
      {
        path: "/update-event",
        element: <CreateEvent />,
      },
      {
        path: "/event-overview",
        element: <EventOverView />,
      },

      {
        path: "/attendees",
        element: <Attendees />,
      },
      {
        path: "/exhibitors",
        element: <Exhibitors />,
      },

      {
        path: "/sessions",
        element: <Sessions />,
      },
      {
        path: "/speakers",
        element: <Speakers />,
      },
      {
        path: "/sponsor",
        element: <Sponsor />,
      },
      {
        path: "/event-inventory",
        element: <EventInventory />,
      },
      {
        path: "/module-permissions",
        element: <ModulePermissions />,
      },

      {
        path: "/lead-management",
        element: <Leads />,
      },
      {
        path: "/create-lead",
        element: <CreateLead />,
      },
      {
        path: "/update-lead",
        element: <CreateLead />,
      },
      {
        path: "/beat-planning",
        element: <BeatPlanning />,
      },
      {
        path: "/hr",
        element: <h1>HR</h1>,
      },
      {
        path: "/task",
        element: <Task />,
      },
      {
        path: "/beat",
        element: <h1>Beat</h1>,
      },
      {
        path: "/credit-notes",
        element: <h1>Credit notes</h1>,
      },
      {
        path: "/expense-voucher",
        element: <ExpenseVoucher />,
      },
      {
        path: "/add-expense-voucher",
        element: <AddExpenseVoucher />,
      },
      {
        path: "/update-expense-voucher",
        element: <AddExpenseVoucher />,
      },
      {
        path: "/view-expense-voucher",
        element: <ViewExpenseVoucher />,
      },

      {
        path: "/collection-management",
        element: <CollectionManagement />,
      },

      {
        path: "/add-collection-management",
        element: <AddCollectionManagement />,
      },

      {
        path: "/update-collection-management",
        element: <AddCollectionManagement />,
      },
      {
        path: "/delivery",
        element: <h1>Delivery</h1>,
      },
      {
        path: "/master-data",
        element: <h1>Master Data</h1>,
      },
      {
        path: "/event-masters",
        element: <EventMaster />,
      },
      {
        path: "/material-masters",
        element: <ProductMasters />,
      },
      {
        path: "/general-masters",
        element: <GeneralMasters />,
      },
      {
        path: "/setting",
        element: <Setting />,
      },
      {
        path: "/attendance-logs",
        element: <AttendanceLogs />,
      },
      {
        path: "/role-profile",
        element: <RoleProfile />,
      },
      {
        path: "/begin",
        element: (
          <>
            <Begin />,
          </>
        ),
      },
      {
        path: "/opportunity-management",
        element: <Opportunities />,
      },
      {
        path: "/create-opportunity",
        element: <CreateOpportunity />,
      },
      {
        path: "/update-opportunity",
        element: <CreateOpportunity />,
      },
      {
        path: "/business-partners",
        element: <BusinessPartners />,
      },
      {
        path: "/create-business-partner",
        element: <CreateBusinessPartner />,
      },
      {
        path: "/update-business-partner",
        element: <CreateBusinessPartner />,
      },
      {
        path: "/view-business-partner",
        element: <ViewBusinessPartner />,
      },
      {
        path: "/contacts",
        element: <Contacts />,
      },
      {
        path: "/create-contact",
        element: <CreateContact />,
      },
      {
        path: "/update-contact",
        element: <CreateContact />,
      },
      {
        path: "/request-for-quotation",
        element: <RequstForQuotation />,
      },
      {
        path: "/create-request-for-quotation",
        element: <CreateRequstForQuotation />,
      },
      {
        path: "/update-request-for-quotation",
        element: <CreateRequstForQuotation />,
      },
    ],
  },

  {
    path: "/error-page",

    element: (
      <>
        <ErrorPage />,
      </>
    ),
  },

  {
    path: "/change-mobile",
    element: <ChangeMobile />,
  },
  {
    path: "/privacy-policy",
    element: (
      <div className="text-center py-5">
        <h1 className="font-popinsSemiBold">Privacy Policy</h1>
      </div>
    ),
  },
  {
    path: "/terms-and-condition",
    element: (
      <div className="text-center py-5">
        <h1 className="font-popinsSemiBold">Terms and Condition</h1>
      </div>
    ),
  },
  {
    path: "/business-hours",
    element: <BusinessHoursConfig />,
  },
  {
    path: "/accept-invitation",
    element: <AcceptInvitation />,
  },
  {
    path: "/accept-invitation-create-account",
    element: <AcceptInvitationCreateAccount />,
  },

  {
    element: <PublicRoute />,
    children: [
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/sign-otp",
        element: <LoginOTP />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/reset-password",
        element: <ResetPassword />,
      },
      {
        path: "/login",
        element: <Login />,
      },

      {
        path: "/otp",
        element: <OtpVerfication />,
      },
      {
        path: "/create-account",
        element: <CreateAccount />,
      },
    ],
  },

  {
    path: "*",
    element: <NotFound />,
  },
]);

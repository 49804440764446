import {
  Button,
  Card,
  Col,
  Descriptions,
  Form,
  Image,
  Input,
  Row,
  Select,
  Spin,
} from "antd";
import React, { useEffect } from "react";
import add from "../../../assets/img/add-role.svg";
import report from "../../../assets/img/report-to.svg";
import CustomIconText from "../../common/CustomIconText";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  actionAddRoleProfile,
  actionGetAllRoleProfile,
  actionGetRoleProfile,
  actionGetSingleRoleProfile,
  actionUpdateRoleProfile,
} from "../../../store/services/settingService";

const AddRoleProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [form] = Form.useForm();
  const { profileId } = location.state || {};
  const {
    addRoleProfileLoader,
    roleProfileDetail,
    getSingleRoleProfileLoader,
    roleAllProfile,
    getAllRoleProfileLoader,
    updateRoleProfileLoader,
  } = useSelector((state) => state.setting);

  useEffect(() => {
    dispatch(actionGetAllRoleProfile());
  }, [dispatch]);

  useEffect(() => {
    if (profileId) {
      dispatch(actionGetSingleRoleProfile(profileId));
    }
  }, [profileId, dispatch]);

  useEffect(() => {
    if (profileId) {
      if (roleProfileDetail) {
        form.setFieldsValue({
          name: roleProfileDetail.name,
          description: roleProfileDetail.description,
        });
      }
    }
  }, [roleProfileDetail, form, dispatch, profileId]);

  const cloneProfile =
    roleAllProfile &&
    roleAllProfile.length > 0 &&
    roleAllProfile.map((profile, index) => {
      return {
        label:
          profile && profile.name != null
            ? profile.name.charAt(0).toUpperCase() +
              profile.name.slice(1).toLowerCase()
            : "-",
        value: profile.id,
      };
    });

  const onFinish = (values) => {
    profileId
      ? dispatch(actionUpdateRoleProfile({ id: profileId, values, navigate }))
      : dispatch(actionAddRoleProfile({ values, navigate }));
  };

  return (
    <section className="main-wrapper">
      <div className="mb-4">
        <CustomIconText image={add} title="New Role Profile" backArrow={true} />
      </div>
      <Card className="main-card mt-5 ">
        <Spin spinning={getSingleRoleProfileLoader}>
          <Form
            form={form}
            layout="vertical"
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="Profile Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Profile Name!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Profile Name" />
                </Form.Item>
              </Col>
              {!profileId && (
                <Col span={12}>
                  <Form.Item
                    label="Clone Profile"
                    name="clone_profile"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Clone Profile!",
                      },
                    ]}
                  >
                    <Select
                      loading={getAllRoleProfileLoader}
                      disabled={getAllRoleProfileLoader}
                      allowClear
                      showSearch
                      placeholder="Select Clone Profile "
                      optionFilterProp="children"
                      className=""
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={cloneProfile}
                    />
                  </Form.Item>
                </Col>
              )}

              <Col span={profileId ? 12 : 24}>
                <Form.Item
                  label="Profile Description"
                  name="description"
                  rules={[
                    {
                      required: false,
                      message: "Please Enter Description!",
                    },
                  ]}
                >
                  <Input.TextArea placeholder="Enter Description" />
                </Form.Item>
              </Col>

              <Col
                span={24}
                className="flex items-center my-4 justify-center gap-x-6"
              >
                <Form.Item>
                  <Button
                    onClick={() => navigate(-1)}
                    type="button"
                    className="gray-button text-white hover:text-white gray-hover min-h-[45px] min-w-[120px]"
                    htmlType="button"
                  >
                    Cancel
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    loading={addRoleProfileLoader || updateRoleProfileLoader}
                    type="primary"
                    className="root-btn  min-h-[45px] min-w-[120px]"
                    htmlType="submit"
                  >
                    {profileId ? "Update" : "Submit"}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Card>
    </section>
  );
};

export default AddRoleProfile;

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "sonner";
import { handleErrorMessage } from "../../components/helper/common-function";
import { BASE_URL } from "../../config/web-config";
import { handleDeleteResponse, handleGetResponse } from "./commonService";

export const actionGetExpenseVouchers = createAsyncThunk(
  "actionGetExpenseVouchers",
  async ({
    search = "",
    orderByColumn = "",
    order = "",
    status = "",
    offset = 0,
  } = {}) => {
    return handleGetResponse(
      `expense-voucher?limit=10&offset=${offset}${
        search ? `&search=${search}` : ""
      }${orderByColumn ? `&orderByColumn=${orderByColumn}` : ""}${
        order ? `&order=${order}` : ""
      }`
    );
  }
);

export const actionGetExpenseVoucherDetails = createAsyncThunk(
  "actionGetExpenseVoucherDetails",
  async (id) => {
    return handleGetResponse(`expense-voucher/${id}`);
  }
);

export const actionGetAllExpenseVouchers = createAsyncThunk(
  "actionGetAllExpenseVouchers",
  async () => {
    return handleGetResponse(`expense-voucher?getall=YES`);
  }
);

export const actionClearAllExpenseVouchers = createAsyncThunk(
  "actionClearAllExpenseVouchers",
  async ({ setExportAll }) => {
    setExportAll && setExportAll(false);
    return "";
  }
);

export const actionAddExpenseVoucher = createAsyncThunk(
  "actionAddExpenseVoucher",
  async ({ values, navigate }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.post(`${BASE_URL}/expense-voucher`, values);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate && navigate(-1);
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionUpdateExpenseVoucher = createAsyncThunk(
  "actionUpdateExpenseVoucher",
  async ({ id, values, navigate }, { rejectWithValue, dispatch }) => {
    try {
      const res = await axios.put(`${BASE_URL}/expense-voucher/${id}`, values);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate && navigate(-1);
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionDeleteExpenseVoucher = createAsyncThunk(
  "actionDeleteExpenseVoucher",
  async (id) => {
    return handleDeleteResponse(`expense-voucher/${id}`, id);
  }
);

export const actionChangeExpenseVoucherStatus = createAsyncThunk(
  "actionChangeExpenseVoucherStatus",

  async ({ values = {}, setIsModalOpen = false } = {}, { rejectWithValue }) => {
    try {
      const res = await axios.patch(`${BASE_URL}/expense-voucher`, values);
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setIsModalOpen && setIsModalOpen(false);
        return { status: values.status, ids: values.expense_voucher_id };
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionGetExpenseItemsBySupplierName = createAsyncThunk(
  "actionGetExpenseItemsBySupplierName",
  async (search) => {
    return handleGetResponse(
      `expense-voucher-items/supplier-name?getall=YES${
        search ? "search=" + search : ""
      }`
    );
  }
);

export const actionGetCollectionManagement = createAsyncThunk(
  "actionGetCollectionManagement",
  ({ offset, getall, orderByColumn, order, search }) => {
    return handleGetResponse(
      `collection-management?limit=10&offset=${offset}${
        getall ? "&getall=" + getall : ""
      }${orderByColumn ? "&orderByColumn=" + orderByColumn : ""}${
        order ? "&order=" + order : ""
      }${search ? "&search=" + search : ""}`
    );
  }
);

export const actionGetAllCollectionManagement = createAsyncThunk(
  "actionGetAllCollectionManagement",
  () => {
    return handleGetResponse(`collection-management?getall=YES`);
  }
);

export const actionClearAllCollectionManagement = createAsyncThunk(
  "actionClearAllCollectionManagement",
  async ({ setExportAll }) => {
    setExportAll && setExportAll(false);
    return "";
  }
);

export const actionAddCollectionManagement = createAsyncThunk(
  "actionAddCollectionManagement",
  async ({ req, navigate }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/collection-management`,
        req
      );
      const { data, message: customMsg, status } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg);
        navigate && navigate("/collection-management");
        return data;
      } else {
        toast.error(customMsg);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionDeleteCollectionManagement = createAsyncThunk(
  "actionDeleteCollectionManagement",
  async ({ collectionId, setIsDeleteModalOpen }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/collection-management/${collectionId}`
      );
      const { status, message: customMsg, data } = response.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        setIsDeleteModalOpen && setIsDeleteModalOpen(false);
        return collectionId;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

export const actionGetCollectionManagementDetails = createAsyncThunk(
  "actionGetCollectionManagementDetails",
  async (id) => {
    return handleGetResponse(`collection-management/${id}`);
  }
);

export const actionUpdateCollectionManagement = createAsyncThunk(
  "actionUpdateCollectionManagement",
  async ({ id, req, navigate }, { rejectWithValue }) => {
    try {
      const res = await axios.patch(
        `${BASE_URL}/collection-management/${id}`,
        req
      );
      const { status, message: customMsg, data } = res.data;
      if (parseInt(status) === 200) {
        toast.success(customMsg, 5);
        navigate && navigate(-1);
        return data;
      } else {
        toast.error(customMsg, 5);
        return rejectWithValue(customMsg);
      }
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

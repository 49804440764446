import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Empty,
  Image,
  Modal,
  Pagination,
  Spin,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import businessPartner from "../../../assets/img/businessPartner.svg";
import {
  actionClearAllBusinessPartner,
  actionDeleteBusinessPartner,
  actionGetAllBusinessPartner,
  actionGetBusinessPartner,
  actionGetSingleBusinessPartner,
} from "../../../store/services/masterDataService";
import CustomInputWithButtons from "../../common/CustomInputWithButtons ";
import PageTitle from "../../common/PageTitle";
import SortIcon from "../../common/SortIcon";

const BusinessPartners = () => {
  const [search, setSearch] = useState("");
  const [exportAll, setExportAll] = useState(false);
  const [viewBusinessPartnerModalOpen, setViewBusinessPartnerModalOpen] =
    useState(false);
  const [businessPartnerId, setBusinessPartnerId] = useState("");
  const [deleteBusinessPartnerModalOpen, setDeleteBusinessPartnerModalOpen] =
    useState(false);
  const offsetRef = useRef(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    getBusinessPartnerLoader,
    businessPartnerData,
    getAllBusinessPartnerLoader,
    allBusinessPartnerData,
    businessPartnersCount,
    deleteBusinessPartnerLoader,
    getBusinessPartnerDetailLoader,
    businessPartnerDetail,
  } = useSelector((state) => state.masterData);

  useEffect(() => {
    dispatch(actionGetBusinessPartner({ offset: offsetRef.current, search }));
  }, []);

  useEffect(() => {
    if (businessPartnerId && viewBusinessPartnerModalOpen) {
      dispatch(actionGetSingleBusinessPartner(businessPartnerId));
    }
  }, [viewBusinessPartnerModalOpen, businessPartnerId]);

  const columns = [
    {
      title: "Business Partner Name",
      key: "1",
      width: 200,
      ellipsis: true,
      dataIndex: "business_partner_name",
      sorter: true,
      sortIcon: () => <SortIcon />,
      fixed: "left",
      render: (text, data) => (
        <div
          onClick={() => {
            navigate("/view-business-partner", {
              state: { id: data.business_partner_id },
            });
          }}
          className="flex text-brand items-center cursor-pointer"
        >
          {text ?? "-"}
        </div>
      ),
    },
    {
      title: "Business Partner Type",
      key: "2",
      width: 200,
      ellipsis: true,
      dataIndex: "business_partner_type",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span className="ml-2">{text?.business_partner_type_name ?? "-"}</span>
      ),
    },
    {
      title: "Email",
      key: "3",
      width: 200,
      ellipsis: true,
      dataIndex: "email",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text ?? "-"}</span>,
    },
    {
      title: "Mobile",
      key: "4",
      width: 200,
      ellipsis: true,
      dataIndex: "mobile",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text ?? "-"}</span>,
    },
    {
      title: "Address 1",
      key: "5",
      width: 200,
      dataIndex: "address_1",
      ellipsis: true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      // render: (text) => <span className="ml-2">{text ?? "-"}</span>,
      render: (text) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1, tooltip: false }}
          style={{ width: 200 }}
          className="ml-2"
        >
          {text || "-"}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Address 2",
      key: "6",
      width: 200,
      dataIndex: "address_2",
      ellipsis: true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1, tooltip: false }}
          style={{ width: 200 }}
          className="ml-2"
        >
          {text || "-"}
        </Typography.Paragraph>
      ),
    },

    {
      title: "Country",
      key: "10",
      ellipsis: true,
      width: 200,
      dataIndex: "country",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text?.name || "-"}</span>,
    },
    {
      title: "State",
      key: "9",
      ellipsis: true,
      width: 200,
      dataIndex: "state",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text?.name || "-"}</span>,
    },
    {
      title: "City",
      key: "7",
      // width: "15%",
      width: 200,
      ellipsis: true,
      dataIndex: "city",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text ?? "-"}</span>,
    },
    {
      title: "Pin Code",
      key: "8",
      width: 200,
      ellipsis: true,
      dataIndex: "zip_code",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Beat",
      key: "9",
      ellipsis: true,
      width: 200,
      dataIndex: "beat_master",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text?.beat_name || "-"}</span>,
    },
    {
      title: "ERP Code",
      key: "11",
      ellipsis: true,
      width: 200,
      dataIndex: "erp_code",

      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "GST Number",
      key: "11",
      ellipsis: true,
      width: 200,
      dataIndex: "gst_number",
      sorter: (a, b) => a.gst_number - b.gst_number,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "PAN Card No",
      key: "12",
      ellipsis: true,
      width: 200,
      dataIndex: "pan_card_no",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "MSME Number",
      key: "13",
      ellipsis: true,
      width: 200,
      dataIndex: "msme_number",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "FSSAI",
      key: "14",
      ellipsis: true,
      width: 200,
      dataIndex: "fssai",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "CIN No",
      key: "15",
      ellipsis: true,
      width: 200,
      dataIndex: "cin_no",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Type of Company",
      key: "16",
      ellipsis: true,
      width: 200,
      dataIndex: "type_of_company",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span className="ml-2">
          {text?.company_type !== null ? text?.company_type : "-"}
        </span>
      ),
    },
    {
      title: "Industry",
      key: "17",
      ellipsis: true,
      width: 200,
      dataIndex: "industry",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text?.industry || "-"}</span>,
    },
    {
      title: "Company Size",
      key: "18",
      ellipsis: true,
      width: 200,
      dataIndex: "company_size",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Revenue",
      key: "19",
      ellipsis: true,
      width: 200,
      dataIndex: "revenue",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Sales Volume",
      key: "20",
      ellipsis: true,
      width: 200,
      dataIndex: "sales_volume",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Agreement Duration",
      key: "21",
      ellipsis: true,
      width: 200,
      dataIndex: "agreement_duration",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Commission Structure",
      key: "22",
      ellipsis: true,
      width: 200,
      dataIndex: "commission_structure",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Terms and Conditions",
      key: "23",
      ellipsis: true,
      width: 200,
      dataIndex: "terms_and_conditions",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1, tooltip: false }}
          style={{ width: 200 }}
          className="ml-2"
        >
          {text || "-"}
        </Typography.Paragraph>
      ) /*  <span className="ml-2">{text || "-"}</span> */,
    },
    {
      title: "Feedback Mechanism",
      key: "24",
      ellipsis: true,
      width: 200,
      dataIndex: "feedback_mechanism",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1, tooltip: false }}
          style={{ width: 200 }}
          className="ml-2"
        >
          {text || "-"}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Renewal Process",
      key: "25",
      ellipsis: true,
      width: 200,
      dataIndex: "renewal_process",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1, tooltip: false }}
          style={{ width: 200 }}
          className="ml-2"
        >
          {text || "-"}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Termination Conditions",
      key: "26",
      ellipsis: true,
      width: 200,
      dataIndex: "termination_conditions",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <Typography.Paragraph
          ellipsis={{ rows: 1, tooltip: false }}
          style={{ width: 200 }}
          className="ml-2"
        >
          {text || "-"}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Bank Name",
      key: "27",
      ellipsis: true,
      width: 200,
      dataIndex: "bank_name",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Branch Name",
      key: "28",
      ellipsis: true,
      width: 200,
      dataIndex: "branch_name",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Account Type",
      key: "29",
      ellipsis: true,
      width: 200,
      dataIndex: "account_type",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "IFSC",
      key: "30",
      ellipsis: true,
      width: 200,
      dataIndex: "ifsc",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Account Number",
      key: "31",
      ellipsis: true,
      width: 200,
      dataIndex: "account_number",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Primary Contact Name",
      key: "38",
      ellipsis: true,
      width: 200,
      dataIndex: "primary_contact_name",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Primary Contact Email",
      key: "39",
      ellipsis: true,
      width: 200,
      dataIndex: "primary_contact_email",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Primary Contact Phone",
      key: "40",
      ellipsis: true,
      width: 200,
      dataIndex: "primary_contact_phone",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Secondary Contact Name",
      key: "41",
      ellipsis: true,
      width: 200,
      dataIndex: "secondary_contact_name",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Secondary Contact Email",
      key: "42",
      ellipsis: true,
      width: 200,
      dataIndex: "secondary_contact_email",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Secondary Contact Phone",
      key: "43",
      ellipsis: true,
      width: 200,
      dataIndex: "secondary_contact_phone",
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "150px",
      fixed: "right",
      render: (text, data) => (
        <>
          <Dropdown.Button
            id="custom-drop"
            menu={{
              items: [
                {
                  label: <span>Edit</span>,
                  key: "Edit",
                  icon: <EditOutlined />,
                  onClick: () =>
                    navigate("/update-business-partner", {
                      state: { businessPartnerId: data.business_partner_id },
                    }),
                },
                {
                  label: <span style={{ color: "#ff4d4f" }}>Delete</span>,
                  key: "Delete",
                  icon: (
                    <DeleteOutlined
                      style={{ fontSize: 12, color: "#ff4d4f" }}
                    />
                  ),
                  onClick: () => {
                    setDeleteBusinessPartnerModalOpen(true);
                    setBusinessPartnerId(data.business_partner_id);
                  },
                },
              ].filter(Boolean),
            }}
          >
            Action
          </Dropdown.Button>
        </>
      ),
    },
  ];

  const handleRefresh = () => {
    setSearch(null);
    dispatch(
      actionGetBusinessPartner({
        offset: offsetRef.current,
      })
    );
  };

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      dispatch(
        actionGetBusinessPartner({
          offset: offsetRef.current,
          search: e.target.value,
        })
      );
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      dispatch(
        actionGetBusinessPartner({
          offset: offsetRef.current,
          search: e.target.value,
        })
      );
    }
  };

  const exportAllToExcel = async () => {
    const data =
      (await allBusinessPartnerData) &&
      allBusinessPartnerData.length > 0 &&
      allBusinessPartnerData.map((item) => ({
        "Business Partner Name": item.business_partner_name ?? "-",
        "Business Partner Type":
          item.business_partner_type?.business_partner_type_name ?? "-",
        Email: item.email ?? "-",
        Mobile: item.mobile ?? "-",
        "Address 1": item.address_1 ?? "-",
        "Address 2": item.address_2 ?? "-",
        Country: item.country?.name ?? "-",
        State: item.state?.name ?? "-",
        City: item.city ?? "-",
        "Pin Code": item.zip_code ?? "-",
        Beat: item.beat ?? "-",
        "GST Number": item.gst_number ?? "-",
        "PAN Card No": item.pan_card_no ?? "-",
        "MSME Number": item.msme_number ?? "-",
        FSSAI: item.fssai ?? "-",
        "CIN No": item.cin_no ?? "-",
        "Type of Company":
          item &&
          item.type_of_company != null &&
          item.type_of_company.company_type
            ? item.type_of_company.company_type
            : "-",
        Industry: item.industry?.industry ?? "-",
        "Company Size": item.company_size ?? "-",
        Revenue: item.revenue ?? "-",
        "Sales Volume": item.sales_volume ?? "-",
        "Agreement Duration": item.agreement_duration ?? "-",
        "Commission Structure": item.commission_structure ?? "-",
        "Terms and Conditions": item.terms_and_conditions ?? "-",
        "Feedback Mechanism": item.feedback_mechanism ?? "-",
        "Renewal Process": item.renewal_process ?? "-",
        "Termination Conditions": item.termination_conditions ?? "-",
        "Bank Name": item.bank_name ?? "-",
        "Branch Name": item.branch_name ?? "-",
        "Account Type": item.account_type ?? "-",
        IFSC: item.ifsc ?? "-",
        "Account Number": item.account_number ?? "-",
        "Primary Contact Name": item.primary_contact_name ?? "-",
        "Primary Contact Email": item.primary_contact_email ?? "-",
        "Primary Contact Phone": item.primary_contact_phone ?? "-",
        "Secondary Contact Name": item.secondary_contact_name ?? "-",
        "Secondary Contact Email": item.secondary_contact_email ?? "-",
        "Secondary Contact Phone": item.secondary_contact_phone ?? "-",
      }));

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet2");
    // Generate the Excel file and initiate the download
    XLSX.writeFile(workbook, "business-partner.xlsx");
    dispatch(actionClearAllBusinessPartner({ setExportAll }));
  };

  useEffect(() => {
    if (
      exportAll &&
      allBusinessPartnerData &&
      allBusinessPartnerData.length > 0
    ) {
      exportAllToExcel();
    }
  }, [allBusinessPartnerData]);

  const onClickDownloadMenu = () => {
    dispatch(actionGetAllBusinessPartner());
    setExportAll(true);
  };
  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    dispatch(
      actionGetBusinessPartner({
        offset: offsetRef.current,
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
      })
    );
  };

  const handleCloseDeleteModal = () => {
    setBusinessPartnerId("");
    setDeleteBusinessPartnerModalOpen(false);
  };

  return (
    <>
      <section className="main-wrapper">
        <PageTitle
          image={businessPartner}
          title="Business Partners"
          buttonText="Add Business Partner"
          buttonLink="/create-business-partner"
        />
        <CustomInputWithButtons
          handleRefresh={handleRefresh}
          handleSearch={handleSearch}
          handleBlurAndPressEnter={handleBlurAndPressEnter}
          searchValue={search}
          onClickDownloadMenu={onClickDownloadMenu}
        />

        <Table
          sticky
          rowKey="business_partner_id"
          scrollToFirstRowOnChange={true}
          sortDirections={["ascend", "descend"]}
          onChange={handleSortChange}
          bordered
          scroll={{
            x: "max-content",
            y: window.innerWidth < 1500 ? "49vh" : "61vh",
          }}
          pagination={false}
          columns={columns}
          dataSource={businessPartnerData}
          loading={getBusinessPartnerLoader || getAllBusinessPartnerLoader}
        />

        <div className="flex justify-between mt-3">
          <p className="text-[15px] font-popinsMedium">
            Total Business Partners :{" "}
            {businessPartnersCount ? businessPartnersCount : 0}
          </p>
          <Pagination
            hideOnSinglePage
            responsive={true}
            current={offsetRef.current + 1}
            className="flex items-center"
            showSizeChanger={false}
            total={businessPartnersCount}
            onChange={(e) => {
              offsetRef.current = e - 1;
              dispatch(
                actionGetBusinessPartner({ offset: offsetRef.current, search })
              );
            }}
          />
        </div>
        <Modal
          loading={getBusinessPartnerDetailLoader}
          centered={true}
          width={700}
          footer={false}
          destroyOnClose={() => {
            setViewBusinessPartnerModalOpen(false);
            // handleCancel();
          }}
          open={viewBusinessPartnerModalOpen}
          title=""
          onCancel={() => {
            setViewBusinessPartnerModalOpen(false);
            // setBusinessPartnerData(false);
            // handleCancel();
          }}
          onOk={() => {
            setViewBusinessPartnerModalOpen(false);
            // setBusinessPartnerData(false);
            // handleCancel();
          }}
        >
          <Spin spinning={false}>
            {/* {getExhibitorDetail && ( */}
            <div>
              <div className="text-brand flex items-center gap-x-3">
                <Image
                  src={businessPartner}
                  alt="image"
                  // height={20}
                  // width={20}
                  preview={false}
                />
                <h1 className="lg:text-lg text-base font-popinsRegular">
                  Business Partner
                </h1>
              </div>
              <div className="flex flex-col gap-y-4">
                <div className="flex items-center pt-5 gap-x-4">
                  <Image
                    preview={false}
                    height={100}
                    width={100}
                    className="rounded-[10px]"
                    src={businessPartnerDetail?.logo}
                    alt="Profile Pic"
                  />
                  {/*  <div className="flex justify-start flex-col gap-y-1">
                    <p className="text-sm font-popinsMedium">Kathryn Murphy</p>
                    <p className="text-sm  text-[#818181]">
                      abc@gmail.com
                    </p>
                  </div> */}
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">
                    Business Partner Name:
                  </p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {/* {getExhibitorDetail.exhibitor_contact_person} */}
                    {businessPartnerDetail?.business_partner_name}
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">
                    Business Partner Type :
                  </p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {businessPartnerDetail?.business_partner_type
                      ?.business_partner_type_name ?? "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">Email</p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {businessPartnerDetail?.email ?? "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">Mobile:</p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {businessPartnerDetail?.mobile ?? "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">Address 1:</p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {businessPartnerDetail?.address_1 ?? "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">Address 2:</p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {businessPartnerDetail?.address_2 ?? "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">Country:</p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {businessPartnerDetail?.country?.name ?? "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">State:</p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {businessPartnerDetail?.state?.name ?? "-"}{" "}
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">City:</p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {businessPartnerDetail?.city ?? "-"}{" "}
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">Pin Code:</p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {businessPartnerDetail?.zip_code ?? "-"}
                  </p>
                </div>

                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">GST Number:</p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {businessPartnerDetail?.gst_number ?? "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">PAN Card No:</p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {businessPartnerDetail?.pan_card_no ?? "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">MSME Number:</p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {businessPartnerDetail?.msme_number ?? "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">FSSAI:</p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {businessPartnerDetail?.fssai ?? "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">CIN No:</p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {businessPartnerDetail?.cin_no ?? "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">
                    Type of Company:
                  </p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {businessPartnerDetail &&
                    businessPartnerDetail?.type_of_company !== null &&
                    businessPartnerDetail?.type_of_company?.company_type
                      ? businessPartnerDetail?.type_of_company?.company_type
                      : "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">Industry:</p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {businessPartnerDetail?.industry?.name ?? "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">
                    Company Size:
                  </p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {businessPartnerDetail?.company_size ?? "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">Revenue:</p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {businessPartnerDetail?.revenue ?? "-"}{" "}
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">
                    Sales Volume:
                  </p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {businessPartnerDetail?.sales_volume ?? "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">
                    Agreement Duration:
                  </p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {businessPartnerDetail?.agreement_duration ?? "-"} year
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">
                    Commission Structure:
                  </p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {businessPartnerDetail?.commission_structure != null
                      ? `${businessPartnerDetail.commission_structure}% per sale`
                      : "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">
                    Terms and Conditions:
                  </p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {businessPartnerDetail?.termination_conditions ?? "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">
                    Feedback Mechanism:
                  </p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {businessPartnerDetail?.feedback_mechanism ?? "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">
                    Renewal Process:
                  </p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {businessPartnerDetail?.renewal_process ?? "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">
                    Termination Conditions:
                  </p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {businessPartnerDetail?.termination_conditions != null
                      ? `${businessPartnerDetail.termination_conditions}30 days notice`
                      : "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">Bank Name:</p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {businessPartnerDetail?.bank_name ?? "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">Branch Name:</p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {businessPartnerDetail?.branch_name ?? "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">
                    Account Type:
                  </p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {businessPartnerDetail?.account_type ?? "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">IFSC:</p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {businessPartnerDetail?.ifsc ?? "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">
                    Account Number:
                  </p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {businessPartnerDetail?.account_number ?? "-"}
                  </p>
                </div>

                {/*   <div>
                  <h2 className="text-lg font-semibold">Other Certificates:</h2>
                  {businessPartnerDetail?.other_certificates_attachments &&
                  businessPartnerDetail?.other_certificates_attachments.length >
                    0 ? (
                    businessPartnerDetail?.other_certificates_attachments.map(
                      (doc, index) => (
                        <div
                          key={index}
                          className="flex items-center gap-x-4 py-2 cursor-pointer"
                          onClick={() => handleClick(doc.url)}
                        >
                          {doc.type === "pdf" ? (
                            <FilePdfOutlined
                              className="text-red-500"
                              style={{ fontSize: "24px" }}
                            />
                          ) : (
                            <PictureOutlined
                              className="text-blue-500"
                              style={{ fontSize: "24px" }}
                            />
                          )}
                          <p className="text-sm text-black font-popinsMedium">
                            {doc.name}
                          </p>
                        </div>
                      )
                    )
                  ) : (
                    <p className="text-sm text-[#818181]">
                      No documents available.
                    </p>
                  )}
                </div> */}
                {/*    <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">
                    GSTN Certificate:
                  </p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    ABCDE123456F
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">PAN Card:</p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    ABCDE1234F
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">
                    Cancelled Cheque:
                  </p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    ABCDE123456F
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">
                    MSME Details:
                  </p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    UDYAM-XXXX-XXXX
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">Contract:</p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    Contract.pdf
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">
                    Other Certificates:
                  </p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    Certificate.pdf
                  </p>
                </div> */}
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">
                    Primary Contact Name:
                  </p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {businessPartnerDetail?.primary_contact_name ?? "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">
                    Primary Contact Email:
                  </p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {businessPartnerDetail?.primary_contact_email ?? "-"}{" "}
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">
                    Primary Contact Phone:
                  </p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {businessPartnerDetail?.primary_contact_phone ?? "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">
                    Secondary Contact Name:
                  </p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {businessPartnerDetail?.secondary_contact_name ?? "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">
                    Secondary Contact Email:
                  </p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {businessPartnerDetail?.secondary_contact_email ?? "-"}
                  </p>
                </div>
                <div className="flex gap-x-6 items-start gap-y-1">
                  <p className="text-xs text-[#818181] w-[40%]">
                    Secondary Contact Phone:
                  </p>
                  <p className="text-sm text-start w-[60%] text-black font-popinsMedium">
                    {businessPartnerDetail?.secondary_contact_phone ?? "-"}
                  </p>
                </div>
              </div>
            </div>
            {/* )} */}
          </Spin>
        </Modal>
        <Modal
          className="max-h-[100vh]"
          centered={true}
          footer={false}
          destroyOnClose={handleCloseDeleteModal}
          open={deleteBusinessPartnerModalOpen}
          title=""
          onCancel={handleCloseDeleteModal}
          onOk={handleCloseDeleteModal}
        >
          <p className="text-[19px] text-black font-popinsMedium mt-2">
            Are you sure you want to remove this Business Partner ?
          </p>
          <div className=" flex justify-end mt-[30px]">
            <Button
              onClick={handleCloseDeleteModal}
              type="button"
              className="gray-button text-white hover:text-white min-h-[45px] min-w-[110px]"
            >
              Cancel
            </Button>

            <div className="ml-4">
              <Button
                onClick={() =>
                  dispatch(
                    actionDeleteBusinessPartner({
                      id: businessPartnerId,
                      handleCloseDeleteModal,
                    })
                  )
                }
                loading={deleteBusinessPartnerLoader}
                className="delete-btn min-h-[45px] min-w-[110px]"
              >
                Delete
              </Button>
            </div>
          </div>
        </Modal>
      </section>
    </>
  );
};

export default BusinessPartners;

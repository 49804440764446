import { message } from "antd";
import axios from "axios";
import { BASE_URL } from "../../config/web-config";
import { toast } from "sonner";
import {
  extractFilename,
  handleErrorMessage,
} from "../../components/helper/common-function";
import { createAsyncThunk } from "@reduxjs/toolkit";

// For Get Api
export const handleGetResponse = async (url) => {
  try {
    const res = await axios.get(`${BASE_URL}/${url}`);
    const { status, message: customMsg } = res.data;
    if (parseInt(status) === 200) {
      return res.data;
    } else if (parseInt(status) === 404) {
      return "";
    } else {
      toast.error(customMsg, 5);
      throw new Error(`API Error: ${customMsg}`);
    }
  } catch (error) {
    handleErrorMessage(error);
  }
};

// For Post API
export const handlePostResponse = async (url, req, navigate, route) => {
  try {
    const response = await axios.post(`${BASE_URL}/${url}`, req);
    const { status, message: customMsg, data } = response.data;
    if (parseInt(status) === 200) {
      toast.success(customMsg, 5);
      if (navigate && route) {
        navigate(route);
      }
      return data;
    } else {
      toast.error(customMsg, 5);
      return false;
    }
  } catch (error) {
    handleErrorMessage(error);
  }
};

// For Patch (Update) API
export const handlePatchResponse = async (url, req, navigate, route) => {
  try {
    const response = await axios.patch(`${BASE_URL}/${url}`, req);
    const { status, message: customMsg, data } = response.data;
    if (parseInt(status) === 200) {
      toast.success(customMsg, 5);
      if (navigate && route) {
        navigate(route);
      }
      return data;
    } else {
      toast.error(customMsg, 5);
      return false;
    }
  } catch (error) {
    handleErrorMessage(error);
  }
};

// For Delete API
export const handleDeleteResponse = async (url, req, setEditingKey) => {
  try {
    const response = await axios.delete(`${BASE_URL}/${url}`);
    const { status, message: customMsg } = response.data;
    if (parseInt(status) === 200) {
      toast.success(customMsg, 5);
      setEditingKey && setEditingKey("");
      return req;
    } else {
      toast.error(customMsg, 5);
      return false;
    }
  } catch (error) {
    handleErrorMessage(error);
  }
};

export const handleUpload = async (files, setState) => {
  const formData = new FormData();
  files.forEach((file) => formData.append("files", file));

  try {
    setUploading(true);
    const response = await axios.post(
      `${BASE_URL_UPLOAD}/upload-multiple`,
      formData,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("crmWebToken"),
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log("Upload successful:", response.data);
    const newFiles = response.data.data;
    setState((prevFiles) => [
      ...prevFiles,
      ...newFiles.map((file) => ({
        ...file,
        file: files.find((f) => f.name === file.originalname),
      })),
    ]);
  } catch (error) {
    console.error("Upload failed:", error);
  } finally {
    setUploading(false);
  }
};

export const actionDownloadFile = createAsyncThunk(
  "actionDownloadFile",
  async (blob) => {
    console.log(blob);
    const url = blob && blob.url ? blob.url : blob;
    const newBlobFile = extractFilename(url);
    try {
      axios({
        url: `${BASE_URL}/uploads/download/${newBlobFile}`, // replace with your server-side endpoint
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", newBlobFile);
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {
      handleErrorMessage(error);
    }
  }
);

import { Image } from "antd";
import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import icon from "../../assets/img/upload-dragger.svg";

const CustomUploadDragger = ({
  image = icon,
  onDrop,
  onChange,
  loading = false,
  accept = ".jpeg,.jpg,.png",
}) => {
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = "copy";
  };

  return (
    <>
      <div
        onDrop={onDrop}
        onDragOver={handleDragOver}
        className={`${
          loading ? "opacity-50 pointer-events-none" : ""
        }  border rounded-md min-h-24  border-dashed border-[#B2ACC8] bg-[#FBFCFF] flex items-center justify-center flex-col`}
      >
        {loading ? (
          <label className="px-3 mt-2 flex flex-col justify-center items-center gap-y-4  cursor-pointer">
            <LoadingOutlined className="text-[20px]" color="#7C7C7C" />
            <p className="text-sm font-popinsRegular text-[#000]">
              Uploading...
            </p>
          </label>
        ) : (
          <label
            htmlFor="file-upload"
            className=" px-3 min-h-24 h-full w-full flex flex-col justify-center items-center gap-y-4  cursor-pointer"
          >
            <Image
              height={20}
              preview={false}
              width={20}
              src={image}
              alt="upload icon"
            />
            <p className="text-sm font-popinsRegular text-black">
              Add Attachment
            </p>
          </label>
        )}
      </div>
      <input
        disabled={loading}
        style={{ display: "none" }}
        id="file-upload"
        type="file"
        multiple
        hidden
        onChange={onChange}
        className="hidden"
        accept={accept}
      />
    </>
  );
};

export default CustomUploadDragger;

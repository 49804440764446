import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { FaCloudUploadAlt, FaSpinner } from "react-icons/fa";

import {
  AutoComplete,
  Badge,
  Button,
  ConfigProvider,
  Image,
  Input,
  InputNumber,
  Modal,
  Popover,
  Select,
  Table,
  Tooltip,
} from "antd";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Col, DatePicker, Form, Row, Spin } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import addButtonWhite from "../../../assets/img/add-button-white.svg";
import expenseVoucherIcon from "../../../assets/img/expense-voucher.svg";
import { BASE_URL_UPLOAD } from "../../../config/web-config";
import {
  actionAddExpenseVoucher,
  actionGetExpenseItemsBySupplierName,
  actionGetExpenseVoucherDetails,
  actionUpdateExpenseVoucher,
} from "../../../store/services/financeService";
import {
  actionGetAllExchageCurrencyMaster,
  actionGetExpenseCategoryMaster,
  actionGetPaymentMethodMaster,
} from "../../../store/services/generalMasterService";
import CustomIconText from "../../common/CustomIconText";
import { renderFileIcon } from "../../helper/common-function";

const requiredFields = [
  "expense_category_type_master_id",
  "amount",
  "amount_currency_id",
  "payment_method_master_id",
  "supplier_name",
  "invoice_number",
];

const fieldNames = {
  expense_category_type_master_id: "Expense category",
  amount: "Amount",
  amount_currency_id: "Amount currency",
  payment_method_master_id: "Payment method",
  supplier_name: "Supplier name",
  invoice_number: "Invoice number",
};

const getFieldErrors = (row) => {
  const errors = {};

  // Check if at least one required field is filled
  const hasAnyRequiredFieldFilled = requiredFields.some(
    (field) =>
      field !== "amount_currency_id" &&
      row[field] !== undefined &&
      row[field] !== null &&
      row[field] !== ""
  );

  requiredFields.forEach((field) => {
    if (field === "amount_currency_id") {
      // Check if amount_currency_id is default filled and no other required field is filled
      if (
        hasAnyRequiredFieldFilled &&
        (row[field] === undefined || row[field] === null || row[field] === "")
      ) {
        errors[field] = "Amount currency is required.";
      }
    } else if (
      row[field] === undefined ||
      row[field] === null ||
      row[field] === ""
    ) {
      if (field === "amount" && row[field] === 0) {
        errors[field] = "Amount cannot be zero.";
      } else {
        errors[field] = `${fieldNames[field]} is required.`;
      }
    }
  });

  return errors;
};

const AddExpenseVoucher = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentCompanyUser } = useSelector((state) => state.auth);
  const { expenseVoucherDetailData, getExpenseVoucherDetailLoader } =
    useSelector((state) => state.finance);

  const { allCurrencyMasterData } = useSelector((state) => state.generalMaster);

  const { state: id } = useLocation();

  const [form] = Form.useForm();
  const [fileLists, setFileLists] = useState({});
  const [data, setData] = useState([]);
  const [fieldErrors, setFieldErrors] = useState({});

  const [defaultCurrencyId, setDefaultCurrencyId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(actionGetExpenseCategoryMaster());
      dispatch(actionGetPaymentMethodMaster());
      dispatch(actionGetAllExchageCurrencyMaster({ is_dropdown: "YES" }));
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (allCurrencyMasterData && allCurrencyMasterData.length > 0) {
      let defaultCurrency = null;

      if (currentCompanyUser?.company?.currency?.currency) {
        defaultCurrency = allCurrencyMasterData.find(
          (curr) =>
            curr?.exchange_currency?.currency ===
            currentCompanyUser.company.currency.currency
        );
      }

      if (!defaultCurrency) {
        defaultCurrency = allCurrencyMasterData.find(
          (curr) => curr?.exchange_currency?.currency === "INR"
        );
      }

      setDefaultCurrencyId(defaultCurrency || null);
    }
  }, [allCurrencyMasterData, currentCompanyUser, defaultCurrencyId]);

  const fetchExpenseVoucherData = async (data) => {
    // Map rows and handle null/undefined values concisely
    const rows = (data.expense_voucher_items || []).map((item, i) => ({
      key: i + 1,
      receipt_attechments: item.receipt_attechments || [],
      expense_category_type_master_id:
        item.expense_category_type_master?.expense_category_type_master_id ||
        null,
      payment_method_master_id:
        item.payment_method_master?.payment_method_master_id || null,
      amount: item.amount,
      amount_currency_id: item.amount_currency
        ? item.amount_currency?.id
        : null,
      description: item.description || "",
      supplier_name: item.supplier_name || null,
      invoice_number: item.invoice_number || "",
      amount_currency_rate: item.amount_currency_rate || "",
    }));

    // Set form values
    form.setFieldsValue({
      voucher_date: dayjs(data.voucher_date),
      city: data.city || "",
      notes: data.notes || "",
    });

    setData(rows);

    // Prepare file lists
    const newFileLists = {};
    rows.forEach((row) => {
      if (row.receipt_attechments.length > 0) {
        newFileLists[row.key] = row.receipt_attechments.map(
          (att) => att.upload
        );
      }
    });

    setFileLists(newFileLists);
  };

  useEffect(() => {
    if (id) {
      dispatch(actionGetExpenseVoucherDetails(id));
    } else {
      const initialData = Array.from({ length: 5 }, (_, index) => ({
        key: index + 1,
        receipt_attechments: [],
        expense_category_type_master_id: null,
        payment_method_master_id: null,
        amount: "",
        amount_currency_id: defaultCurrencyId?.exchange_currency?.id,
        description: "",
        supplier_name: null,
        invoice_number: "",
        amount_currency_rate: defaultCurrencyId?.value_in_currency_locale,
      }));
      setData(initialData);
    }
  }, [id, defaultCurrencyId, dispatch]);

  useEffect(() => {
    if (id && expenseVoucherDetailData) {
      fetchExpenseVoucherData(expenseVoucherDetailData);
    }
  }, [expenseVoucherDetailData, id]);

  const handleFileUpload = (recordKey, newFiles) => {
    setFileLists((prevFileLists) => ({
      ...prevFileLists,
      [recordKey]: [...(prevFileLists[recordKey] || []), ...newFiles],
    }));

    // Update the data state to include the new files in the receipt field
    setData((prevData) =>
      prevData.map((item) =>
        item.key === recordKey
          ? {
              ...item,
              receipt_attechments: [
                ...(item.receipt_attechments || []),
                ...newFiles,
              ],
            }
          : item
      )
    );
  };

  const handleSubmit = (values) => {
    let allErrors = {};
    let validRows = [];

    data.forEach((row, index) => {
      // Check if at least one required field is filled
      const hasAnyRequiredFieldFilled = requiredFields.some(
        (field) =>
          field !== "amount_currency_id" &&
          row[field] !== undefined &&
          row[field] !== null &&
          row[field] !== ""
      );

      if (hasAnyRequiredFieldFilled) {
        const errors = getFieldErrors(row);
        if (Object.keys(errors).length === 0) {
          validRows.push(row);
        } else {
          allErrors[index] = errors;
        }
      }
    });

    setFieldErrors(allErrors);

    if (Object.keys(allErrors).length > 0) {
      const firstErrorColumn = Object.keys(allErrors)[0];
      //  handleScrollToErrorColumn(firstErrorColumn); // Scroll to the column with the first error

      return;
    }

    const expenseRows = validRows.map((row) => ({
      key: row.key,
      expense_category_type_master_id: row.expense_category_type_master_id,
      amount: row.amount,
      amount_currency_id: row.amount_currency_id,
      description: row.description || "",
      payment_method_master_id: row.payment_method_master_id || "",
      supplier_name: row.supplier_name || null,
      amount_currency_rate: parseFloat(row.amount_currency_rate),
      invoice_number: row.invoice_number || "",
      receipt_attechments:
        (fileLists[row.key] &&
          fileLists[row.key].length > 0 &&
          fileLists[row.key].map((file) => file.upload_id)) ||
        [],
    }));

    const req = {
      ...values,
      notes: values.notes || "",
      city: values.city || "",
      voucher_date: dayjs(values.voucher_date).format("YYYY-MM-DD"),
      expense_voucher_items: expenseRows,
    };

    if (expenseRows.length > 0) {
      if (id) {
        dispatch(
          actionUpdateExpenseVoucher({ id, values: req, navigate, expenseRows })
        );
      } else {
        dispatch(
          actionAddExpenseVoucher({ values: req, navigate, expenseRows })
        );
      }
    } else {
      toast.error("Please Fill one Expense completely.");
    }
  };

  return (
    <Spin spinning={getExpenseVoucherDetailLoader}>
      <section className="main-wrapper">
        <CustomIconText
          image={expenseVoucherIcon}
          title={id ? "Update Expense Voucher" : "Add Expense Voucher"}
          backArrow={true}
        />

        <Form
          form={form}
          initialValues={{
            voucher_date: dayjs(),
          }}
          className="mt-5 expense-voucher-form"
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                rules={[
                  { required: true, message: "Please select voucher date" },
                ]}
                name="voucher_date"
                label="Voucher Date"
              >
                <DatePicker
                  className="w-full !bg-white"
                  placeholder="Select Voucher Date"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="city" label="City">
                <Input className="w-full" placeholder="Enter City" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="notes" label="Notes">
                <Input.TextArea
                  rows={4}
                  className="w-full py-2"
                  placeholder="Enter Notes"
                />
              </Form.Item>
            </Col>
          </Row>
          <ExpenseTable
            fieldErrors={fieldErrors}
            onFileUpload={handleFileUpload}
            fileLists={fileLists}
            data={data}
            setFileLists={setFileLists}
            setData={setData}
            defaultCurrency={defaultCurrencyId}
            setFieldErrors={setFieldErrors}
          />
        </Form>
      </section>
    </Spin>
  );
};

export default AddExpenseVoucher;

const ExpenseTable = ({
  data,
  setData,
  onFileUpload,
  fileLists,
  setFileLists,
  fieldErrors,
  setFieldErrors,
  defaultCurrency,
}) => {
  const dispatch = useDispatch();
  const { currentCompanyUser } = useSelector((state) => state.auth);

  const { addExpenseVoucherLoader, updateExpenseVoucherLoader } = useSelector(
    (state) => state.finance
  );
  const {
    expenseCategoryMasterData,
    getExpenseCategoryLoader,
    getPaymentMethodLoader,
    paymentMethodMasterData,
    allCurrencyMasterData,
    getAllExchangeCurrencyMasterLoader,
  } = useSelector((state) => state.generalMaster);
  const { supplierData } = useSelector((state) => state.finance);

  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);

  const [searchValue, setSearchValue] = useState("");
  const [popoverForm] = Form.useForm();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [uploadingFiles, setUploadingFiles] = useState({});
  const [tempFileLists, setTempFileLists] = useState({});

  const categoryOptions = useMemo(() => {
    return (
      (expenseCategoryMasterData &&
        expenseCategoryMasterData.length > 0 &&
        expenseCategoryMasterData.map((item) => ({
          value: item.expense_category_type_master_id,
          label: item.expense_category_type,
        }))) ||
      []
    );
  }, [expenseCategoryMasterData]);

  const paymentOptions = useMemo(() => {
    return (
      (paymentMethodMasterData &&
        paymentMethodMasterData.length > 0 &&
        paymentMethodMasterData.map((item) => ({
          value: item.payment_method_master_id,
          label: item.payment_method,
        }))) ||
      []
    );
  }, [paymentMethodMasterData]);

  const currencyOptions = useMemo(() => {
    return (
      allCurrencyMasterData &&
      allCurrencyMasterData.length > 0 &&
      allCurrencyMasterData.map((item) => ({
        value: item?.exchange_currency?.id,
        label: item?.exchange_currency?.currency,
      }))
    );
  }, [allCurrencyMasterData]);

  const handleDelete = (key) => {
    const newData = data.filter((item) => item.key !== key);
    setData(newData);
    setFileLists((prevFileLists) => {
      const newFileLists = { ...prevFileLists };
      delete newFileLists[key];
      return newFileLists;
    });

    setFieldErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[key];
      return newErrors;
    });
  };

  useEffect(() => {
    if (searchValue) {
      dispatch(actionGetExpenseItemsBySupplierName(searchValue));
    }
  }, [searchValue, dispatch]);

  const columns = [
    {
      title: <span className="text-[14px]">Receipt</span>,
      dataIndex: "receipt_attechments",
      width: 100,
      align: "middle",
      render: (_, record) => {
        const fileCount = fileLists[record.key]?.length || 0;

        return (
          <Tooltip
            title={
              fileCount > 0 ? `${fileCount} file(s) uploaded` : "Upload files"
            }
          >
            <Badge count={fileCount} size="small" offset={[0, 0]}>
              {fileCount <= 0 ? (
                <Button
                  icon={<PlusOutlined />}
                  onClick={() => showModal(record)}
                  className="flex plus-btn items-center justify-center"
                />
              ) : (
                <div
                  onClick={() => showModal(record)}
                  className="flex items-center justify-center cursor-pointer"
                >
                  {fileLists?.[record.key]?.[0]?.url &&
                    renderFileIcon(fileLists[record.key][0].url, 24, false)}
                </div>
              )}
            </Badge>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "expense_category_type_master_id",
      title: (
        <span className="text-[14px]">
          Category<span className="ml-[2px] text-red-500">*</span>
        </span>
      ),
      //  width: 250,
      render: (text, record, index) => (
        <>
          <Select
            allowClear
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            variant="borderless"
            value={text}
            loading={getExpenseCategoryLoader}
            disabled={getExpenseCategoryLoader}
            options={categoryOptions}
            placeholder="Select Category"
            style={{ width: "100%" }}
            onChange={(value) =>
              handleChange(record.key, "expense_category_type_master_id", value)
            }
          />
          {fieldErrors[index] &&
            fieldErrors[index]["expense_category_type_master_id"] && (
              <div className="text-red-500 mt-1 ml-[11px]">
                {fieldErrors[index]["expense_category_type_master_id"]}
              </div>
            )}
        </>
      ),
    },
    {
      title: (
        <span className="text-[14px]">
          Amount<span className="ml-[2px] text-red-500">*</span>
        </span>
      ),
      ellipsis: true,
      dataIndex: "amount",
      width: 250,
      render: (text, record, index) => {
        const singleCurrency =
          allCurrencyMasterData &&
          allCurrencyMasterData.length > 0 &&
          allCurrencyMasterData.find(
            (curr) => curr.exchange_currency?.id === record.amount_currency_id
          );

        const content = () => {
          return (
            <Form
              form={popoverForm}
              onFinish={(values) => {
                // Update amount_currency_rate for the correct record
                popoverForm && popoverForm.resetFields();
                setData((prevData) => {
                  return prevData.map((item) =>
                    item.key === record.key
                      ? { ...item, amount_currency_rate: values.exchange_rate }
                      : item
                  );
                });

                setIsPopoverOpen(false);
              }}
              layout="vertical"
              className="mt-[20px] mb-0"
            >
              <Form.Item
                name="exchange_rate"
                label={`Exchange Rate (in ${singleCurrency?.base_currency?.currency})`}
                className="mb-[12px]"
                size="small"
                rules={[
                  {
                    required: true,
                    message: "Please input exchange rate!",
                  },
                ]}
              >
                <Input
                  style={{ background: "white" }}
                  placeholder="Enter Exchange rate"
                  className="indent-5 begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
                />
              </Form.Item>

              <div className="flex justify-center mt-[20px]">
                <Form.Item className="mb-0">
                  <Button
                    onClick={() => setIsPopoverOpen(false)}
                    type="button"
                    className="gray-button text-white hover:text-white min-h-[30px] min-w-[100px]"
                  >
                    Cancel
                  </Button>
                </Form.Item>

                <div className="ml-4">
                  <Form.Item className="mb-0">
                    <Button
                      type="primary"
                      className="root-btn min-h-[30px] min-w-[100px]"
                      htmlType="submit"
                    >
                      Save
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          );
        };

        return (
          <div>
            <InputNumber
              controls={false}
              placeholder="0.00"
              className="borderless"
              variant="borderless"
              value={text}
              onChange={(value) => handleChange(record.key, "amount", value)}
              addonBefore={
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  style={{ width: 80, textAlign: "left" }}
                  variant="borderless"
                  value={record.amount_currency_id}
                  onChange={(value) =>
                    handleChange(record.key, "amount_currency_id", value)
                  }
                  loading={getAllExchangeCurrencyMasterLoader}
                  disabled={getAllExchangeCurrencyMasterLoader}
                  placeholder="Currency"
                  options={currencyOptions}
                />
              }
            />
            {singleCurrency?.exchange_currency?.currency !==
              currentCompanyUser?.company?.currency?.currency &&
              record.amount_currency_id && (
                <div className="border-t border-gray text-gray flex items-center">
                  1 {singleCurrency?.exchange_currency?.currency} ={" "}
                  {record.amount_currency_rate ||
                    singleCurrency?.value_in_currency_locale}{" "}
                  {singleCurrency?.base_currency?.currency}
                  <Popover
                    open={isPopoverOpen === record.key}
                    onOpenChange={(visible) => {
                      if (!visible) {
                        setIsPopoverOpen(null);
                      }
                    }}
                    content={content}
                    title={null}
                    trigger="click"
                  >
                    <EditOutlined
                      onClick={() => {
                        popoverForm.setFieldsValue({
                          exchange_rate:
                            record.amount_currency_rate ||
                            singleCurrency?.value_in_currency_locale,
                        });
                        setIsPopoverOpen(
                          isPopoverOpen === record.key ? null : record.key
                        );
                      }}
                      className="cursor-pointer text-blue-600 ml-2"
                    />
                  </Popover>
                </div>
              )}
            <div className="text-red-500 mt-1 ml-[11px]">
              {fieldErrors[index] && fieldErrors[index]["amount"] && (
                <div>{fieldErrors[index]["amount"]}</div>
              )}
              {fieldErrors[index] &&
                !fieldErrors[index]["amount"] &&
                fieldErrors[index]["amount_currency_id"] && (
                  <div>{fieldErrors[index]["amount_currency_id"]}</div>
                )}
            </div>
          </div>
        );
      },
    },
    {
      title: (
        <span className="text-[14px]">
          Payment Method<span className="ml-[2px] text-red-500">*</span>
        </span>
      ),
      dataIndex: "payment_method_master_id",
      render: (text, record, index) => (
        <>
          <Select
            loading={getPaymentMethodLoader}
            disabled={getPaymentMethodLoader}
            allowClear
            variant="borderless"
            value={text}
            placeholder="Payment Method"
            style={{ width: "100%" }}
            onChange={(value) =>
              handleChange(record.key, "payment_method_master_id", value)
            }
            options={paymentOptions}
          />
          {fieldErrors[index] &&
            fieldErrors[index]["payment_method_master_id"] && (
              <div className="text-red-500 mt-1 ml-[11px]">
                {fieldErrors[index]["payment_method_master_id"]}
              </div>
            )}
        </>
      ),
    },
    {
      title: (
        <span className="text-[14px]">
          Supplier<span className="ml-[2px] text-red-500">*</span>
        </span>
      ),
      dataIndex: "supplier_name",
      render: (text, record, index) => {
        return (
          <>
            <AutoComplete
              variant="borderless"
              style={{ width: 200 }}
              placeholder="Select Supplier or enter supplier"
              value={text}
              onChange={(val) => handleChange(record.key, "supplier_name", val)}
              onSearch={(val) =>
                dispatch(actionGetExpenseItemsBySupplierName(val))
              }
              options={
                (supplierData &&
                  supplierData.length > 0 &&
                  supplierData?.map((item) => ({
                    label: item.supplier_name,
                    value: item.supplier_name,
                  }))) ||
                []
              }
            />
            {fieldErrors[index]?.supplier_name && (
              <div className="text-red-500 mt-1 ml-[11px]">
                {fieldErrors[index]["supplier_name"]}
              </div>
            )}
          </>
        );
      },
    },
    {
      title: (
        <span className="text-[14px]">
          Invoice Number<span className="ml-[2px] text-red-500">*</span>
        </span>
      ),
      dataIndex: "invoice_number",
      render: (text, record, index) => (
        <>
          <Input
            allowClear
            className="borderless"
            value={text}
            onChange={(e) =>
              handleChange(record.key, "invoice_number", e.target.value)
            }
            placeholder="Invoice Number"
          />
          {fieldErrors[index]?.invoice_number && (
            <div className="text-red-500 mt-1 ml-[11px]">
              {fieldErrors[index]["invoice_number"]}
            </div>
          )}{" "}
        </>
      ),
    },

    {
      title: "Remark",
      dataIndex: "description",
      //   width: 350,
      render: (text, record) => (
        <Input
          allowClear
          className="borderless"
          value={text}
          onChange={(e) =>
            handleChange(record.key, "description", e.target.value)
          }
          placeholder="Remark"
        />
      ),
    },

    {
      title: "Action",
      dataIndex: "action",
      fixed: "right",
      width: 100,
      align: "middle",
      render: (_, record) => (
        <div
          onClick={() => handleDelete(record.key)}
          className={`flex justify-center items-center gap-2 cursor-pointer ${
            data.length === 1 ? "opacity-50 pointer-events-none" : ""
          }`}
        >
          <DeleteOutlined className="text-red-500" />
        </div>
      ),
    },
  ];

  const handleAdd = () => {
    const newData = [...data];
    const startKey = Math.max(...data.map((item) => item.key), 0) + 1;
    for (let i = 0; i < 5; i++) {
      const newKey = startKey + i;
      newData.push({
        key: newKey,
        receipt_attechments: [],
        expense_category_type_master_id: null,
        payment_method_master_id: null,
        amount: "",
        amount_currency_id: defaultCurrency?.exchange_currency?.id,
        description: "",
        supplier_name: null,
        invoice_number: "",
        amount_currency_rate: defaultCurrency?.value_in_currency_locale,
      });
    }
    setData(newData);
  };

  const handleChange = (key, dataIndex, value) => {
    const newData = [...data];
    const index = newData.findIndex((item) => item.key === key);

    if (index !== -1) {
      if (newData[index][dataIndex] !== value) {
        newData[index][dataIndex] = value;

        if (dataIndex === "amount_currency_id") {
          const selectedCurrency =
            allCurrencyMasterData &&
            allCurrencyMasterData.length > 0 &&
            allCurrencyMasterData?.find(
              (curr) => curr.exchange_currency?.id === value
            );

          if (selectedCurrency) {
            newData[index]["amount_currency_rate"] =
              selectedCurrency.value_in_currency_locale;
          }
        }

        setData(newData);
      }
    }
  };

  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);
    if (!currentRecord) return;

    try {
      const formData = new FormData();
      setUploadingFiles({ count: files.length });

      files.forEach((file) => {
        formData.append("files", file);
      });

      const response = await axios.post(
        `${BASE_URL_UPLOAD}/upload-multiple`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("crmWebToken"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const newFiles = response.data.data;

      setTempFileLists((prev) => ({
        ...prev,
        [currentRecord.key]: [...(prev[currentRecord.key] || []), ...newFiles],
      }));
    } catch (error) {
      console.error("Upload failed:", error);
      toast.error("Upload failed", error?.response?.data?.message);
    } finally {
      setUploadingFiles({});
    }
  };

  const handleOk = () => {
    if (currentRecord) {
      const existingFiles = fileLists[currentRecord.key] || [];
      const tempFiles = tempFileLists[currentRecord.key] || [];

      const newFiles = tempFiles.filter(
        (file) =>
          !existingFiles.some(
            (existingFile) => existingFile.upload_id === file.upload_id
          )
      );

      if (newFiles.length > 0) {
        onFileUpload(currentRecord.key, newFiles);
        toast.success(`${newFiles.length} file(s) saved successfully`);
      }

      setFileLists((prev) => ({
        ...prev,
        [currentRecord.key]: tempFiles,
      }));
    }

    // Close the modal
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showModal = (record) => {
    setCurrentRecord(record);
    setIsModalVisible(true);
    setTempFileLists((prev) => ({
      ...prev,
      [record.key]: fileLists[record.key] || [],
    }));
  };

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              cellPaddingBlock: 5,
            },
          },
        }}
      >
        <Table
          className="sales-orders-table"
          columns={columns}
          dataSource={data}
          pagination={false}
          bordered
          scroll={{ x: "max-content", y: 600 }}
        />
      </ConfigProvider>

      <div
        onClick={handleAdd}
        className={`inline-flex  mt-5 cursor-pointer items-center gap-x-2 ${
          addExpenseVoucherLoader || updateExpenseVoucherLoader
            ? "opacity-50 pointer-events-none"
            : ""
        }`}
      >
        <Image
          preview={false}
          src={addButtonWhite}
          height={20}
          width={20}
          alt="add-button-white"
        />
        <span className="text-brand text-base">Add More Expense</span>
      </div>

      <div className="flex items-center gap-x-5 py-3 justify-end">
        <Button
          onClick={() => navigate(-1)}
          type="button"
          className="gray-button text-white hover:text-white min-h-[40px] min-w-[120px]"
        >
          Cancel
        </Button>
        <Form.Item className="mb-0">
          <Button
            loading={addExpenseVoucherLoader || updateExpenseVoucherLoader}
            type="primary"
            className="root-btn min-h-[40px] min-w-[120px]"
            htmlType="submit"
          >
            Save
          </Button>
        </Form.Item>
      </div>

      <Modal
        okText="Upload"
        title="Upload Files"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {currentRecord && (
          <div>
            <div className="max-w-xl my-2">
              <label
                htmlFor="file-upload"
                className="flex justify-center w-full h-32 px-4 transition bg-white border-[0.5px] border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none"
              >
                {uploadingFiles.count ? (
                  <div className="flex my-auto  space-y-2 flex-col items-center">
                    <LoadingOutlined />
                    <span className="font-medium text-gray-600">
                      Uploading {uploadingFiles.count} file(s)...
                    </span>
                  </div>
                ) : (
                  <span className="flex items-center space-x-2">
                    <FaCloudUploadAlt className="w-6 h-6 text-gray-600" />
                    <span className="font-medium text-gray-600 ml-2">
                      Drop files to Attach, or
                      <span className="text-blue-600 underline ml-1">
                        browse
                      </span>
                    </span>
                  </span>
                )}

                <input
                  accept=".jpg,.jpeg,.png,.gif"
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  className="hidden"
                  multiple
                  onChange={handleFileChange}
                />
              </label>
            </div>

            {tempFileLists &&
              tempFileLists[currentRecord.key] &&
              tempFileLists[currentRecord.key]?.length > 0 && (
                <ul className="mt-4 space-y-4 max-h-[300px] gray-scrollbar overflow-y-auto">
                  {tempFileLists[currentRecord.key].map((file, index) => (
                    <li key={index} className="text-sm">
                      <div className="flex items-center py-2  bg-slate-100 rounded-md px-3">
                        {renderFileIcon(file.url)}
                        <span
                          title={file.originalname}
                          className="text-gray-500 ml-2 max-w-[calc(100%-8rem)] truncate flex-grow"
                        >
                          {file.originalname}
                        </span>

                        <DeleteOutlined
                          onClick={() => {
                            setTempFileLists((prev) => ({
                              ...prev,
                              [currentRecord.key]: prev[
                                currentRecord.key
                              ].filter((f) => f.upload_id !== file.upload_id),
                            }));
                          }}
                          className="text-red-500 cursor-pointer text-base ml-auto"
                        />
                      </div>
                      {uploadingFiles[file.originalname] && (
                        <div className="flex items-center space-x-2 mt-1 text-xs text-gray-500">
                          <FaSpinner className="animate-spin h-4 w-4" />
                          <span>Uploading...</span>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              )}
          </div>
        )}
      </Modal>
    </>
  );
};

import { Dropdown, Select, Spin, Table, Image, Pagination } from "antd";
import React, { useEffect, useRef, useState } from "react";
import CustomInputWithButtons from "../../common/CustomInputWithButtons ";
import PageTitle from "../../common/PageTitle";
import requestForQuotation from "../../../assets/img/request-for-quotation.svg";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import SortIcon from "../../common/SortIcon";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import DeleteModal from "../../common/DeleteModal";
import CommonModal from "../../common/CommonModal";
import {
  actionClearAllRequestForQuotation,
  actionDeleteRequestForQuotation,
  actionGetAllRequestForQuotation,
  actionGetRequestForQuotation,
  actionGetSingleRequestForQuotation,
  actionUpdateRequestForQuotationStatus,
} from "../../../store/services/requestForQuotationService";
import { useDispatch, useSelector } from "react-redux";

const RequstForQuotation = () => {
  const [quotationData, setQuotationData] = useState(null);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [exportAll, setExportAll] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [requstForQuotationId, setRequestForQuotationId] = useState(null);

  const navigate = useNavigate(true);
  const [search, setSearch] = useState("");
  const {
    getRequestForQuotationLoader,
    requestForQuotationDataCount,
    getSingleRequestForQuotationLoader,
    getSingleRequestForQuotationData,
    allRequestForQuotation,
    requestForQuotationData,
    deleteRequestForQuotationLoader,
  } = useSelector((state) => state.requestForQuotation);
  const offsetRef = useRef(0);

  const dispatch = useDispatch();
  const getApiCall = (
    offset = 0,
    search = "",
    orderByColumn = "",
    order = ""
  ) => {
    dispatch(
      actionGetRequestForQuotation({
        offset,
        orderByColumn,
        order,
        search,
      })
    );
  };

  useEffect(() => {
    dispatch(
      actionGetRequestForQuotation({ offset: offsetRef.current, search })
    );
  }, [dispatch]);

  useEffect(() => {
    if (requstForQuotationId && viewModalOpen) {
      dispatch(
        actionGetSingleRequestForQuotation({
          id: requstForQuotationId,
        })
      );
    }
  }, [dispatch, requstForQuotationId]);

  //modal
  const handleCellClick = (record) => {
    setRequestForQuotationId(record.request_for_quotation_id);
    setViewModalOpen(true);
  };

  //close modal
  const handleCancel = () => {
    setViewModalOpen(false);
    setQuotationData(null);
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "DRAFT":
        return "draft-select";
      case "PENDING":
        return "pending-select";
      case "UNDER_REVIEW":
        return "under-select";
      case "APPROVED":
        return "approved-select";
      case "REJECTED":
        return "rejected-select";
      case "IN_PROGRESS":
        return "in-progress-select";
      case "COMPLETED":
        return "completed-select";
      case "CANCELLED":
        return "cancelled-select";
      default:
        return "default-select";
    }
  };
  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      width: 200,
      fixed: "right",
      sorter: true,
      sortIcon: () => <SortIcon />,
      onCell: (record) => ({
        onClick: () => handleCellClick(record),
        style: { cursor: "pointer", color: "#6883FD" },
      }),
      render: (text, record) => (
        <span className="ml-2">
          {record.category && record.category?.category_name
            ? record.category?.category_name
            : "-"}
        </span>
      ),
    },

    {
      title: "Grade",
      dataIndex: "grade",
      key: "grade",
      width: 150,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <span className="ml-2">{data.grade ? data.grade : "-"}</span>
      ),
    },
    {
      title: "Thickness",
      dataIndex: "thickness",
      key: "thickness",
      width: 200,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <span className="ml-2">{data.thickness ? data.thickness : "-"}</span>
      ),
    },
    {
      title: "Size if custom-made",
      dataIndex: "custom_made_size",
      key: "custom_made_size",
      width: 250,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <span className="ml-2">
          {data.custom_made_size ? data.custom_made_size : "-"}
        </span>
      ),
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      width: 150,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <span className="ml-2">{data.sku ? data.sku : "-"}</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 250,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text, record) => {
        const statusOptions = [
          {
            value: "DRAFT",
            label: "Draft",
          },
          {
            value: "PENDING",
            label: "Pending",
          },
          {
            value: "UNDER_REVIEW",
            label: "Under Review",
          },
          {
            value: "APPROVED",
            label: "Approved",
          },
          {
            value: "REJECTED",
            label: "Rejected",
          },
          {
            value: "IN_PROGRESS",
            label: "In Progress",
          },
          {
            value: "COMPLETED",
            label: "Completed",
          },
          {
            value: "CANCELLED",
            label: "Cancelled",
          },
        ];

        const statusClass = getStatusClass(text);

        const handleStatusChange = (newStatus) => {
          const req = {
            status: newStatus,
          };
          dispatch(
            actionUpdateRequestForQuotationStatus({
              id: record.request_for_quotation_id,
              req,
            })
          );
          console.log("Selected Status:", newStatus, "for record:", record);
          // Perform the status update logic here
        };

        return (
          <Select
            className={`${statusClass}`}
            value={text}
            onChange={handleStatusChange}
            style={{ maxWidth: 150, width: 150 }}
            placeholder="Select Status"
            options={statusOptions}
          />
        );
      },
    },

    {
      title: "Custom Thickness",
      dataIndex: "custom_thickness",
      key: "custom_thickness",
      width: 200,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <span className="ml-2">
          {data.custom_thickness ? data.custom_thickness : "-"}
        </span>
      ),
    },
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
      width: 200,
      render: (text, data) => (
        <span className="ml-2">{data.remark ? data.remark : "-"}</span>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 100,
      fixed: "right",
      render: (text, data) => (
        <>
          <Dropdown.Button
            id="custom-drop"
            menu={{
              items: [
                {
                  label: <span>Edit</span>,
                  key: "Edit",
                  icon: <EditOutlined />,
                  onClick: () => {
                    navigate("/update-request-for-quotation", {
                      state: {
                        requestForQuotationId: data.request_for_quotation_id,
                      },
                    });
                  },
                },
                {
                  label: "Delete",
                  icon: <DeleteOutlined style={{ fontSize: 12 }} />,
                  danger: true,
                  // onClick: () => setIsDeleteModalOpen(true),
                  onClick: () => {
                    setIsDeleteModalOpen((prev) => !prev);
                    setRequestForQuotationId(data?.request_for_quotation_id);
                  },
                  key: "Delete",
                },
              ].filter(Boolean),
            }}
          >
            Action
          </Dropdown.Button>
        </>
      ),
    },
  ];

  const handleSortChange = (pagination, filters, sorter) => {
    console.log(sorter);
    const sorterParams = {
      field: sorter.columnKey,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    getApiCall(
      offsetRef.current,
      search,
      sorterParams.field,
      sorterParams.order
    );
  };

  const handleRefresh = () => {
    setSearch(null);
    offsetRef.current = 0;
    getApiCall(offsetRef.current);
  };

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      getApiCall(offsetRef.current, e.target.value);
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      getApiCall(offsetRef.current, e.target.value);
    }
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setRequestForQuotationId(null);
  };

  const onClickDownloadMenu = () => {
    dispatch(actionGetAllRequestForQuotation());
    setExportAll(true);
  };

  const exportAllToExcel = async () => {
    const data =
      (await allRequestForQuotation) &&
      allRequestForQuotation.length > 0 &&
      allRequestForQuotation.map((data) => ({
        Category: data.category?.category_name
          ? data.category?.category_name
          : "-",
        Grade: data?.grade ? data?.grade : "-",
        Thickness: data.thickness ? data.thickness : "-",
        "Business Partner": data.business_partner
          ? data.business_partner?.business_partner_name
          : "-",
        "Size if custom-made": data.custom_made_size
          ? data.custom_made_size
          : "-",
        sku: data.sku ? data.sku : "-",
        Remark: data.remark ? data.remark : "-",
      }));
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Request For Quotation");
    XLSX.writeFile(workbook, "Request For Quotation.xlsx");
    dispatch(actionClearAllRequestForQuotation({ setExportAll }));
  };

  useEffect(() => {
    if (
      exportAll &&
      allRequestForQuotation &&
      allRequestForQuotation.length > 0
    ) {
      exportAllToExcel();
    }
  }, [allRequestForQuotation]);

  return (
    <>
      <div id="sales-orders">
        <PageTitle
          image={requestForQuotation}
          title="Request For Quotation"
          buttonText="Create Request For Quotation"
          buttonLink="/create-request-for-quotation"
        />
        <CustomInputWithButtons
          handleRefresh={handleRefresh}
          handleSearch={handleSearch}
          handleBlurAndPressEnter={handleBlurAndPressEnter}
          searchValue={search}
          onClickDownloadMenu={onClickDownloadMenu}
        />

        <div>
          <Table
            rowKey="request_for_quotation_id"
            loading={getRequestForQuotationLoader}
            onChange={handleSortChange}
            columns={columns}
            pagination={false}
            bordered
            dataSource={requestForQuotationData}
            // virtual
            scroll={{ x: "max-content" }}
          />
        </div>

        <div className="flex justify-between mt-3">
          <p className="text-[15px] font-popinsMedium">
            Total :{" "}
            {requestForQuotationDataCount ? requestForQuotationDataCount : 0}
          </p>
          <Pagination
            hideOnSinglePage
            responsive={true}
            current={offsetRef.current + 1}
            className="flex items-center"
            showSizeChanger={false}
            total={requestForQuotationDataCount}
            onChange={(e) => {
              offsetRef.current = e - 1;
              getApiCall(offsetRef.current, search);
            }}
          />
        </div>

        <CommonModal
          className=""
          centered
          open={viewModalOpen}
          title=""
          onCancel={handleCancel}
          handleReset={handleCancel}
          onOk={handleCancel}
        >
          <Spin spinning={getSingleRequestForQuotationLoader}>
            {getSingleRequestForQuotationData && (
              <div>
                <div className="text-brand flex items-center gap-x-3">
                  <Image
                    src={requestForQuotation}
                    alt="image"
                    height={20}
                    width={20}
                    preview={false}
                  />
                  <h1 className="lg:text-lg text-base font-popinsRegular">
                    View Request For Quotation
                  </h1>
                </div>
                <div className="flex flex-col mt-3 gap-y-4">
                  <div className="flex gap-x-6 items-center gap-y-1">
                    <p className="text-xs text-[#818181] w-[30%]">Category:</p>
                    <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                      {getSingleRequestForQuotationData.category &&
                      getSingleRequestForQuotationData.category?.category_name
                        ? getSingleRequestForQuotationData.category
                            ?.category_name
                        : "-"}
                    </p>
                  </div>
                  <div className="flex gap-x-6 items-center gap-y-1">
                    <p className="text-xs text-[#818181] w-[30%]">Grade:</p>
                    <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                      {getSingleRequestForQuotationData.grade ?? "-"}
                    </p>
                  </div>
                  <div className="flex gap-x-6 items-center gap-y-1">
                    <p className="text-xs text-[#818181] w-[30%]">Thickness:</p>
                    <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                      {getSingleRequestForQuotationData.thickness ?? "-"}
                    </p>
                  </div>
                  <div className="flex gap-x-6 items-center gap-y-1">
                    <p className="text-xs text-[#818181] w-[30%]">
                      Size if custom-made:
                    </p>
                    <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                      {getSingleRequestForQuotationData.custom_made_size ?? "-"}
                    </p>
                  </div>
                  <div className="flex gap-x-6 items-center gap-y-1">
                    <p className="text-xs text-[#818181] w-[30%]">SKU:</p>
                    <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                      {getSingleRequestForQuotationData.sku ?? "-"}
                    </p>
                  </div>
                  <div className="flex gap-x-6 items-center gap-y-1">
                    <p className="text-xs text-[#818181] w-[30%]">
                      Custom Thickness:
                    </p>
                    <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                      {getSingleRequestForQuotationData.custom_thickness ?? "-"}
                    </p>
                  </div>
                  <div className="flex gap-x-6 items-center gap-y-1">
                    <p className="text-xs text-[#818181] w-[30%]">Status:</p>
                    <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                      {getSingleRequestForQuotationData.status ?? "-"}
                    </p>
                  </div>
                  <div className="flex gap-x-6 items-center gap-y-1">
                    <p className="text-xs text-[#818181] w-[30%]">Remark:</p>
                    <p className="text-sm text-start w-[70%] text-black font-popinsMedium">
                      {getSingleRequestForQuotationData.remark ?? "-"}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </Spin>
        </CommonModal>
      </div>

      <DeleteModal
        title="Are you sure you want to remove this ?"
        description="All the associations will be removed for this Quotation"
        loading={deleteRequestForQuotationLoader}
        onCancel={() => {
          setIsDeleteModalOpen(false);
          setRequestForQuotationId(null);
        }}
        onConfirm={() =>
          dispatch(
            actionDeleteRequestForQuotation({
              requstForQuotationId,
              handleCloseDeleteModal,
            })
          )
        }
        isVisible={isDeleteModalOpen}
      />
    </>
  );
};

export default RequstForQuotation;

import CustomInputWithButtons from "../common/CustomInputWithButtons ";
import PageTitle from "../common/PageTitle";
import salesOrder from "../../assets/img/salesOrder.svg";
import {
  Table,
  Dropdown,
  Popconfirm,
  Pagination,
  Tag,
  Modal,
  Form,
  Row,
  Select,
  Button,
} from "antd";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { MdDoNotDisturbAlt, MdOutlineChangeCircle } from "react-icons/md";
import DeleteModal from "../common/DeleteModal";
import * as XLSX from "xlsx";
import React, { useEffect, useRef, useState } from "react";
import SortIcon from "../common/SortIcon";
import { useDispatch, useSelector } from "react-redux";
import {
  actionChangeSalesOrderStatus,
  actionClearAllSalesOrders,
  actionDeleteSalesOrder,
  actionGetAllSalesOrders,
  actionGetSalesOrderDetails,
  actionGetSalesOrders,
} from "../../store/services/salesService";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { getFirstLetterCapital } from "../helper/common-function";

const getStatusClass = (status) => {
  switch (status) {
    case "PENDING":
      return "border-[#C69619] text-[#C69619]";
    case "DONE":
      return "border-[#15A02B] text-[#15A02B]";
    case "FAILED":
      return "border-[#DD1B1B] text-[#DD1B1B]";
    default:
      return "border-[#7C7C7C] text-[#7C7C7C]";
  }
};

const SalesOrders = () => {
  const offsetRef = useRef(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    getSalesOrdersLoader,
    getAllSalesOrdersLoader,
    deleteSalesOrderLoader,
    allSalesOrdersCount,
    allSalesOrders,
    salesOrdersData,
    changeSalesOrderStatusLoader,
    getSalesOrderDetailLoader,
    salesOrderDetails,
  } = useSelector((state) => state.sales);

  const [statusForm] = Form.useForm();
  const [search, setSearch] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [salesOrderId, setSalesOrderId] = useState("");
  const [exportAll, setExportAll] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

  const getApiCall = (
    offset = 0,
    search = "",
    orderByColumn = "",
    order = ""
  ) => {
    dispatch(
      actionGetSalesOrders({
        offset,
        orderByColumn,
        order,
        search,
      })
    );
  };

  useEffect(() => {
    getApiCall(offsetRef.current, search);
  }, [dispatch]);

  const handleRefresh = () => {
    setSearch(null);
    offsetRef.current = 0;
    getApiCall(offsetRef.current);
  };

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      getApiCall(offsetRef.current, e.target.value);
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      getApiCall(offsetRef.current, e.target.value);
    }
  };

  const onClickDownloadMenu = () => {
    dispatch(actionGetAllSalesOrders());
    setExportAll(true);
  };

  useEffect(() => {
    if (salesOrderDetails && salesOrderId) {
      statusForm.setFieldsValue({
        status: salesOrderDetails.status,
      });
    }
  }, [salesOrderId, salesOrderDetails]);

  const columns = [
    {
      title: "SO Number",
      dataIndex: "sales_order_number",
      key: "sales_order_number",
      width: 200,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text, data) => (
        <span
          onClick={() =>
            navigate("/update-sales-order", {
              state: data.sales_order_id,
            })
          }
          className="text-brand cursor-pointer"
        >
          {text}
        </span>
      ),
    },
    {
      title: "Business Partner / Customer",
      dataIndex: ["customer", "business_partner_name"],
      key: "customer",
      ellipsis: true,
      width: 200,
      sorter: true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      width: 150,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => dayjs(text).format("DD/MM/YYYY"),
    },
    {
      title: "Ship to Party",
      dataIndex: ["ship_to_party", "business_partner_name"],
      key: "ship_to_party",
      ellipsis: true,
      width: 250,
      sorter: true,
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      sorter: true,
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span
          className={`${getStatusClass(
            text
          )} border-[0.5px] py-1 rounded-[5px] px-2.5 text-sm`}
        >
          {text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()}
        </span>
      ),
    },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: 100,
      fixed: "right",
      render: (text, data) => (
        <>
          <Dropdown.Button
            id="custom-drop"
            menu={{
              items: [
                {
                  label: <span>Edit</span>,
                  key: "Edit",
                  icon: <EditOutlined />,
                  onClick: () => {
                    navigate("/update-sales-order", {
                      state: data.sales_order_id,
                    });
                  },
                },
                {
                  label: <span>Change Status</span>,
                  key: "change-status",
                  icon: (
                    <MdOutlineChangeCircle color="rgba(0,0,0,0.8)" size={15} />
                  ),
                  onClick: async () => {
                    setIsStatusModalOpen(true);
                    setSalesOrderId(data.sales_order_id);
                    await dispatch(
                      actionGetSalesOrderDetails(data.sales_order_id)
                    );
                  },
                },
                {
                  label: "Delete",
                  icon: <DeleteOutlined />,
                  danger: true,
                  key: "Delete",

                  onClick: () => {
                    setIsDeleteModalOpen((prev) => !prev);
                    setSalesOrderId(data?.sales_order_id);
                  },
                },
              ].filter(Boolean),
            }}
          >
            Action
          </Dropdown.Button>
        </>
      ),
    },
  ];

  const data = [];
  for (let i = 0; i < 5; i++) {
    data.push({
      key: i,
      business_partner: `Test SuperStockist ${i}`,
      qty: i,
      ship_to_party: `${1}Test Super`,
      unit: `${1}KG`,
      material: `Material ${i}`,
    });
  }

  const exportAllToExcel = async () => {
    const data =
      (await allSalesOrders) &&
      allSalesOrders.length > 0 &&
      allSalesOrders.map((data) => ({
        "SO Number": data.sales_order_number ? data.sales_order_number : "-",
        "Business Partner / Customer": data.customer
          ? data.customer.business_partner_name
          : "-",
        Date: data.created_at
          ? dayjs(data.created_at).format("DD/MM/YYYY")
          : "-",
        "Ship to Party": data.ship_to_party
          ? data.ship_to_party?.business_partner_name
          : "-",
        Status: data.status ? getFirstLetterCapital(data.status) : "-",
        Note: data.note ? data.note : "-",
      }));
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sales Orders");
    // Generate the Excel file and initiate the download
    XLSX.writeFile(workbook, "Sales Orders.xlsx");
    dispatch(actionClearAllSalesOrders({ setExportAll }));
  };

  useEffect(() => {
    if (exportAll && allSalesOrders && allSalesOrders.length > 0) {
      exportAllToExcel();
    }
  }, [allSalesOrders]);

  const handleSortChange = (pagination, filters, sorter) => {
    console.log(sorter);
    const sorterParams = {
      field: sorter.columnKey,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    getApiCall(
      offsetRef.current,
      search,
      sorterParams.field,
      sorterParams.order
    );
  };

  const handleCloseStatusModal = () => {
    setIsStatusModalOpen(false);
    setSalesOrderId("");
  };

  return (
    <section className="main-wrapper">
      <PageTitle
        image={salesOrder}
        title="Sales Orders"
        buttonText="Create New SO"
        buttonLink="/add-sales-order"
      />

      <CustomInputWithButtons
        handleRefresh={handleRefresh}
        handleSearch={handleSearch}
        handleBlurAndPressEnter={handleBlurAndPressEnter}
        searchValue={search}
        onClickDownloadMenu={onClickDownloadMenu}
      />

      <Table
        sticky
        rowKey="sales_order_id"
        scrollToFirstRowOnChange={true}
        sortDirections={["ascend", "descend"]}
        onChange={handleSortChange}
        bordered
        scroll={{
          x: "max-content",
          y: window.innerWidth < 1500 ? "49vh" : "61vh",
        }}
        pagination={false}
        columns={columns}
        dataSource={salesOrdersData}
        loading={getAllSalesOrdersLoader || getSalesOrdersLoader}
      />
      <div className="flex justify-end mt-3">
        <Pagination
          hideOnSinglePage
          responsive={true}
          current={offsetRef.current + 1}
          className="flex items-center"
          showSizeChanger={false}
          total={allSalesOrdersCount}
          onChange={(e) => {
            offsetRef.current = e - 1;
            getApiCall(offsetRef.current, search);
          }}
        />
      </div>

      <DeleteModal
        title="Are you sure you want to delete this sales order?"
        description="All the associations will be removed for this Collection"
        loading={deleteSalesOrderLoader}
        onCancel={() => {
          setIsDeleteModalOpen(false);
          setSalesOrderId("");
        }}
        onConfirm={() =>
          dispatch(
            actionDeleteSalesOrder({
              id: salesOrderId,
              setIsDeleteModalOpen,
            })
          )
        }
        isVisible={isDeleteModalOpen}
      />

      <Modal
        width={500}
        centered={true}
        open={isStatusModalOpen}
        footer={false}
        title=""
        loading={getSalesOrderDetailLoader}
        destroyOnClose={handleCloseStatusModal}
        onCancel={handleCloseStatusModal}
        onOk={handleCloseStatusModal}
      >
        <div className="text-brand flex items-center gap-x-3">
          <MdOutlineChangeCircle size={20} color="#6883FD" />

          <h1 className="lg:text-lg text-base  font-popinsRegular">
            Change Status
          </h1>
        </div>

        <Form
          form={statusForm}
          onFinish={(values) => {
            dispatch(
              actionChangeSalesOrderStatus({
                values,
                id: salesOrderId,
                handleClose: handleCloseStatusModal,
              })
            );
          }}
          layout="vertical"
          className="mt-[20px] mb-0"
        >
          <Form.Item
            name="status"
            label="Status"
            className="mb-[12px]"
            size="small"
            rules={[
              {
                required: true,
                message: "Please select status!",
              },
            ]}
          >
            <Select
              options={[
                {
                  label: "Pending",
                  value: "PENDING",
                  disabled: salesOrderDetails.status === "PENDING",
                },
                {
                  label: "Done",
                  value: "DONE",
                  disabled: salesOrderDetails.status === "DONE",
                },
                {
                  label: "Failed",
                  value: "FAILED",
                  disabled: salesOrderDetails.status === "FAILED",
                },
              ]}
              allowClear
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              style={{ background: "white" }}
              placeholder="Select Attendee Status"
              className="begin-input bg-white focus:bg-white active:bg-white rounded-[10px]"
            />
          </Form.Item>

          <Form.Item className="mt-10 mb-0">
            <Button
              loading={changeSalesOrderStatusLoader}
              type="primary"
              className="root-btn min-h-[40px] min-w-[100px]"
              htmlType="submit"
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </section>
  );
};

export default SalesOrders;

import {
  FilePdfOutlined,
  FileTextOutlined,
  FileWordOutlined,
} from "@ant-design/icons";
import { Image } from "antd";
import * as CryptoJS from "crypto-js";
import dayjs from "dayjs";
import { PhoneNumberUtil } from "google-libphonenumber";
import { toast } from "sonner";
import { CIPHER_KEY } from "../../config/web-config";

const phoneUtil = PhoneNumberUtil.getInstance();

export const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

export const convertToInt = (val) => {
  return val || val != null ? parseInt(val) : null;
};

export const currentYear = dayjs().year();

// Function to retrieve userData from localStorage
export const getStoredUserData = () => {
  const storedUserData = localStorage.getItem("userData");
  if (storedUserData) {
    try {
      return JSON.parse(storedUserData);
    } catch (error) {
      console.error("Error parsing stored userData:", error);
    }
  }
  return null;
};

// Function to retrieve event from localStorage
export const getEventId = () => {
  const storedUserData = localStorage.getItem("event_id");
  if (storedUserData) {
    try {
      return JSON.parse(storedUserData);
    } catch (error) {
      console.error("Error parsing stored event id:", error);
    }
  }
  return null;
};

export const startTimer = (initialTime, setTimer) => {
  let timer = 59;
  setTimer && setTimer(timer);

  const interval = setInterval(() => {
    timer -= 1;
    setTimer && setTimer(timer);

    if (timer <= 0) {
      clearInterval(interval);
    }
  }, 1000);
  return interval;
};

export const getFormattedTimezone = (offset, name, timezone) => {
  return `(${offset}) ${name}  ( ${timezone} )`;
};

export const getAllKeys = (nodes) => {
  const keys = [];
  nodes.forEach((node) => {
    keys.push(node.key);
    if (node.children) {
      keys.push(...getAllKeys(node.children));
    }
  });
  return keys;
};

export const getFormattedDateTime = (date) => {
  return dayjs(date).format("DD MMM, YYYY HH:mm:ss A");
};

export const getFirstLetterCapital = (str) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export function decrypt(encryptedData) {
  const base64 = decodeURIComponent(encryptedData);
  const encrypted = CryptoJS.enc.Base64.parse(base64).toString(
    CryptoJS.enc.Utf8
  );
  // const decrypted = CryptoJS.AES.decrypt(encrypted, "lu6^S-l(U!e=6$ho)1^x7gFcoD2C%^6(H)Ke8nwn0.uzGcNhDev~Z");
  const decrypted = CryptoJS.AES.decrypt(encrypted, CIPHER_KEY);
  return decrypted.toString(CryptoJS.enc.Utf8);
}

export function formatString(input) {
  let formatted = input?.replace(/[^a-zA-Z0-9\s/]/g, " ");
  formatted = formatted?.toLowerCase();
  formatted = formatted?.replace(/(?:^|[\s/])\w/g, (char) =>
    char.toUpperCase()
  );
  return formatted?.trim();
}

export const extractFilename = (url) => {
  const startStr = "uploads/";
  const startIndex = url.indexOf(startStr);

  if (startIndex !== -1) {
    const endIndex = url.indexOf("?", startIndex);
    if (endIndex !== -1) {
      return url.substring(startIndex + startStr.length, endIndex);
    } else {
      return url.substring(startIndex + startStr.length);
    }
  }
  return "";
};

export const getFullName = (firstName, lastName) => {
  const isValidName = (name) =>
    name !== null && name !== undefined && name.trim() !== "";

  // Capitalize first letter of both names
  const formattedFirstName = isValidName(firstName)
    ? getFirstLetterCapital(firstName)
    : "";
  const formattedLastName = isValidName(lastName)
    ? getFirstLetterCapital(lastName)
    : "";

  if (formattedFirstName && formattedLastName) {
    return `${formattedFirstName} ${formattedLastName}`;
  }
  return formattedFirstName || formattedLastName || "-";
};

export const ALLOWED_SEARCH_REGEX = /^[a-zA-Z0-9 @.-]*$/;
export const isValidSearchString = (value) => ALLOWED_SEARCH_REGEX.test(value);

export const handleErrorMessage = (error) => {
  const message = error.response?.data?.message;

  if (Array.isArray(message)) {
    toast.error(message[0]);
  } else if (message && message !== null) {
    toast.error(message);
  } else {
    toast.error(error.message);
  }

  throw new Error(error.message);
};

export const formatUrl = (url) => {
  if (!url) return "";
  if (url && !url.startsWith("http://") && !url.startsWith("https://")) {
    return `https://${url}`;
  }
  return url;
};

export const renderFileIcon = (file, size = 24, preview = true) => {
  const extractFileName = extractFilename(file);
  if (
    extractFileName.endsWith("png") ||
    extractFileName.endsWith("jpg") ||
    extractFileName.endsWith("jpeg") || 
    extractFileName.endsWith("gif")  
  ) {
    return (
      <Image
        src={file}
        preview={preview}
        alt="docs"
        height={size}
        width={size}
      />
    );
  } else if (extractFileName.endsWith("pdf")) {
    return <FilePdfOutlined style={{ fontSize: size, color: "#ff6347" }} />;
  } else if (
    extractFileName.endsWith("doc") ||
    extractFileName.endsWith("docx")
  ) {
    return <FileWordOutlined style={{ fontSize: size, color: "#1e90ff" }} />;
  } else {
    return <FileTextOutlined style={{ fontSize: size, color: "#1890ff" }} />;
  }
};

export const extractFileType = (url) => {
  // Extract file extension from URL
  const extension = url.split(".").pop().split("?")[0];
  return extension;
};

export const isNullOrEmpty = (value) => {
  if (value === null || value === undefined) {
    return "- ";
  }

  if (typeof value === "string" && value.trim() === "") {
    return "- ";
  }

  if (Array.isArray(value) && value.length === 0) {
    return "- ";
  }

  if (typeof value === "object" && Object.keys(value).length === 0) {
    return "- ";
  }

  return value;
};

import { createSlice } from "@reduxjs/toolkit";
import {
  //product category
  actionGetProductCategoryMaster,
  actionAddProductCategoryMaster,
  actionDeleteProductCategoryMaster,
  actionGetSingleProductCategoryMaster,
  actionEditProductCategoryMaster,
  //product sub category
  actionGetProductSubCategoryMaster,
  actionAddProductSubCategoryMaster,
  actionDeleteProductSubCategoryMaster,
  actionEditProductSubCategoryMaster,
  actionGetSingleProductSubCategoryMaster,
  //product type
  actionGetProductTypeMaster,
  actionAddProductTypeMaster,
  actionDeleteProductTypeMaster,
  actionEditProductTypeMaster,
  actionGetSingleProductTypeMaster,
  //hsnSac code
  actionGetHsnSacMaster,
  actionAddHsnSacCodeMaster,
  actionDeleteHsnSacCodeMaster,
  actionEditHsnSacMaster,
  actionGetSingleHsnSacCodeMaster,
  //unit master
  actionGetUnitMaster,
  actionAddUnitMaster,
  actionDeleteUnitMaster,
  actionGetSingleUnitMaster,
  actionEditUnitMaster,
  actionAddProductMaster,
  actionGetProductsMaster,
  actionGetProductMaster,
  actionUpdateProductMaster,
  actionDeleteProductMaster,
} from "../services/productMasterService";
const productMasterSlice = createSlice({
  name: "productmaster",
  initialState: {
    //product category
    getProductCategoryLoader: false,
    addProductCategoryLoader: false,
    deleteProductCategoryLoader: false,
    getSingleProductCategoryLoader: false,
    updateProductCategoryLoader: {},
    getSingleProductCategoryData: false,
    productCategoryData: [],
    //product sub category
    getProductSubCategoryLoader: false,
    addProductSubCategoryLoader: false,
    deleteProductSubCategoryLoader: false,
    updateProductSubCategoryLoader: {},
    getSingleProductSubCategoryLoader: false,
    getSingleProductSubCategoryData: false,
    productSubCategoryData: [],
    //product type
    getProductTypeLoader: false,
    addProductTypeLoader: false,
    deleteProductTypeLoader: false,
    updateProductTypeLoader: {},
    getSingleProductTypeLoader: false,
    getSingleProductTypeData: false,
    productTypeData: [],
    //HsnSac code
    getHsnSacLoader: false,
    addHsnSacCodeLoader: false,
    deleteHsnSacCodeLoader: false,
    updateHsnSacCodeLoader: {},
    getSingleHsnSacLoader: false,
    getSingleHsnSacCodeData: false,
    hsnSacCodeData: [],
    //unit master
    getUnitMasterLoader: false,
    addUnitLoader: false,
    deleteUnitLoader: false,
    getSingleUnitLoader: false,
    getSingleUnitMasterData: false,
    updateUnitLoader: {},
    unitMasterData: [],

    addProductLoader: false,
    getProductsLoader: false,
    getSingleProductLoader: false,
    updateProductLoader: false,
    deleteProductLoader: false,
    productsData: [],
    getSingleProductData: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get all products
      .addCase(actionGetProductsMaster.pending, (state) => {
        state.getProductsLoader = true;
      })
      .addCase(actionGetProductsMaster.fulfilled, (state, action) => {
        state.getProductsLoader = false;
        state.productsData = action.payload.data;
      })
      .addCase(actionGetProductsMaster.rejected, (state) => {
        state.getProductsLoader = false;
      })

      // get single product
      .addCase(actionGetProductMaster.pending, (state) => {
        state.getSingleProductLoader = true;
      })
      .addCase(actionGetProductMaster.fulfilled, (state, action) => {
        state.getSingleProductData = action.payload.data;
        state.getSingleProductLoader = false;
      })
      .addCase(actionGetProductMaster.rejected, (state) => {
        state.getSingleProductLoader = false;
      })

      // add product
      .addCase(actionAddProductMaster.pending, (state) => {
        state.addProductLoader = true;
      })
      .addCase(actionAddProductMaster.fulfilled, (state, action) => {
        state.addProductLoader = false;

        if (!state.productsData || !Array.isArray(state.productsData)) {
          state.productsData = [action.payload];
        } else {
          state.productsData.unshift(action.payload);
        }
      })
      .addCase(actionAddProductMaster.rejected, (state) => {
        state.addProductLoader = false;
      })

      // update product
      .addCase(actionUpdateProductMaster.pending, (state) => {
        state.updateProductLoader = true;
      })
      .addCase(actionUpdateProductMaster.fulfilled, (state, action) => {
        state.updateProductLoader = false;
        state.productsData = state.productsData.map((product) =>
          product.material_master_id === action.payload.material_master_id
            ? action.payload
            : product
        );
      })
      .addCase(actionUpdateProductMaster.rejected, (state) => {
        state.updateProductLoader = false;
      })

      // delete product
      .addCase(actionDeleteProductMaster.pending, (state) => {
        state.deleteProductLoader = true;
      })
      .addCase(actionDeleteProductMaster.fulfilled, (state, action) => {
        state.deleteProductLoader = false;
        state.productsData = state.productsData.filter(
          (product) => product.material_master_id !== action.payload
        );
      })
      .addCase(actionDeleteProductMaster.rejected, (state) => {
        state.deleteProductLoader = false;
      })

      //TODO: product master slice start here
      //get all product category start here
      .addCase(actionGetProductCategoryMaster.pending, (state) => {
        state.getProductCategoryLoader = true;
      })
      .addCase(actionGetProductCategoryMaster.fulfilled, (state, action) => {
        state.getProductCategoryLoader = false;
        state.productCategoryData = action.payload.data;
      })
      .addCase(actionGetProductCategoryMaster.rejected, (state) => {
        state.getProductCategoryLoader = false;
      })

      // add lead status
      .addCase(actionAddProductCategoryMaster.pending, (state) => {
        state.addProductCategoryLoader = true;
      })
      .addCase(actionAddProductCategoryMaster.fulfilled, (state, action) => {
        state.addProductCategoryLoader = false;
        if (action.payload) {
          if (
            !state.productCategoryData ||
            !Array.isArray(state.productCategoryData)
          ) {
            state.productCategoryData = [action.payload];
          } else {
            state.productCategoryData.unshift(action.payload);
          }
        }
      })

      .addCase(actionAddProductCategoryMaster.rejected, (state) => {
        state.addProductCategoryLoader = false;
      })

      //delete product category
      .addCase(actionDeleteProductCategoryMaster.pending, (state, action) => {
        state.deleteProductCategoryLoader = true;
      })
      .addCase(actionDeleteProductCategoryMaster.fulfilled, (state, action) => {
        const deletedId = action.payload; // Assuming payload is the deleted ID
        state.productCategoryData = state.productCategoryData.filter(
          (category) => category.product_category_master_id !== deletedId
        );
        state.deleteProductCategoryLoader = false;
      })
      .addCase(actionDeleteProductCategoryMaster.rejected, (state, action) => {
        state.deleteProductCategoryLoader = false;
      })

      //get single product category
      .addCase(actionGetSingleProductCategoryMaster.pending, (state) => {
        state.getSingleProductCategoryLoader = true;
      })
      .addCase(
        actionGetSingleProductCategoryMaster.fulfilled,
        (state, action) => {
          state.getSingleProductCategoryData = action.payload.data;
          state.getSingleProductCategoryLoader = false;
        }
      )
      .addCase(actionGetSingleProductCategoryMaster.rejected, (state) => {
        state.getSingleProductCategoryLoader = false;
      })

      //get all product sub category start here
      .addCase(actionGetProductSubCategoryMaster.pending, (state) => {
        state.getProductSubCategoryLoader = true;
      })
      .addCase(actionGetProductSubCategoryMaster.fulfilled, (state, action) => {
        state.getProductSubCategoryLoader = false;
        state.productSubCategoryData = action.payload.data;
      })
      .addCase(actionGetProductSubCategoryMaster.rejected, (state) => {
        state.getProductSubCategoryLoader = false;
      })

      //add product sub category master
      .addCase(actionAddProductSubCategoryMaster.pending, (state) => {
        state.addProductSubCategoryLoader = true;
      })
      .addCase(actionAddProductSubCategoryMaster.fulfilled, (state, action) => {
        state.addProductSubCategoryLoader = false;
        if (action.payload) {
          if (
            !state.productSubCategoryData ||
            !Array.isArray(state.productSubCategoryData)
          ) {
            state.productSubCategoryData = [action.payload];
          } else {
            state.productSubCategoryData.unshift(action.payload);
          }
        }
      })

      .addCase(actionAddProductSubCategoryMaster.rejected, (state) => {
        state.addProductSubCategoryLoader = false;
      })

      //delete product sub category
      .addCase(
        actionDeleteProductSubCategoryMaster.pending,
        (state, action) => {
          state.deleteProductSubCategoryLoader = true;
        }
      )
      .addCase(
        actionDeleteProductSubCategoryMaster.fulfilled,
        (state, action) => {
          const deletedId = action.payload;
          state.productSubCategoryData = state.productSubCategoryData.filter(
            (category) => category.product_sub_category_master_id !== deletedId
          );
          state.deleteProductSubCategoryLoader = false;
        }
      )
      .addCase(
        actionDeleteProductSubCategoryMaster.rejected,
        (state, action) => {
          state.deleteProductSubCategoryLoader = false;
        }
      )

      //edit product category
      .addCase(actionEditProductCategoryMaster.pending, (state, action) => {
        state.updateProductCategoryLoader[action.meta.arg.id] = true;
      })
      .addCase(actionEditProductCategoryMaster.fulfilled, (state, action) => {
        state.updateProductCategoryLoader[action.meta.arg.id] = false;
        state.productCategoryData = state.productCategoryData.map((type) =>
          type.product_category_master_id ===
          action.payload.product_category_master_id
            ? {
                ...type,
                category_name: action.payload.category_name,
                status: action.payload.status,
              }
            : type
        );
      })
      .addCase(actionEditProductCategoryMaster.rejected, (state, action) => {
        state.updateProductCategoryLoader[action.meta.arg.id] = false;
      })

      //get single product sub category
      .addCase(actionGetSingleProductSubCategoryMaster.pending, (state) => {
        state.getSingleProductSubCategoryLoader = true;
      })
      .addCase(
        actionGetSingleProductSubCategoryMaster.fulfilled,
        (state, action) => {
          state.getSingleProductSubCategoryData = action.payload.data;
          state.getSingleProductSubCategoryLoader = false;
        }
      )
      .addCase(actionGetSingleProductSubCategoryMaster.rejected, (state) => {
        state.getSingleProductSubCategoryLoader = false;
      })

      //edit product sub category
      .addCase(actionEditProductSubCategoryMaster.pending, (state, action) => {
        state.updateProductSubCategoryLoader[action.meta.arg.id] = true;
      })
      .addCase(
        actionEditProductSubCategoryMaster.fulfilled,
        (state, action) => {
          state.updateProductSubCategoryLoader[action.meta.arg.id] = false;
          state.productSubCategoryData = state.productSubCategoryData.map(
            (type) =>
              type.product_sub_category_master_id ===
              action.payload.product_sub_category_master_id
                ? {
                    ...type,
                    product_category: {
                      ...type.product_category,
                      category_name:
                        action.payload.product_category?.category_name,
                    },
                    sub_category_name: action.payload.sub_category_name,
                    status: action.payload.status,
                  }
                : type
          );
        }
      )
      .addCase(actionEditProductSubCategoryMaster.rejected, (state, action) => {
        state.updateProductSubCategoryLoader[action.meta.arg.id] = false;
      })

      // product type
      //get all product type
      .addCase(actionGetProductTypeMaster.pending, (state) => {
        state.getProductTypeLoader = true;
      })
      .addCase(actionGetProductTypeMaster.fulfilled, (state, action) => {
        state.getProductTypeLoader = false;
        state.productTypeData = action.payload.data;
      })
      .addCase(actionGetProductTypeMaster.rejected, (state) => {
        state.getProductTypeLoader = false;
      })

      //add product type
      .addCase(actionAddProductTypeMaster.pending, (state) => {
        state.addProductTypeLoader = true;
      })
      .addCase(actionAddProductTypeMaster.fulfilled, (state, action) => {
        state.addProductTypeLoader = false;
        if (action.payload) {
          if (!state.productTypeData || !Array.isArray(state.productTypeData)) {
            state.productTypeData = [action.payload];
          } else {
            state.productTypeData.unshift(action.payload);
          }
        }
      })

      .addCase(actionAddProductTypeMaster.rejected, (state) => {
        state.addProductTypeLoader = false;
      })

      //delete product type
      .addCase(actionDeleteProductTypeMaster.pending, (state, action) => {
        state.deleteProductTypeLoader = true;
      })
      .addCase(actionDeleteProductTypeMaster.fulfilled, (state, action) => {
        state.productTypeData = state.productTypeData.filter(
          (type) => type.product_type_master_id !== action.payload
        );
        state.deleteProductTypeLoader = false;
      })
      .addCase(actionDeleteProductTypeMaster.rejected, (state, action) => {
        state.deleteProductTypeLoader = false;
      })

      //update product type
      .addCase(actionEditProductTypeMaster.pending, (state, action) => {
        state.updateProductTypeLoader[action.meta.arg.id] = true;
      })
      .addCase(actionEditProductTypeMaster.fulfilled, (state, action) => {
        state.updateProductTypeLoader[action.meta.arg.id] = false;
        state.productTypeData = state.productTypeData.map((type) =>
          type.product_type_master_id === action.payload.product_type_master_id
            ? {
                ...type,
                product_type: action.payload.product_type,
                description: action.payload.description,
              }
            : type
        );
      })
      .addCase(actionEditProductTypeMaster.rejected, (state, action) => {
        state.updateProductTypeLoader[action.meta.arg.id] = false;
      })

      //get single Product type
      .addCase(actionGetSingleProductTypeMaster.pending, (state) => {
        state.getSingleProductTypeLoader = true;
      })
      .addCase(actionGetSingleProductTypeMaster.fulfilled, (state, action) => {
        state.getSingleProductTypeData = action.payload.data;
        state.getSingleProductTypeLoader = false;
      })
      .addCase(actionGetSingleProductTypeMaster.rejected, (state) => {
        state.getSingleProductTypeLoader = false;
      })

      //get all hsn/sac code
      .addCase(actionGetHsnSacMaster.pending, (state) => {
        state.getHsnSacLoader = true;
      })
      .addCase(actionGetHsnSacMaster.fulfilled, (state, action) => {
        state.getHsnSacLoader = false;
        state.hsnSacCodeData = action.payload.data;
      })
      .addCase(actionGetHsnSacMaster.rejected, (state) => {
        state.getHsnSacLoader = false;
      })

      // add hsn/sac code
      .addCase(actionAddHsnSacCodeMaster.pending, (state) => {
        state.addHsnSacCodeLoader = true;
      })
      .addCase(actionAddHsnSacCodeMaster.fulfilled, (state, action) => {
        state.addHsnSacCodeLoader = false;
        if (action.payload) {
          if (!state.hsnSacCodeData || !Array.isArray(state.hsnSacCodeData)) {
            state.hsnSacCodeData = [action.payload];
          } else {
            state.hsnSacCodeData.unshift(action.payload);
          }
        }
      })

      .addCase(actionAddHsnSacCodeMaster.rejected, (state) => {
        state.addHsnSacCodeLoader = false;
      })

      //delete hsn/sac code
      .addCase(actionDeleteHsnSacCodeMaster.pending, (state, action) => {
        state.deleteHsnSacCodeLoader = true;
      })
      .addCase(actionDeleteHsnSacCodeMaster.fulfilled, (state, action) => {
        state.hsnSacCodeData = state.hsnSacCodeData.filter(
          (type) => type.hsn_code_master_id !== action.payload
        );
        state.deleteHsnSacCodeLoader = false;
      })
      .addCase(actionDeleteHsnSacCodeMaster.rejected, (state, action) => {
        state.deleteHsnSacCodeLoader = false;
      })

      //edit hsn/sac code
      .addCase(actionEditHsnSacMaster.pending, (state, action) => {
        state.updateHsnSacCodeLoader[action.meta.arg.id] = true;
      })
      .addCase(actionEditHsnSacMaster.fulfilled, (state, action) => {
        state.updateHsnSacCodeLoader[action.meta.arg.id] = false;
        state.hsnSacCodeData = state.hsnSacCodeData.map((type) =>
          type.hsn_code_master_id === action.payload.hsn_code_master_id
            ? {
                ...type,
                hsn_code: action.payload.hsn_code,
                description: action.payload.description,
              }
            : type
        );
      })
      .addCase(actionEditHsnSacMaster.rejected, (state, action) => {
        state.updateHsnSacCodeLoader[action.meta.arg.id] = false;
      })

      //get single hsn sac code master
      .addCase(actionGetSingleHsnSacCodeMaster.pending, (state) => {
        state.getSingleHsnSacLoader = true;
      })
      .addCase(actionGetSingleHsnSacCodeMaster.fulfilled, (state, action) => {
        state.getSingleHsnSacCodeData = action.payload.data;
        state.getSingleHsnSacLoader = false;
      })
      .addCase(actionGetSingleHsnSacCodeMaster.rejected, (state) => {
        state.getSingleHsnSacLoader = false;
      })

      //get all unit
      .addCase(actionGetUnitMaster.pending, (state) => {
        state.getUnitMasterLoader = true;
      })
      .addCase(actionGetUnitMaster.fulfilled, (state, action) => {
        state.getUnitMasterLoader = false;
        state.unitMasterData = action.payload.data;
      })
      .addCase(actionGetUnitMaster.rejected, (state) => {
        state.getUnitMasterLoader = false;
      })

      //add unit
      .addCase(actionAddUnitMaster.pending, (state) => {
        state.addUnitLoader = true;
      })
      .addCase(actionAddUnitMaster.fulfilled, (state, action) => {
        state.addUnitLoader = false;
        if (action.payload) {
          if (!state.unitMasterData || !Array.isArray(state.unitMasterData)) {
            state.unitMasterData = [action.payload];
          } else {
            state.unitMasterData.unshift(action.payload);
          }
        }
      })

      .addCase(actionAddUnitMaster.rejected, (state) => {
        state.addUnitLoader = false;
      })

      //delete unit master
      .addCase(actionDeleteUnitMaster.pending, (state, action) => {
        state.deleteUnitLoader = true;
      })
      .addCase(actionDeleteUnitMaster.fulfilled, (state, action) => {
        state.unitMasterData = state.unitMasterData.filter(
          (type) => type.unit_master_id !== action.payload
        );
        state.deleteUnitLoader = false;
      })
      .addCase(actionDeleteUnitMaster.rejected, (state, action) => {
        state.deleteUnitLoader = false;
      })

      //get single unit master
      .addCase(actionGetSingleUnitMaster.pending, (state) => {
        state.getSingleUnitLoader = true;
      })
      .addCase(actionGetSingleUnitMaster.fulfilled, (state, action) => {
        state.getSingleUnitMasterData = action.payload.data;
        state.getSingleUnitLoader = false;
      })
      .addCase(actionGetSingleUnitMaster.rejected, (state) => {
        state.getSingleUnitLoader = false;
      })

      //edit Unit master
      .addCase(actionEditUnitMaster.pending, (state, action) => {
        state.updateUnitLoader[action.meta.arg.id] = true;
      })
      .addCase(actionEditUnitMaster.fulfilled, (state, action) => {
        state.updateUnitLoader[action.meta.arg.id] = false;
        state.unitMasterData = state.unitMasterData.map((type) =>
          type.unit_master_id === action.payload.unit_master_id
            ? {
                ...type,
                ...action.payload,
              }
            : type
        );
      })
      .addCase(actionEditUnitMaster.rejected, (state, action) => {
        state.updateUnitLoader[action.meta.arg.id] = false;
      });
  },
});
export default productMasterSlice.reducer;

import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Dropdown,
  Image,
  Modal,
  Pagination,
  Popconfirm,
  Table,
  Typography
} from "antd";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import closeIcon from "../../assets/img/close-icon.svg";
import taskIcon from "../../assets/img/task-icon.svg";
import {
  actionClearAllTasks,
  actionDeleteTasks,
  actionGetAllTasks,
  actionGetTaskDetail,
  actionGetTasks,
} from "../../store/services/taskService";
import CustomInputWithButtons from "../common/CustomInputWithButtons ";
import PageTitle from "../common/PageTitle";
import SortIcon from "../common/SortIcon";
import CreateTask from "./CreateTask";

const getStatusColor = (status) => {
  switch (status) {
    case "Not Started":
      return "border-[#7C7C7C] text-[#7C7C7C]";
    case "In Progress":
      return "border-yellow-600 text-yellow-600";
    case "Completed":
      return "border-green-600 text-green-600";
    case "Deferred":
      return "border-blue-800 text-blue-800";
    case "Waiting For Input":
      return "border-red-600 text-red-600";
    default:
      return "border-[#7C7C7C] text-[#7C7C7C]";
  }
};

const getPriorityColor = (priority) => {
  switch (priority) {
    case "Highest":
      return "border-red-600 text-red-600"; // Red
    case "High":
      return "border-orange-600 text-orange-600"; // Orange
    case "Normal":
      return "border-[#7C7C7C] text-[#7C7C7C]"; // Yellow
    case "Low":
      return "border-yellow-600 text-yellow-600"; // Green
    case "Lowest":
      return "border-green-600 text-green-600"; // Gray
    default:
      return "border-gray-200 text-black"; // Default Gray
  }
};

const Task = () => {
  const offsetRef = useRef(0);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [exportAll, setExportAll] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateId, setUpdateId] = useState("");

  const {
    tasks,
    getTasksLoader,
    getAllTasksLoader,
    allTasks,
    alltasksCount,
    getTaskDetailLoader,
    deleteTaskLoader,
  } = useSelector((state) => state.tasks);

  const htmlToPlainText = (html) => {
    // Create a temporary element to parse the HTML
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;

    // Get the plain text content from the temporary element
    let plainText = tempElement.textContent || tempElement.innerText;

    // Clean up special HTML entities
    const entities = {
      "&lt;": "<",
      "&gt;": ">",
      "&amp;": "&",
      "&quot;": '"',
      "&apos;": "'",
      "&nbsp;": " ",
      // Add more entities as needed
    };

    for (const entity in entities) {
      plainText = plainText.replace(new RegExp(entity, "g"), entities[entity]);
    }

    plainText = plainText.trim();

    return plainText;
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      sorter: () => true,
      sortIcon: () => <SortIcon />,
      width: 200,
      ellipsis: true,
      render: (text, data) => (
        <div
          onClick={() => {
            setUpdateId(data.task_id);
            setIsModalOpen(true);
            dispatch(actionGetTaskDetail(data.task_id));
          }}
          className="flex items-center text-brand cursor-pointer"
        >
          {text ? text : "-"}
        </div>
      ),
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      key: "due_date",
      sorter: () => true,
      sortIcon: () => <SortIcon />,
      width: 200,
      render: (text) =>
        text ? dayjs(text).format("DD MMM YYYY HH:mm A") : "-",
    },
    {
      title: "Status",
      dataIndex: "task_status",
      key: "task_status",
      sorter: () => true,
      sortIcon: () => <SortIcon />,
      width: 200,
      render: (text, data) => {
        const task_status_master = data.task_status_master || {};
        const status = task_status_master.status || "";
        return status ? (
          <span
            className={`${getStatusColor(
              status
            )} border py-1.5 rounded-[5px] px-2.5 text-sm`}
          >
            {status}
          </span>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Priority",
      dataIndex: "task_priority",
      key: "task_priority",
      sorter: () => true,
      sortIcon: () => <SortIcon />,
      width: 200,
      render: (text) => {
        return text ? (
          <span
            className={`${getPriorityColor(
              text
            )} border py-1.5 rounded-[5px] px-2.5 text-sm`}
          >
            {text}
          </span>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Reminder",
      dataIndex: "is_reminder",
      key: "is_reminder",
      width: 150,
      render: (text) => (
        <span className={`${text ? "text-green-600" : "text-red-600"}`}>
          {text ? "ON" : "OFF"}
        </span>
      ),
    },
    {
      title: "Reminder On",
      dataIndex: "reminder_on_date",
      key: "reminder_on_date",
      sorter: () => true,
      sortIcon: () => <SortIcon />,
      width: 200,
      render: (text, data) =>
        data.is_reminder
          ? `${dayjs(data.reminder_on_date).format("DD MMM YYYY")} ${dayjs(
              data.reminder_on_time,
              "HH:mm"
            ).format("h:mm A")}`
          : "-",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sorter: () => true,
      sortIcon: () => <SortIcon />,
      // ellipsis: true,
      width: 200,
      render: (text, data) =>
        text ? (
          <Typography.Paragraph
            title={htmlToPlainText(text)}
            ellipsis={{ rows: 1 }}
            style={{ width: 200 }}
          >
            {htmlToPlainText(text)}
          </Typography.Paragraph>
        ) : (
          "-"
        ),
    },

    {
      title: "Assigned Users",
      dataIndex: "task_assignee_user",
      key: "task_assignee_user",

      width: 250,
      render: (assignees) => (
        <Avatar.Group
          size={50}
          max={{
            count: 3,
            style: {
              color: "white",
              backgroundColor: "#6883FD",
              cursor: "pointer",
              fontSize: 15,
            },
            popover: { trigger: "click" },
          }}
        >
          {assignees && assignees.length > 0
            ? assignees.map((item, index) => (
                <Avatar
                  key={index}
                  src={item.assigned_comany_users?.profile_pic}
                />
              ))
            : "-"}
        </Avatar.Group>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "8",
      width: "10%",
      fixed: "right",
      render: (text, data) => (
        <>
          <Dropdown.Button
            id="custom-drop"
            menu={{
              items: [
                {
                  label: <span>Edit</span>,
                  key: "Edit",
                  icon: <EditOutlined />,
                  onClick: () => {
                    setUpdateId(data.task_id);
                    setIsModalOpen(true);
                    dispatch(actionGetTaskDetail(data.task_id));
                  },
                },
                {
                  label: (
                    <Popconfirm
                      okButtonProps={{
                        loading: deleteTaskLoader,
                      }}
                      placement="topLeft"
                      title="Delete"
                      description={`Are you sure to delete this Task?`}
                      onConfirm={() => {
                        dispatch(actionDeleteTasks(data.task_id));
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <div
                        className="flex items-center"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <DeleteOutlined
                          style={{ fontSize: 12, color: "#ff4d4f" }}
                        />
                        <span
                          style={{
                            marginLeft: 8,
                            color: "#ff4d4f",
                          }}
                        >
                          Delete
                        </span>
                      </div>
                    </Popconfirm>
                  ),
                  key: "Delete",

                  onClick: () => {},
                },
              ],
            }}
          >
            Action
          </Dropdown.Button>
        </>
      ),
    },
  ];

  useEffect(() => {
    dispatch(actionGetTasks({ offset: offsetRef.current, search }));
  }, [dispatch]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setUpdateId("");
  };

  const handleSearch = (e) => {
    offsetRef.current = 0;
    setSearch(e.target.value);
    if (e.target.value === "") {
      dispatch(
        actionGetTasks({
          offset: offsetRef.current,
          search: e.target.value,
        })
      );
    }
  };

  const handleBlurAndPressEnter = (e) => {
    if (search) {
      offsetRef.current = 0;
      dispatch(
        actionGetTasks({
          offset: offsetRef.current,
          search: e.target.value,
        })
      );
    }
  };

  const handleRefresh = () => {
    setSearch(null);
    offsetRef.current = 0;
    dispatch(
      actionGetTasks({
        offset: offsetRef.current,
      })
    );
  };

  const exportAllToExcel = async () => {
    const data =
      (await allTasks) &&
      allTasks.length > 0 &&
      allTasks.map((data) => ({
        "Task Owner": data?.created_by?.full_name
          ? data?.created_by?.full_name
          : "-",
        Title: data.name ? data.name : "-",
        "Due Date": data.due_date ? data.due_date : "-",
        Status:
          data.task_status_master && data.task_status_master?.status
            ? data.task_status_master?.status
            : "-",
        Priority: data.task_priority ? data.task_priority : "-",
        "Assigned Users":
          data.task_assignee_user && data.task_assignee_user.length > 0
            ? data.task_assignee_user
                .map(
                  (com) =>
                    com.assigned_comany_users &&
                    com.assigned_comany_users?.full_name
                )
                .join(", ")
            : "-",
        "Reminder On": data.is_reminder
          ? data.reminder_on_date &&
            data.reminder_on_time &&
            `${data.reminder_on_date} ${data.reminder_on_time}`
          : "-",
      }));
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet2");
    // Generate the Excel file and initiate the download
    XLSX.writeFile(workbook, "Tasks.xlsx");
    dispatch(actionClearAllTasks({ setExportAll }));
  };

  useEffect(() => {
    if (exportAll && allTasks && allTasks.length > 0) {
      exportAllToExcel();
    }
  }, [allTasks]);

  const onClickDownloadMenu = () => {
    dispatch(actionGetAllTasks());
    setExportAll(true);
  };

  const handleSortChange = (pagination, filters, sorter) => {
    const sorterParams = {
      field: sorter.field,
      order:
        sorter.order === "descend"
          ? "DESC"
          : sorter.order === "ascend"
          ? "ASC"
          : "",
    };

    dispatch(
      actionGetTasks({
        offset: offsetRef.current,
        search: search,
        orderByColumn: sorterParams.field,
        order: sorterParams.order,
      })
    );
  };

  return (
    <>
      <section className="main-wrapper">
        <PageTitle
          image={taskIcon}
          title="Task Information"
          buttonText="Add Task"
          handleClick={handleOpenModal}
          type="btn"
        />

        <CustomInputWithButtons
          handleRefresh={handleRefresh}
          handleSearch={handleSearch}
          handleBlurAndPressEnter={handleBlurAndPressEnter}
          searchValue={search}
          onClickDownloadMenu={onClickDownloadMenu}
        />

        <Table
          sticky
          rowKey="task_id"
          scrollToFirstRowOnChange={true}
          sortDirections={["ascend", "descend"]}
          onChange={handleSortChange}
          bordered
          scroll={{
            x: "max-content",
            y: window.innerWidth < 1500 ? "49vh" : "61vh",
          }}
          pagination={false}
          columns={columns}
          dataSource={tasks}
          loading={getTasksLoader || getAllTasksLoader}
        />
        <div className="flex justify-end mt-3">
          <Pagination
            hideOnSinglePage
            responsive={true}
            current={offsetRef.current + 1}
            className="flex items-center"
            showSizeChanger={false}
            total={alltasksCount}
            onChange={(e) => {
              offsetRef.current = e - 1;
              dispatch(
                actionGetTasks({
                  offset: offsetRef.current,
                  search,
                })
              );
            }}
          />
        </div>
      </section>

      <Modal
        width={1200}
        height={600}
        loading={getTaskDetailLoader}
        className="add-task-modal"
        closeIcon={<Image preview={false} src={closeIcon} alt="close-icon" />}
        title={null}
        open={isModalOpen}
        style={{ overflowX: "none", top: 20 }}
        footer={false}
        onOk={handleCloseModal}
        onCancel={handleCloseModal}
        destroyOnClose={handleCloseModal}
      >
        <CreateTask
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          updateId={updateId}
          setUpdateId={setUpdateId}
          handleCloseModal={handleCloseModal}
        />
      </Modal>
    </>
  );
};

export default Task;
